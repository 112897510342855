import React from 'react';
import { useSelector } from 'react-redux';
import { selectLatestPeriodCrewClosingBalanceWarnings } from 'common/components/mga/store/selectors';
import { displayDate } from 'common/utils/dates';
import { numberToStr } from 'common/utils/numbers';
import AlertsContainer from 'common/components/alerts/AlertsContainer';
import PropTypes from 'prop-types';

const CrewClosingBalanceWarnings = ({ crewMemberId }) => {
  const warnings = useSelector(state =>
    selectLatestPeriodCrewClosingBalanceWarnings(state, crewMemberId)
  );

  if (!warnings?.length) return null;

  return (
    <AlertsContainer
      isOpen
      className="cmt-6 cpb-2 pt-1 px-2 mb-1"
      alerts={warnings}
      renderAlert={({ full_name, to, closing_balance }) => {
        return (
          <div>
            - Orca detected that <strong className="fw-medium">{full_name}</strong> is going to sign
            off at <strong className="fw-medium">{displayDate(to, { time: true })}</strong> and his
            closing balance is{' '}
            <strong className="fw-medium">
              {numberToStr(closing_balance, 2, 2)} {closing_balance > 0 ? '>' : '<'} 0.00 USD
            </strong>
            .
          </div>
        );
      }}
    />
  );
};

CrewClosingBalanceWarnings.propTypes = {
  crewMemberId: PropTypes.number
};

export default CrewClosingBalanceWarnings;
