import Textarea from 'common/components/form/inputs/Textarea';

const Title = ({ value, error, onChange, disabled }) => {
  return (
    <>
      <Textarea
        label={'Title'}
        value={value}
        name="name"
        white
        rows={1}
        autoResize
        error={error}
        placeholder="Add title"
        dataCy="event_title"
        onChange={onChange}
        className={`ps-1 event-title fw-medium`}
        disabled={disabled}
        onKeyDown={e => {
          if (e.key === 'Enter') e.preventDefault();
        }}
      />
    </>
  );
};

export default Title;
