const digitalConfig = [
  {
    fields: [
      {
        key: 'skype_id',
        type: 'string',
        labelClassName: 'd-none',
        inputWidth: 9,
        inputProps: {
          placeholder: 'Add skype'
        }
      },
      {
        key: 'twitter',
        type: 'string',
        labelClassName: 'd-none',
        inputWidth: 9,
        inputProps: {
          placeholder: 'Add twitter'
        }
      },
      {
        key: 'facebook',
        type: 'string',
        labelClassName: 'd-none',
        inputWidth: 9,
        inputProps: {
          placeholder: 'Add facebook'
        }
      },
      {
        key: 'linkedin',
        type: 'string',
        labelClassName: 'd-none',
        inputWidth: 9,
        inputProps: {
          placeholder: 'Add linkedin'
        }
      }
    ]
  }
];

export default digitalConfig;
