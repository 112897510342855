import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { moduleIcons } from '../config';
import SvgRender from 'common/components/general/SvgRender';
import { selectEventModuleLabels } from 'events/store/events/selectors';
import CircledButton from 'common/components/buttons/CircledButton';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import { setIsEditingModules } from 'events/store/events/actions';
import { fetchListOptions } from 'store/lists/actions';

const ModuleList = () => {
  const dispatch = useDispatch();

  const modules = useSelector(state => selectListOptionsFromStore(state, 'event-modules'));
  const eventModuleList = useSelector(selectEventModuleLabels);

  const onDragStart = label => e => {
    e.dataTransfer.setData('module-label', label);
    e.target.style.cursor = 'grabbing';

    const elem = document.createElement('div');

    ReactDOM.render(
      <SvgRender src={moduleIcons[label].iconWithLetter} style={{ width: 56, height: 56 }} />,
      elem
    );

    elem.className = `event-dragable-module`;

    elem.style.position = 'relative';
    elem.style.marginTop = '20px';

    document.body.appendChild(elem);
    e.dataTransfer.setDragImage(elem, 23, 23);
  };

  const onDragOver = e => {
    e.preventDefault();
  };

  const onDragEnd = e => {
    e.target.style.cursor = 'grab';
    const elem = document.querySelector('.event-dragable-module');
    if (elem) {
      document.body.removeChild(elem);
    }
  };

  const onDone = () => {
    dispatch(setIsEditingModules(false));
  };

  useEffect(() => {
    dispatch(fetchListOptions('event-modules'));
  }, [dispatch]);

  return (
    <div className="event-module-list w-100p">
      <div className="d-flex align-items-center justify-content-between">
        <div className="fs-12 fw-medium text-primary">
          Drag the modules that you want into the toolbar
        </div>
        <CircledButton
          svgStyle={{ width: 12 }}
          type={'check'}
          wrappedLabel="Done"
          onClick={onDone}
          data-cy="done"
        />
      </div>

      <div className="d-flex align-items-center mt-3 flex-wrap mx-1">
        {modules?.length
          ? modules
              .filter(el => !eventModuleList.includes(el.label) && moduleIcons[el.label])
              .map(module => {
                return (
                  <div
                    id={`event-module-list-${module.label}`}
                    className="event-module-list__module me-2 cursor-grab d-flex flex-column align-items-center"
                    key={module.id.toString()}
                    draggable="true"
                    onDragStart={onDragStart(module.label)}
                    onDragOver={onDragOver}
                    onDragEnd={onDragEnd}
                  >
                    <SvgRender
                      src={moduleIcons[module.label].iconWithLetter}
                      style={{ width: 56, height: 56 }}
                    />
                    <div className="text-violet fs-9 fw-medium text-center">{module.name}</div>
                  </div>
                );
              })
          : null}
      </div>
    </div>
  );
};

export default ModuleList;
