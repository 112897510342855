import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { TableTopFilters } from 'common/entities/tables/TopFilterTypes';

import VesselSelector from 'common/components/selectors/VesselSelector';
import VesselSystemGroupSelector from 'common/components/selectors/VesselSystemGroupSelector';
import TableTopFilter from 'common/components/table/TableTopFilter';
import TopPagination from 'common/components/table/main/TopPagination';

import FilterButton from 'common/components/buttons/FilterButton';
import useTableTopFilter from 'common/components/filters/useTableTopFilter';
import { selectTableListPaging } from 'common/components/table/store/selectors';
import HiddenButton from 'common/components/table/main/HiddenButton';

// import PurchasingStoreGroupSelector from 'common/components/selectors/PurchasingStoreGroupSelector';

const TableHeader = ({ topFilters, setTopFilters, label, singleVesselView }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  const robWarningIsActive = useTableTopFilter({ name: 'rob_is_under_optimal' });
  const robViolatedIsActive = useTableTopFilter({ name: 'rob_is_under_minimum_required' });
  const paging = useSelector(state => selectTableListPaging(state, label));
  const isPmsInventory = label === 'inventory_spare_parts';

  const selectedVessels = useMemo(
    () => topFilters?.find(f => f.name === 'vessel_id'),
    [topFilters]
  );
  const selectedGroup = useMemo(
    () =>
      topFilters?.find(
        f =>
          f.name ===
          (label === 'inventory_spare_parts' ? 'vessel_system_group_id' : 'store_group_id')
      ),
    [label, topFilters]
  );

  const updateTopFilters = useCallback(
    (key, value, operation) => {
      setTopFilters(
        topFilters.map(f =>
          f.name === key ? { ...f, value, operation: operation || f.operation } : f
        )
      );
    },
    [setTopFilters, topFilters]
  );

  return (
    <Row className={`d-flex align-items-center cmb-2 mt-4`} noGutters>
      {!isOnBoard && (
        <Col xs="auto" className="me-1">
          <TableTopFilter>
            <VesselSelector
              filter={{ value: selectedVessels.value?.length ? selectedVessels.value : null }}
              isMulti={true}
              isClearable
              autoFocus={false}
              onChange={({ value }) => updateTopFilters('vessel_id', value?.length ? value : null)}
              placeholder="Select vessel"
              showIcon
            />
          </TableTopFilter>
        </Col>
      )}

      {label === 'inventory_spare_parts' ? (
        <>
          <Col xs="auto" className="me-1">
            <TableTopFilter>
              <VesselSystemGroupSelector
                filter={{ value: selectedGroup?.value?.length ? selectedGroup.value : null }}
                isMulti={false}
                isClearable
                autoFocus={false}
                onChange={({ value }) =>
                  updateTopFilters('vessel_system_group_id', value?.id ? [value?.id] : null)
                }
                wrapperClassName="w-100p"
                showIcon
              />
            </TableTopFilter>
          </Col>
          <Col xs="auto" className="me-1">
            <FilterButton
              isActive={robWarningIsActive}
              onClick={() =>
                updateTopFilters('rob_is_under_optimal', robWarningIsActive ? null : 'true')
              }
              status="proceeding"
              size="sm"
              tooltip="ROB is less than the Optimal quantity"
              tooltipInnerClassName="max-width-none"
            >
              ROB Warning
            </FilterButton>
          </Col>
          <Col xs="auto" className="me-1">
            <FilterButton
              isActive={robViolatedIsActive}
              onClick={() =>
                updateTopFilters(
                  'rob_is_under_minimum_required',
                  robViolatedIsActive ? null : 'true'
                )
              }
              status="error"
              size="sm"
              tooltip="ROB is less than the Required quantity"
              tooltipInnerClassName="max-width-none"
            >
              ROB Violated
            </FilterButton>
          </Col>
        </>
      ) : null}

      {isPmsInventory && !isOnBoard ? (
        <Col xs="auto" className="border-start border-platinum ps-2 ms-1">
          <HiddenButton
            label="Spare Parts"
            topFilters={topFilters}
            setTopFilters={setTopFilters}
            dataCy="hidden"
          />
        </Col>
      ) : null}

      <Col xs="auto" className="ms-auto">
        {singleVesselView ? (
          <TopPagination
            hideNavigation
            hidePerPage
            standAlone
            state={{ paging: { ...paging, per_page: null } }}
            customOptions={[20, 50, 100, 200]}
          />
        ) : (
          <TopPagination standAlone state={{ paging }} customOptions={[20, 50, 100, 200]} />
        )}
      </Col>
    </Row>
  );
};

TableHeader.propTypes = {
  topFilters: TableTopFilters.isRequired,
  setTopFilters: PropTypes.func.isRequired,
  label: PropTypes.oneOf(['inventory_spare_parts', 'inventory_items'])
};

export default TableHeader;
