import { useEffect } from 'react';

import Box from './Box';
import ContentCaptainReports from './ContentCaptainReports';
import ContentForms from './ContentForms';
import ContentCrewChange from './ContentCrewChange';
import ContentJobs from './ContentJobs';

import paths from 'routing/routes/_paths';
import crew from 'assets/svg/sidebar/crew.svg';
import findings from 'common/assets/svg/common/findings.svg';
import delivered from 'common/assets/svg/actions/to-be-delivered.svg';

import { useActions } from 'utils/hooks';

import * as dashboardTotalsActions from 'store/dashboard/totals/actions';

import BoxRunningHoursDue from 'views/dashboard/totals/BoxRunningHoursDue';
import ContentFindings from './ContentFindings';
import ContentFlagDispensation from './ContentFlagDispensation';
import permissions from 'common/utils/permissions/constants';
import ContentPmsJobs from './ContentPmsJobs';
import InventorySurveysDaysLeft from 'views/dashboard/totals/InventorySurveysDaysLeft';
import ContentPurchasingCases from './ContentPurchasingCases';

const Totals = () => {
  const [getDashboardTotals] = useActions([dashboardTotalsActions.getDashboardTotals]);

  const totals = [
    {
      header: 'Reports',
      // subheader: 'Last 24h',
      linkTo: { pathname: paths.captain_reports },
      content: <ContentCaptainReports />,
      key: 'captain_reports',
      bg: 'primary',
      size: 'lg',
      permissions: [permissions.onboard.dashboard.reports_widget.card]
    },
    {
      header: 'Forms',
      linkTo: { pathname: paths.form_planning },
      content: <ContentForms />,
      key: 'sms_forms',
      size: 'lg',
      bg: 'white',
      shouldHideBasedOnPermissions: true,
      permissions: [permissions.onboard.dashboard.forms_widget.card]
    },
    {
      header: 'Crew Change',
      icon: crew,
      content: <ContentCrewChange />,
      key: 'crew_rotations',
      size: 'md',
      permissions: [permissions.onboard.dashboard.crew_switches_widget.card]
    },
    {
      header: 'Purchasing Cases',
      subheader: 'To be Delivered',
      icon: delivered,
      content: <ContentPurchasingCases />,
      key: 'purchasing_cases',
      size: 'md',
      permissions: [permissions.onboard.dashboard.purchasing_cases_widget.card]
    },
    {
      header: 'Jobs',
      linkTo: { pathname: paths.regular_jobs },
      content: <ContentJobs />,
      key: 'jobs',
      size: 'lg',
      bg: 'solitude',
      shouldHideBasedOnPermissions: true,
      permissions: [permissions.onboard.dashboard.jobs_widget.card]
    },
    {
      header: 'Flag Dispensations',
      linkTo: { pathname: paths.flag_extensions_dispensations_jobs },
      content: <ContentFlagDispensation />,
      key: 'flag_dispensations',
      size: 'lg',
      bg: 'solitude',
      permissions: [permissions.onboard.dashboard.jobs_widget.card],
      shouldHideBasedOnPermissions: true
    },
    {
      header: 'Findings',
      icon: findings,
      content: <ContentFindings />,
      key: 'findings',
      size: 'md',
      permissions: [permissions.onboard.dashboard.findings_widget.card]
    },
    {
      header: 'Inventory Survey',
      component: InventorySurveysDaysLeft,
      key: 'inventory_survey',
      permissions: [permissions.onboard.dashboard.findings_widget.card]
    },
    {
      header: 'PMS Jobs',
      linkTo: { pathname: paths.pms_jobs },
      content: <ContentPmsJobs />,
      key: 'pms_jobs',
      size: 'lg',
      bg: 'solitude',
      permissions: [permissions.onboard.dashboard.jobs_widget.card],
      shouldHideBasedOnPermissions: true
    },
    {
      header: 'Running Hours Report',
      key: 'running_hours_report',
      component: BoxRunningHoursDue
    }
  ];

  useEffect(() => {
    getDashboardTotals();
  }, [getDashboardTotals]);

  return (
    <div className="dashboard__totals d-flex flex-wrap">
      {totals.map(item =>
        item.component ? (
          <item.component key={item.key} {...item} />
        ) : (
          <Box key={item.key} {...item} />
        )
      )}
    </div>
  );
};

export default Totals;
