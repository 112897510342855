import { useState } from 'react';

import Filters from 'common/components/filters';
import useActions from 'common/utils/hooks/useActions';
import * as tableListActions from 'common/components/table/store/actions';
import Columns from 'common/components/table/header/columns';

import {
  selectTableFilters,
  selectTableListInvalid
} from 'common/components/table/store/selectors';

import { useSelector } from 'react-redux';

const TableFilters = ({ setFilters, topFilters = [], listView, tableLabel, columns }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [applyTableFilters] = useActions([tableListActions.applyTableFilters]);

  const filters = useSelector(state => selectTableFilters(state, tableLabel));
  const invalid = useSelector(state => selectTableListInvalid(state, tableLabel));

  const excludedFilters = [
    'vessel_id',
    'is_completed',
    'is_overdue',
    'is_postponed',
    'type',
    ...topFilters.map(t => t.name)
  ];

  return (
    <Filters
      label={tableLabel}
      columns={columns?.filter(c => c.canFilter !== false)}
      excludedFilters={
        listView
          ? [...excludedFilters, 'due_date']
          : [...excludedFilters, 'is_class', 'is_critical', 'is_environmental_critical']
      }
      selected={filters}
      onChange={setFilters}
      onToggle={setIsOpen}
      onApply={applied => applyTableFilters(applied, tableLabel)}
      isOpen={isOpen}
      extraFilters={
        tableLabel === 'pms_jobs_list' ? (
          <Columns columns={columns} state={{ state: { invalid: invalid } }} />
        ) : null
      }
      key={listView ? 'list-view' : 'system-view'}
    />
  );
};

export default TableFilters;
