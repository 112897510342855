import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';

const MaintenanceJobTypeSelector = ({
  filter,
  onChange,
  isMulti,
  listParams,
  showDefaultOptions = true
}) => {
  const [selectedMaintenancemaintenanceJobType, setSelectedMaintenancemaintenanceJobType] =
    useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'maintenance-job-types', listParams },
    {
      selected: selectedMaintenancemaintenanceJobType,
      setSelected: setSelectedMaintenancemaintenanceJobType
    }
  );

  return (
    <Select
      autoFocus
      className={`mb-0`}
      placeholder="Select value"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      isAsync
      defaultOptions={() =>
        showDefaultOptions
          ? getInitialAsyncValues('maintenance-job-types', null, null, listParams)
          : []
      }
      isMulti={isMulti}
      value={
        filter.value.length
          ? selectedMaintenancemaintenanceJobType.filter(el =>
              filter.value.map(Number).includes(el.id)
            )
          : []
      }
      loadOptions={search => getAsyncOptions(search, 'maintenance-job-types', listParams)}
      onChange={selected => {
        setSelectedMaintenancemaintenanceJobType(selected);
        onChange({
          value: selected ? (isMulti ? [...selected.map(s => s.id)] : selected) : ''
        });
      }}
    />
  );
};

export default MaintenanceJobTypeSelector;
