import _get from 'lodash/get';
import useRouter from 'use-react-router';
import { Row, Col } from 'reactstrap';

import Table, { useTable } from 'common/components/table';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

import { stringifyObj } from 'common/utils/urls';
import CrewRankSelector from 'common/components/selectors/CrewRankSelector';
import CrewReasonSelector from 'common/components/selectors/CrewReasonsSelector';
import RoundedLineIconWithTooltip from 'common/components/general/RoundedLineIconWithTooltip';
import PersonSelector from 'common/components/selectors/PersonSelector';
import {
  isDraftEvaluation,
  getConsentSeamanStatusParams
} from 'crew/profile/main/evaluation/form/helpers';
import { isAuthorized } from 'utils/permissions/authorize';
import { useSelector } from 'react-redux';
import EvaluationDaysLeft from 'crew/components/EvaluationDaysLeft';
import paths from 'routing/routes/_paths';

import crewPlanning from 'common/assets/svg/common/crew_planning.svg';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import draftSvg from 'common/assets/svg/common/draft.svg';
import crewSvg from 'common/assets/svg/common/crew.svg';

import PageTitle from 'common/components/general/PageTitle';
import useTableTopFilter from 'common/components/filters/useTableTopFilter';

import { dateToUtc } from 'common/utils/dates';
import NabTabs from './NavTabs';
import EvaluatorDepartment from 'crew/components/EvaluatorDepartment';
import { selectAccount } from 'store/account/selectors';
import permissions from 'common/utils/permissions/constants';
import PageLink from 'common/components/general/page-link';

const Evaluations = () => {
  const { history } = useRouter();

  const account = useSelector(selectAccount);
  const canSeeEvaluations = isAuthorized(account, [
    permissions.onboard.crew.seaman_profile.evaluations.all_users.view
  ]);
  const canSeeOwnEvaluations = isAuthorized(account, [
    permissions.onboard.crew.seaman_profile.evaluations.own_profile.view
  ]);
  const canEvaluate = isAuthorized(account, [
    permissions.onboard.crew.seaman_profile.evaluations.all_users.create
  ]);

  const isInCompletedTab = useTableTopFilter({ name: 'status' }, 'completed');

  const columns = [
    {
      header: 'Reason',
      key: 'reason',
      sort: true,
      canFilter: false,
      minWidth: 316,
      maxWidth: 316,
      sticky: true,
      left: 0,
      className: 'z-index-3'
    },
    {
      header: 'Reason',
      key: 'reason_id',
      sort: false,
      canFilter: true,
      className: 'd-none',
      type: 'collection',
      hidden: true,
      component: CrewReasonSelector,
      componentRest: { isMulti: true, params: { include_custom: true } }
    },
    {
      header: 'Evaluator',
      key: 'evaluator',
      sort: false,
      canFilter: false,
      minWidth: 300,
      maxWidth: 300
    },
    {
      header: 'Evaluatee',
      key: 'evaluatee',
      sort: true,
      canFilter: false,
      minWidth: 278,
      maxWidth: 278
    },
    {
      header: 'Evaluatee',
      key: 'evaluatee_id',
      sort: false,
      canFilter: true,
      hidden: true,
      className: 'd-none',
      type: 'collection',
      component: PersonSelector,
      componentRest: { isMulti: true, listParams: { onboard: true } }
    },
    {
      header: 'Rank',
      key: 'rank',
      sort: true,
      canFilter: false,
      minWidth: 269,
      maxWidth: 269
    },
    {
      header: 'Rank',
      key: 'rank_id',
      type: 'collection',
      component: CrewRankSelector,
      componentRest: { isMulti: true },
      sort: false,
      canFilter: true,
      hidden: true,
      className: 'd-none'
    },
    {
      header: 'Contract Starts',
      key: 'contract_starts',
      data_key: 'trigger.contract_from_date',
      sortingKey: 'trigger_contract_from',
      type: 'date',
      sort: true,
      canFilter: false,
      minWidth: 136,
      maxWidth: 136
    },
    {
      header: 'Contract Ends',
      key: 'contract_ends',
      data_key: 'trigger.contract_to_date',
      sortingKey: 'trigger_contract_to',
      type: 'date',
      sort: true,
      canFilter: false,
      minWidth: 132,
      maxWidth: 132
    },
    {
      header: 'DOS',
      key: 'trigger_date',
      type: 'date',
      sort: true,
      canFilter: false,
      minWidth: 124,
      maxWidth: 124
    },
    {
      header: 'Days Left',
      key: 'days_left',
      sort: true,
      type: 'date',
      hidden: isInCompletedTab,
      canFilter: false,
      minWidth: 100,
      maxWidth: 100
    },
    {
      header: 'Evaluation Date',
      key: 'evaluation_date',
      sort: true,
      type: 'date',
      hidden: !isInCompletedTab,
      canFilter: isInCompletedTab,
      minWidth: 134,
      maxWidth: 134
    },
    {
      header: '',
      key: 'evaluation_status',
      sort: false,
      canFilter: false,
      sticky: true,
      right: 0,
      minWidth: isInCompletedTab ? 114 : 160,
      maxWidth: isInCompletedTab ? 114 : 160,
      isRightSticky: true
    },
    {
      header: 'Actions',
      key: 'actions',
      field: 'actions',
      className: 'text-center'
    }
  ];
  const table = useTable({
    label: 'crew_evaluation_obligations',
    defaultRequestParams: { paging: true, filters: true, sorting: true },
    columns,
    top: {
      filters: [
        {
          name: 'status',
          operation: '=',
          value: 'pending'
        }
      ]
    }
  });

  const { topFilters, setTopFilters } = table;

  const activeTab = _get(topFilters[0], 'value', 'pending');

  return (
    <div className={`crew-evaluations ${activeTab}`}>
      <Row className="align-items-center mb-4 w-100p" noGutters>
        <Col xs="auto">
          <PageTitle
            className="mb-0"
            render={
              <div className="d-flex align-items-center">
                <NabTabs topFilters={topFilters} setTopFilters={setTopFilters} />
                <span>
                  Evaluation Obligations
                  <strong className="ps-2 text-capitalize">/ {activeTab}</strong>
                </span>
              </div>
            }
          />
        </Col>
      </Row>

      <Table
        loader
        rows={{
          reason: data => {
            const reasonName = _get(data, 'reason.name', '-');

            return (
              <div className="overflow-hidden flex-nowrap pe-1">
                <TextWithTooltip>{reasonName}</TextWithTooltip>
              </div>
            );
          },
          evaluator: data => {
            const evaluatorType = _get(data, 'evaluator_type', false);
            const evaluator = _get(data, 'evaluator', '');

            return evaluatorType === 'crew_member' ? (
              <span>{_get(evaluator, 'full_name', '')}</span>
            ) : (
              <EvaluatorDepartment evaluator={evaluator} evaluatorType={evaluatorType} />
            );
          },
          evaluatee: data => {
            const evaluateeName = _get(data, 'evaluatee.full_name', '-');
            const evaluateeId = _get(data, 'evaluatee.id', '');

            return evaluateeId || evaluateeId === 0 ? (
              <div className="overflow-hidden flex-nowrap pe-1">
                <TextWithTooltip>{evaluateeName}</TextWithTooltip>
              </div>
            ) : (
              '-'
            );
          },
          rank: data => {
            const rank = _get(data, 'crew_rank.name', '-');

            return rank;
          },
          days_left: data => <EvaluationDaysLeft data={data} />,
          evaluation_date: data => dateToUtc(data?.evaluation?.date)?.format('DD/MM/YYYY') || '-',
          evaluation_status: data => {
            const isDraft = isDraftEvaluation(data?.evaluation, true);
            const isCaptainForReview = _get(data, 'evaluation.sent_to_captain_for_review');

            return activeTab === 'pending' ? (
              <div className="d-flex align-items-center me-4 rounded-end rounded-lg">
                <RoundedLineIconWithTooltip
                  tooltipMessage="Draft"
                  icon={draftSvg}
                  activeColor={'secondary-gray'}
                  isActive={isDraft}
                  className="cme-10"
                />

                <RoundedLineIconWithTooltip
                  tooltipMessage={
                    isCaptainForReview ? 'Sent to Captain for Review' : 'Captain’s Review'
                  }
                  icon={crewSvg}
                  isActive={isCaptainForReview}
                  activeColor={'primary'}
                />

                <div className="small-separator text-platinum cme-12 cms-12"></div>
                <RoundedLineIconWithTooltip
                  onClick={
                    data?.evaluation?.id
                      ? () => {
                          history.push(
                            `${paths.crew}/${data.evaluatee.id}/evaluation/view/${data.evaluation.id}`
                          );
                        }
                      : false
                  }
                  icon={crewPlanning}
                  {...getConsentSeamanStatusParams(data)}
                />
              </div>
            ) : (
              <div className="w-100p d-flex align-items-center evaluation-status-completed ms-4 rounded-end rounded-lg">
                <RoundedLineIconWithTooltip
                  icon={crewPlanning}
                  {...getConsentSeamanStatusParams(data)}
                />
              </div>
            );
          },
          actions: data => {
            const obligationId = _get(data, 'id', '');
            const evaluateeId = _get(data, 'evaluatee_id', '');
            const evaluationId = _get(data, 'evaluation.id');

            const evaluateSeamanAction = canEvaluate
              ? {
                  className: 'text-white',
                  wrapperClassName: 'bg-primary',
                  label: 'Evaluate Seaman',
                  icon: crewPlanning,
                  onClick: evaluateeId
                    ? () => {
                        history.push({
                          pathname: `${paths.crew}/${evaluateeId}/evaluation/create`,
                          search: stringifyObj({
                            obligation_id: obligationId
                          })
                        });
                      }
                    : () => {}
                }
              : {};

            const viewEvaluationAction =
              evaluationId &&
              (canSeeEvaluations || (evaluateeId === account.id && canSeeOwnEvaluations))
                ? {
                    className: 'text-primary',
                    wrapperClassName: 'bg-white',
                    label: 'View Evaluation',
                    icon: eyeSvg,
                    onClick: evaluateeId
                      ? () => {
                          history.push({
                            pathname: `${paths.crew}/${evaluateeId}/evaluation/${
                              isDraftEvaluation(data?.evaluation, true) ? 'draft' : 'view'
                            }/${evaluationId}`
                          });
                        }
                      : () => {}
                  }
                : {};

            return {
              options: [
                activeTab === 'pending'
                  ? !data.evaluation
                    ? evaluateSeamanAction
                    : viewEvaluationAction
                  : activeTab === 'completed'
                  ? viewEvaluationAction
                  : {}
              ]
            };
          }
        }}
        {...table}
      />
    </div>
  );
};

export default Evaluations;
