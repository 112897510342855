import React, { useCallback } from 'react';

import {
  selectVesselSystem,
  selectVesselSystemAssignment,
  selectTotalVesselSystemAssignmentJobs,
  selectAllExpanded
} from 'common/components/pms/jobs/store/selectors';

import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import { numberToStr } from 'common/utils/numbers';

import CollapseColoredBox from 'common/components/collapse/CollapseColoredBox';
import VesselSystemInfo from 'common/components/pms/jobs/components/VesselSystemInfo';
import ReportLink from 'captain-reports/components/ReportLink';

import Jobs from './jobs';

import * as pmsJobsActions from 'common/components/pms/jobs/store/actions';
import moment from 'moment';

const RunningHoursTotals = ({ vesselSystemAssignment }) => (
  <>
    {vesselSystemAssignment.last_date_of_running_hours_reading
      ? moment(vesselSystemAssignment.last_date_of_running_hours_reading).format('DD/MM/YYYY')
      : '-'}{' '}
    {vesselSystemAssignment.running_hours
      ? `| ${numberToStr(vesselSystemAssignment.running_hours)} rh`
      : ''}
  </>
);

const Header = ({ vesselSystemAssignmentID }) => {
  const vesselSystem = useSelector(state => selectVesselSystem(state, vesselSystemAssignmentID));
  const vesselSystemAssignment = useSelector(state =>
    selectVesselSystemAssignment(state, vesselSystemAssignmentID)
  );

  const totalJobs = useSelector(state =>
    selectTotalVesselSystemAssignmentJobs(state, vesselSystemAssignmentID)
  );

  return (
    <div className="d-flex align-items-center fs-12">
      <VesselSystemInfo
        vesselSystem={vesselSystem}
        cmsReferenceCode={vesselSystemAssignment?.cms_reference_code}
        attributes={{
          is_class: vesselSystemAssignment?.is_class,
          is_critical: vesselSystemAssignment?.is_critical,
          is_environmental_critical: vesselSystemAssignment?.is_environmental_critical,
          is_navigational: vesselSystemAssignment?.is_navigational
        }}
      />

      <div className="d-flex align-items-center ms-auto pe-4">
        {vesselSystemAssignment.last_date_of_running_hours_reading ||
        vesselSystemAssignment.running_hours ? (
          <>
            <span className="fw-normal text-violet pe-3">
              Last r/h report:{' '}
              {vesselSystemAssignment.running_hours_report_id ? (
                <ReportLink
                  className="link-medium text-violet"
                  templateType={'system_running_hours'}
                  reportId={vesselSystemAssignment.running_hours_report_id}
                  target="_blank"
                >
                  <RunningHoursTotals vesselSystemAssignment={vesselSystemAssignment} />
                </ReportLink>
              ) : (
                <RunningHoursTotals vesselSystemAssignment={vesselSystemAssignment} />
              )}
            </span>
            <span className="seperator-violet height-12 me-3 ms-0"></span>
          </>
        ) : null}
        <strong>
          {totalJobs} job{totalJobs === 1 ? '' : 's'}
        </strong>
      </div>
    </div>
  );
};

const CollapseBox = ({ vesselSystemAssignmentID }) => {
  const [setExpandedAssignment] = useActions([pmsJobsActions.setExpandedAssignment]);
  const allExpanded = useSelector(selectAllExpanded);

  const onExpandCallback = useCallback(
    isOpen => setExpandedAssignment(vesselSystemAssignmentID, isOpen),
    [vesselSystemAssignmentID, setExpandedAssignment]
  );

  return (
    <CollapseColoredBox
      header={<Header vesselSystemAssignmentID={vesselSystemAssignmentID} />}
      headerClassName="w-100p"
      expandButton={false}
      hideExpandButton={true}
      onExpandCallback={onExpandCallback}
      isDefaultOpened={allExpanded}
    >
      <Jobs vesselSystemAssignmentID={vesselSystemAssignmentID} />
    </CollapseColoredBox>
  );
};

export default CollapseBox;
