import React from 'react';
import PageTitle from 'common/components/general/PageTitle';

const Title = ({ pageTitle }) => {
  return (
    <div className="page-table-layout--header-title d-flex align-items-center">{pageTitle}</div>
  );
};

const TitleLayout = ({ title }) => {
  return title ? <PageTitle render={() => <Title pageTitle={title} />} /> : null;
};

export default TitleLayout;
