import React from 'react';
import CustomSelect, { SelectLabel } from 'events/components/Select';
import { useSelector } from 'react-redux';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import OnMenuCloseSelector from 'common/components/selectors/OnMenuCloseSelector';

const Responsibility = ({
  responsible,
  departmentIDs,
  approvers,
  disabled,
  onResponsibleChange,
  onDepartmentsChange,
  onApproversChange
}) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <>
      <div className="fs-12 text-primary fw-bold mt-3 ps-1">Responsibility</div>
      <CustomSelect
        value={responsible}
        type="person"
        list="parties"
        disabled={disabled}
        rest={{ can_login: true, onboard: isOnBoard || undefined }}
        className="mt-3 ps-1"
        onChange={onResponsibleChange}
      />
      <CustomSelect
        value={departmentIDs}
        type="department"
        list="parties"
        className="mt-3 ps-1 mb-2"
        disabled={disabled}
        onChange={onDepartmentsChange}
      />
      {!isOnBoard ? (
        <OnMenuCloseSelector
          isMulti
          isAsync
          label={<SelectLabel type={'approvers'} />}
          value={approvers}
          onChange={onApproversChange}
          type="parties"
          className="mt-3 ps-1"
          disabled={disabled}
          placeholder="Select Approvers"
          getOptionValue={option => option.id}
          getOptionLabel={option => option.full_name}
          loadOptions={search =>
            getAsyncOptions(search, 'parties', { can_login: true, onboard: isOnBoard })
          }
          defaultOptions={() =>
            getInitialAsyncValues('parties', null, null, {
              can_login: true,
              onboard: isOnBoard
            })
          }
        />
      ) : null}
    </>
  );
};

export default Responsibility;
