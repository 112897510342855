const keys = [
  'captain-report-templates',
  'ports',
  'captain-report-enums-boiler-colors',
  'captain-report-enums-instructed-speed-consumption-by',
  'captain-report-enums-instructed-speed-consumption-target',
  'captain-report-enums-seabed-types',
  'units',
  'purpose-of-call',
  'berth-type',
  'ld-stopping-reason',
  'ld-hired-gear-type',
  'stoppage-reason',
  'stoppage-associated-element',
  'status-at-port',
  'terms',
  'genders',
  'crew-evaluation-templates',
  'crew-evaluation-reasons',
  'cargo-grades',
  'report-groups',
  'event-importances',
  'job-statuses',
  'job-importances',
  'job-template-types',
  'event-statuses',
  'event-types',
  'events',
  'event-modules',
  'departments',
  'forms',
  'fuel-grades',
  'vessel-fuel-tank-categories',
  'tank-measurement-method',
  'job-statuses',
  'party-and-crew-departments',
  'captain-report-drifting-reason',
  'captain-report-enums-delivered-at',
  'captain-report-enums-holds-on-delivery',
  'captain-report-enums-instructed-speed-allowance-unit',
  'captain-report-enums-instructed-consumption-allowance-unit',
  'captain-report-enums-phase-shift',
  'captain-report-enums-co-base-number',
  'form-statuses',
  'event-statuses',
  'importances',
  'drills',
  'schedule-intervals',
  'purchasing-categories',
  'mga-accounts',
  'mga-action-types',
  'mga-period-statuses',
  'stores',
  'extension-and-dispensation-types',
  'events_underwater_services',
  'finding-categories',
  'finding-types',
  'finding-tags',
  'crew-members',
  'currencies',
  'purchasing-units',
  'purchasing-requisition-statuses'
];

export default keys;
