import { useSelector } from 'react-redux';
import { selectDashboardTotals } from 'store/dashboard/totals/selectors';
import LinkTo from 'views/dashboard/components/LinkTo';
import paths from 'routing/routes/_paths';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from 'common/utils/permissions/constants';

const ContentPurchasingCases = () => {
  const totals = useSelector(state => selectDashboardTotals(state, 'purchasing_requisitions'));

  return totals ? (
    <div className="d-flex align-items-center justify-content-between">
      <div className="fs-20 fw-bold line-height-1 text-primary me-1">
        {totals?.to_be_delivered || 0}
      </div>
      <AuthCheck permissions={[permissions.onboard.purchasing.requisitions.view]}>
        <LinkTo bg="white" to={`${paths.purchasing_requisitions}/dlv`} />
      </AuthCheck>
    </div>
  ) : null;
};

export default ContentPurchasingCases;
