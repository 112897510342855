import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTable } from 'common/components/table';
import {
  selectIsLoading,
  selectTotalVesselSystemAssignments,
  selectJobDrawerIsOpen,
  selectTablePaging
} from 'common/components/pms/jobs/store/selectors';

import * as pmsJobsActions from 'common/components/pms/jobs/store/actions';

import { useActions } from 'utils/hooks';
import _get from 'lodash/get';

import systemColumns from 'common/components/pms/jobs/views/system/_tableColumns';
import TableFilters from 'common/components/pms/jobs/components/TableFilters';
import TopFilters from 'common/components/pms/jobs/components/TableTopFilters';
import Table from './table';
import TableEmptyPage from 'common/components/pms/jobs/components/TableEmptyPage';

import PageLoader from 'common/components/general/PageLoader';

import { usePageInfiniteScroll } from 'utils/hooks';

import { selectTableShouldRefetchData } from 'common/components/jobs/_base/store/selectors';
import * as jobProfileActions from 'common/components/jobs/_base/store/actions';

const Body = () => {
  const total = useSelector(selectTotalVesselSystemAssignments);
  const isLoading = useSelector(selectIsLoading);
  const paging = useSelector(selectTablePaging);
  const jobDrawerIsOpen = useSelector(selectJobDrawerIsOpen);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const [getVesselSystemMaintenanceJobs, setTableShouldRefetchData] = useActions([
    pmsJobsActions.getVesselSystemMaintenanceJobs,
    jobProfileActions.setTableShouldRefetchData
  ]);

  const tableShouldRefetchData = useSelector(selectTableShouldRefetchData);

  const fetchData = async (params, extraRequestParams = {}) => {
    if (
      (!isOnBoard && _get(params, 'filters', []).find(f => f.name === 'vessel_id' && f.value)) ||
      isOnBoard
    ) {
      return await getVesselSystemMaintenanceJobs({ ...params, ...extraRequestParams });
    }
  };

  const table = useTable({
    label: 'pms_jobs_per_system',
    defaultRequestParams: { visible: false, paging: false, sorting: false, filters: true },
    requestTableListFrom: fetchData,
    columns: systemColumns,
    top: {
      filters: [
        {
          name: 'vessel_id',
          operation: 'oneOf',
          value: null
        },
        {
          name: 'is_class',
          operation: '=',
          value: null
        },
        {
          name: 'is_critical',
          operation: '=',
          value: null
        },
        {
          name: 'is_environmental_critical',
          operation: '=',
          value: null
        },
        {
          name: 'is_navigational',
          operation: '=',
          value: null
        },
        {
          name: 'is_overdue',
          operation: '=',
          value: null
        },
        {
          name: 'is_postponed',
          operation: '=',
          value: null
        },
        {
          name: 'becoming_due',
          operation: '=',
          value: null
        }
      ]
    }
  });

  const hasSelectedVessel = isOnBoard
    ? true
    : table.topFilters?.find(f => f.name === 'vessel_id' && f.value);

  const loadMorePages = () => {
    table.refetchData({}, { loadMore: true });
  };

  usePageInfiniteScroll(
    paging?.last_page,
    loadMorePages,
    table.requestParams,
    300,
    jobDrawerIsOpen
  );

  useEffect(() => {
    if (!jobDrawerIsOpen && tableShouldRefetchData) {
      table.refetchData();
      setTableShouldRefetchData(false);
    }
  }, [jobDrawerIsOpen, setTableShouldRefetchData, table, tableShouldRefetchData]);

  return (
    <>
      <TopFilters hasSelectedVessel={hasSelectedVessel} table={{ ...table }} />

      {hasSelectedVessel ? (
        <TableFilters
          setFilters={table.setFilters}
          tableLabel={table.label}
          topFilters={table.topFilters}
          columns={systemColumns}
        />
      ) : null}

      {!hasSelectedVessel || (hasSelectedVessel && !isLoading && !total) ? (
        <TableEmptyPage
          shouldExpandAll
          hasValidFilters={hasSelectedVessel}
          isLoading={isLoading}
          total={total}
        />
      ) : hasSelectedVessel ? (
        <Table />
      ) : null}

      <PageLoader isLoading={isLoading} />
    </>
  );
};

export default Body;
