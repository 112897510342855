import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from 'common/components/general/PageTitle';
import PageActions from 'components/layouts/page/table/PageActions';
import PageLoader from 'common/components/general/PageLoader';
import SvgRender from 'common/components/general/SvgRender';
import add from 'common/assets/svg/actions/add.svg';
import useDrawer from 'common/components/drawer/useDrawer';
import NewSurveyDrawer from 'common/components/inventory/survey';
import HistoryDrawer from '../history';

import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';

import TableHeader from './TableHeader';
import Table from './Table';
import useInventoryTable from './useInventoryTable';
import SaveTableLayout from 'components/layouts/page/table/save-table';

const InventoryTable = ({ type }) => {
  const drawer = useDrawer(false);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const { topFilters, setTopFilters, fetching, label, refetchData, filters, setFilters } =
    useInventoryTable({ type });
  const singleVesselView = topFilters?.find(f => f.name === 'vessel_id')?.value?.length === 1;

  return (
    <>
      <div className="d-flex align-items-center">
        {type === 'pms' ? (
          <SaveTableLayout
            label={label}
            pageTitle="Spare Parts Inventory"
            className={`mb-0 pb-0 ${isOnBoard ? '' : 'w-100p'}`}
            pageActions={[{ type: 'favorite' }]}
          />
        ) : (
          <PageTitle title="Stores Inventory" className="mb-0 pb-0" />
        )}

        <PageActions
          actions={[
            {
              customElement: () =>
                isOnBoard ? (
                  <Button
                    className={`d-flex align-items-center text-dark`}
                    onClick={() => drawer.open()}
                    color="yellow"
                  >
                    <SvgRender src={add} style={{ height: 14, width: 14 }} className="me-1" />
                    New Survey
                  </Button>
                ) : null
            }
          ]}
          className="pe-0 align-items-start"
        />
      </div>

      <TableHeader
        label={label}
        topFilters={topFilters}
        setTopFilters={setTopFilters}
        singleVesselView={singleVesselView}
      />

      <Table
        label={label}
        topFilters={topFilters}
        setTopFilters={setTopFilters}
        fetching={fetching}
        refetchData={refetchData}
        singleVesselView={singleVesselView}
        filters={filters}
        setFilters={setFilters}
      />

      {isOnBoard ? <NewSurveyDrawer drawer={drawer} refetchData={refetchData} type={type} /> : null}
      <HistoryDrawer />
      <PageLoader isLoading={fetching} />
    </>
  );
};

InventoryTable.propTypes = {
  type: PropTypes.oneOf(['pms', 'purchasing']).isRequired
};

export default InventoryTable;
