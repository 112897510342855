import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import paths from '@/routing/routes/_paths.js';

const FormSubmissions = React.lazy(() =>
  retry(() => import('@/views/forms/submissions/index.jsx'))
);
const FormPlanning = React.lazy(() => retry(() => import('@/views/forms/planning/index.jsx')));
const FormObligations = React.lazy(() =>
  retry(() => import('@/common/components/form-obligations/index.jsx'))
);

const routes = [
  {
    path: [`${paths.forms}`, `${paths.forms}/:form_id`],
    component: FormSubmissions,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.forms.view],
    documentTitle: 'Forms'
  },
  {
    path: [`${paths.form_planning}`, `${paths.form_planning}/:form_id`],
    component: FormPlanning,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.forms.view],
    documentTitle: 'Form Planning'
  },
  {
    path: `${paths.form_obligations}`,
    component: FormObligations,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.forms.view],
    documentTitle: 'Form Obligations'
  }
];
export default routes;
