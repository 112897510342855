import _sumBy from 'lodash/sumBy';
import PropTypes from 'prop-types';

const TotalsBar = ({ legends, color = 'primary' }) => {
  const sum = _sumBy(legends, legend => legend.total);

  return (
    <div className="totals-bar-graph">
      <div className="totals-bar-graph--inner d-flex flex-nowrap">
        {legends
          .filter(legend => legend.total)
          .map((legend, index) => (
            <div
              key={index}
              className="totals-bar-graph--count"
              style={{ maxWidth: (legend.total / sum) * 100 + '%' }}
            >
              <div
                className="totals-bar-graph--count-bg"
                style={{ backgroundColor: legend.color }}
              ></div>
              <div className={`totals-bar-graph--count-number text-${color}`}>
                <strong>{legend.total}</strong>
              </div>
            </div>
          ))}
      </div>
      <div className="totals-bar-graph--legends d-flex flex-wrap mt-2">
        {legends.map((legend, index) => (
          <div className="d-inline-flex align-items-center me-1 cmb-4" key={index}>
            <div
              className="totals-bar-graph--legends-bg"
              style={{ backgroundColor: legend.color }}
            ></div>
            <div className="totals-bar-graph--legends-name">
              <strong className="fs-12 text-violet fw-medium">{legend.label}</strong>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

TotalsBar.propTypes = {
  color: PropTypes.string, // bootstrap theme color
  legends: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.label,
      total: PropTypes.number
    })
  )
};

export default TotalsBar;
