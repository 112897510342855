import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectCrewEvaluationsIsFetching,
  selectCrewEvaluations,
  selectGraphData
} from 'crew/store/evaluations/profile/selectors';

import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useActions } from 'utils/hooks';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import _get from 'lodash/get';

import paths from 'routing/routes/_paths';
import * as listActions from 'store/lists/actions';
import * as evaluationActions from 'crew/store/evaluations/profile/actions';

import HeaderTabs from './components/HeaderTabs';
import IconSelect from 'common/components/form/styled-inputs/IconSelect';

import SingleEvaluation from './components/SingleEvaluation';
import PageLoader from 'common/components/general/PageLoader';
import CircleButton from 'common/components/buttons/CircledButton';
import { selectCrewProfile } from 'crew/store/profile/selectors';
import { numberToStr } from 'common/utils/numbers';

import star from 'common/assets/svg/common/star.svg';
import EmptyPage from 'common/components/general/EmptyPage';
import { isAuthorized } from 'utils/permissions/authorize';

import vesselIcon from 'common/assets/svg/common/vessels.svg';
import contractIcon from 'common/assets/svg/crew/contracts.svg';
import permissions from 'common/utils/permissions/constants';
import { selectAccount } from 'store/account/selectors';
import { useAppSelector } from '@/store/hooks';
import { selectEvaluationsRangeTo } from '@/store/settings/selectors';

const ReceivedEvaluations = ({ id, isOnboard, components }) => {
  const [evaluationsType, setEvaluationsType] = useState('received');
  const [vessel, setVessel] = useState(null);
  const [contact, setContact] = useState(null);
  const graphData = useSelector(selectGraphData);
  const crew = useSelector(selectCrewProfile);
  const crewAverage = _get(crew, 'average_score', '0');
  const maxScore = useAppSelector(selectEvaluationsRangeTo);

  const history = useHistory();

  const [getCrewEvaluations, getCrewEvaluationStatistics, fetchListOptions] = useActions([
    evaluationActions.getCrewEvaluations,
    evaluationActions.getCrewEvaluationStatistics,
    listActions.fetchListOptions
  ]);

  const account = useSelector(selectAccount);
  const isFetching = useSelector(selectCrewEvaluationsIsFetching);
  const evaluations = useSelector(selectCrewEvaluations);
  const canEvaluate =
    isAuthorized(account, [
      permissions.office.crew.seaman_profile.evaluations.create,
      permissions.onboard.crew.seaman_profile.evaluations.all_users.create
    ]) && account.id !== +id;
  const vessels = useSelector(state => _get(state, 'lists.vessels.options', null));

  const goToEvaluationPage = () => {
    history.push(`${paths.crew}/${id}/evaluation/create`);
  };

  const fetchData = useCallback(
    params => {
      const evaluator = evaluationsType === 'given';
      const vesselInfo = vessel ? { vessel_id: vessel } : {};
      const contractInfo = contact?.id ? { contract_id: contact.id } : {};

      getCrewEvaluations({ id, evaluator, ...vesselInfo, ...contractInfo, ...params });

      if (!isOnboard) {
        getCrewEvaluationStatistics({ id });
      }
    },
    [
      contact?.id,
      evaluationsType,
      getCrewEvaluationStatistics,
      getCrewEvaluations,
      id,
      isOnboard,
      vessel
    ]
  );

  useEffect(() => {
    if (!isOnboard) fetchListOptions('vessels');
  }, [fetchListOptions, isOnboard]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const EmptyPageText = () => {
    let title = '';

    if (evaluationsType === 'given') {
      title = 'This seaman has not given an evaluation yet.';
    } else {
      title = 'This seaman has not been evaluated yet.';
    }

    return <div>{title}</div>;
  };

  return (
    <div>
      <HeaderTabs type={evaluationsType} setType={setEvaluationsType} />
      {evaluations?.length ? null : !isFetching && !vessel && !contact ? (
        <EmptyPage
          className={`ms-9 crew-empty-state crew-empty-state--evaluate-seaman ${
            evaluationsType === 'given' ? 'crew-empty-state--evaluate-seaman-given' : 'pt-17'
          }`}
          pageText={<EmptyPageText />}
          svgClassName="bg-yellow text-primary"
          buttonOnClick={canEvaluate && evaluationsType === 'received' ? goToEvaluationPage : null}
          buttonText={'Evaluate Seaman'}
          buttonType="add"
          subTitle={
            canEvaluate || evaluationsType === 'given'
              ? null
              : '*You do not have permission to evaluate.'
          }
          pageIcon={star}
        />
      ) : null}

      <>
        {!isOnboard && evaluationsType !== 'given' ? (
          <>
            {evaluations?.length ? (
              <div className="d-flex align-items-center mt-2 evaluation-graph-container">
                <div className="evaluation-average-graph-container">
                  <components.EvaluationsAverageGraph graphData={graphData} />
                </div>

                <div className="d-flex flex-column align-items-center ms-7">
                  <span className="fw-bold text-primary fs-10">AVERAGE RATING</span>
                  <div className="d-flex align-items-center fs-20 my-1 cpb-4">
                    <span className="text-primary fw-bold">{numberToStr(crewAverage, 2, 2)}</span>
                    <span className="text-violet cme-6 cms-6">/</span>
                    <span className="text-violet">{maxScore}</span>
                  </div>
                </div>
              </div>
            ) : null}

            <hr className="mt-3" />
          </>
        ) : null}

        <Row
          className={`align-items-center ${evaluationsType === 'given' ? 'mt-4' : 'mt-3'} w-100p`}
          noGutters
        >
          {!isOnboard && (
            <Col xs={2} className="cme-12">
              <IconSelect
                placeholder={'Select vessel'}
                icon={vesselIcon}
                size="sm"
                iconStyle={{ color: '#354069', width: 15, height: 15 }}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
                value={vessel}
                options={vessels}
                onChange={setVessel}
                className="mb-0"
                isClearable
              />
            </Col>
          )}
          <Col xs={3}>
            <IconSelect
              placeholder={'Select contract'}
              icon={contractIcon}
              size="sm"
              iconStyle={{ color: '#354069', width: 14, height: 14 }}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              value={contact}
              onChange={setContact}
              className="mb-0"
              isClearable
              isAsync
              defaultOptions={() =>
                getInitialAsyncValues('crew-contracts', null, null, { crew_member_id: id })
              }
              loadOptions={search =>
                getAsyncOptions(search, 'crew-contracts', { crew_member_id: id })
              }
            />
          </Col>

          <Col xs={'auto'} className="ms-auto">
            {canEvaluate && evaluationsType === 'received' ? (
              <CircleButton
                type="evaluate"
                labelClassName="fs-14"
                label="Evaluate Seaman"
                reversed
                onClick={goToEvaluationPage}
                svgStyle={{ width: 12, height: 12 }}
              />
            ) : null}
          </Col>
        </Row>

        {evaluations?.length ? (
          <div className={`evaluations-list mt-2`}>
            {evaluationsType === 'received' ? (
              <Row noGutters className="evaluations-list--header">
                <Col xs={3} className="ps-2">
                  RANK / VESSEL
                </Col>
                <Col className="evaluations-list--row--date">DATE RECEIVED</Col>
                <Col className="pe-2">REASON - OBLIGATION</Col>
                <Col>EVALUATOR</Col>
              </Row>
            ) : (
              <Row noGutters className="evaluations-list--header">
                <Col xs={4} className="ps-2">
                  EVALUATEE / RANK
                </Col>
                <Col xs={2}>VESSEL</Col>
                <Col className="evaluations-list--row--date">DATE GIVEN</Col>
                <Col>REASON - OBLIGATION</Col>
              </Row>
            )}

            {evaluations.map((evaluation, index) => (
              <SingleEvaluation
                key={index}
                isReceived={evaluationsType === 'received'}
                evaluation={evaluation}
              />
            ))}
          </div>
        ) : (
          <div className="w-100p d-flex justify-content-center align-items-center mt-20 fs-20">
            No results found
          </div>
        )}
      </>
      <PageLoader isLoading={isFetching} />
    </div>
  );
};

export default ReceivedEvaluations;
