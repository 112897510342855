import Tooltip from 'common/components/general/Tooltip';

const ShiftsPopup = ({ children, title, className, ...rest }) => {
  return (
    <Tooltip className={`shifts-popup ${className || ''}`} {...rest} hideArrow>
      <div className="shifts-popup__header">{title}</div>
      <div className="shifts-popup__body">{children}</div>
    </Tooltip>
  );
};

export default ShiftsPopup;
