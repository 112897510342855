import { useEffect } from 'react';
import PropTypes from 'prop-types';

import Table, { useTable } from 'common/components/table';
import SvgRender from 'common/components/general/SvgRender';
import VesselGroupSelector from 'common/components/selectors/VesselGroupSelector';
import PersonSelector from 'common/components/selectors/PersonSelector';
import ReportStatus from 'captain-reports/components/ReportStatus';
import ValidationWarning from 'captain-reports/components/ValidationWarning';
import AlarmContainer from 'captain-reports/components/alarms/Container';
import Date from 'captain-reports/components/Date';
import useActions from 'common/utils/hooks/useActions';
import LoadingSituationSelector from 'common/components/selectors/LoadingSituationSelector';
import SaveTableLayout from 'components/layouts/page/table/save-table';

import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import paths from 'routing/routes/_paths';

import eyeSvg from 'common/assets/svg/common/eye.svg';
import ballast from 'captain-reports/assets/svg/ballast.svg';
import laden from 'captain-reports/assets/svg/laden.svg';

import TopFilters from './TopFilters';
import MorePageActions from './MorePageActions';

import * as tableListActions from 'store/tables/lists/actions';
import * as listActions from 'store/lists/actions';
import CaptainReportsStatusSelector from 'common/components/selectors/CaptainReportsStatusSelector';
import PageLink from 'common/components/general/page-link';

const CaptainReportsTable = ({ actions = {}, components = {} }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  const { getRunningHoursReportsAlarms } = actions;

  const [getTableList, fetchListOptions] = useActions([
    tableListActions.getTableList,
    listActions.fetchListOptions
  ]);

  const fetchData = async params => {
    const { filters } = params;
    const updatedFilters = [
      ...filters,
      {
        name: 'type',
        operation: 'oneOf',
        value: ['system_running_hours']
      }
    ];
    await getTableList({ ...params, filters: updatedFilters });
  };

  const table = useTable({
    label: 'captain_reports_running_hours',
    requestTableListFrom: fetchData,
    columns: [
      {
        header: 'Vessel',
        key: 'vessel_name',
        maxWidth: 200,
        minWidth: 200,
        hiddenOnBoard: true,
        sort: true
      },
      {
        header: 'Vessel Groups',
        key: 'vessel_groups',
        type: 'collection',
        component: VesselGroupSelector,
        componentRest: { isMulti: true },
        hidden: true,
        hiddenOnBoard: true
      },
      {
        header: 'Status',
        sort: true,
        key: 'status',
        type: 'collection',
        component: CaptainReportsStatusSelector,
        componentRest: { isMulti: true },
        minWidth: 90,
        maxWidth: 90
      },
      {
        header: 'Local Date & time',
        key: 'local_timestamp',
        type: 'datetime',
        sort: true,
        showUtc: true,
        maxWidth: 160,
        minWidth: 160
      },
      {
        header: 'UTC Date & time',
        key: 'timestamp',
        type: 'datetime',
        sort: true,
        showUtc: true,
        maxWidth: 160,
        minWidth: 160
      },
      {
        header: 'Approved By',
        key: 'approved_by_id',
        type: 'collection',
        component: PersonSelector,
        componentRest: { isMulti: true, listParams: { can_login: true } },
        sort: false,
        hidden: true,
        hiddenOnBoard: true
      },
      {
        header: 'Approved By',
        key: 'approved_by',
        type: 'collection',
        canFilter: false,
        sort: true,
        maxWidth: 280,
        minWidth: 280
      },
      {
        header: 'Approved At',
        key: 'approved_at',
        type: 'datetime',
        sort: true,
        maxWidth: 160,
        minWidth: 160
      },
      {
        header: 'Reviewed By',
        key: 'reviewed_by_tech_id',
        type: 'collection',
        component: PersonSelector,
        componentRest: { isMulti: true, listParams: { can_login: true } },
        sort: false,
        hidden: true,
        maxWidth: 160,
        minWidth: 160,
        hiddenOnBoard: true
      },
      {
        header: 'Reviewed By',
        key: 'reviewed_by_tech',
        type: 'collection',
        canFilter: false,
        sort: true,
        maxWidth: 280,
        minWidth: 280
      },
      {
        header: 'Reviewed At',
        key: 'reviewed_by_tech_at',
        type: 'datetime',
        sort: true,
        maxWidth: 160,
        minWidth: 160
      },
      {
        header: 'Situation',
        key: 'loading_condition',
        component: LoadingSituationSelector,
        componentRest: { isMulti: true },
        className: 'text-center',
        type: 'collection',
        maxWidth: 184,
        minWidth: 184
      },
      {
        header: 'Actions',
        key: 'actions',
        field: 'actions'
      }
    ],
    top: {
      filters: [
        {
          name: 'vessel',
          operation: 'oneOf',
          value: null,
          initialValue: null
        }
      ]
    }
  });

  useEffect(() => {
    if (getRunningHoursReportsAlarms) getRunningHoursReportsAlarms();

    fetchListOptions(`report-groups`);
  }, [getRunningHoursReportsAlarms, fetchListOptions]);

  return (
    <Row>
      <Col>
        <SaveTableLayout pageTitle={'Running Hours'} label="captain_reports_running_hours" />
      </Col>
      <Col xs="auto" className="d-flex align-items-start">
        <MorePageActions topFilters={table.topFilters} component={components.CreateReportAction} />
      </Col>
      <Col xs={12}>
        <Table
          hideTopPagination={!isOnBoard}
          topCustomComponent={!isOnBoard ? <AlarmContainer className="cmb-4 cp-20" /> : null}
          topFiltersComponent={
            !isOnBoard ? (
              <TopFilters
                table={table}
                topFilters={table.topFilters}
                setTopFilters={table.setTopFilters}
              />
            ) : null
          }
          loader
          rows={{
            vessel_name: ({ vessel, id, has_validation_errors }) => (
              <div className="d-flex align-items-center">
                <PageLink className="flex-1 pe-1" to={`${paths.pms_running_hours}/${id}`}>
                  {vessel.name}
                </PageLink>

                {has_validation_errors ? (
                  <ValidationWarning className="me-5" target={`validation-${id}`} />
                ) : null}
              </div>
            ),
            status: ({ status, id }) => (
              <ReportStatus target={`a${id}-${status}`} status={status} className="ms-2" />
            ),
            loading_condition: ({ loading_condition }) => (
              <SvgRender
                src={loading_condition === 'laden' ? laden : ballast}
                style={{ width: 18, height: 18 }}
              />
            ),
            timestamp: ({ timestamp }) => <Date value={timestamp} withTime />,
            reviewed_by_tech: ({ reviewed_by_tech }) => (
              <div>{reviewed_by_tech?.full_name || ''}</div>
            ),
            approved_by: ({ approved_by }) => <div>{approved_by?.full_name || ''}</div>,
            local_timestamp: ({ local_timestamp }) => <Date value={local_timestamp} withTime />,
            actions: data => ({
              options: [
                {
                  label: 'View',
                  icon: eyeSvg,
                  onClick: () => window.open(`${paths.pms_running_hours}/${data.id}`)
                }
              ]
            })
          }}
          {...table}
        />
      </Col>
    </Row>
  );
};

CaptainReportsTable.propTypes = {
  actions: PropTypes.shape({ getRunningHoursReportsAlarms: PropTypes.func }),
  components: PropTypes.shape({ BulkActionsButton: PropTypes.func })
};

export default CaptainReportsTable;
