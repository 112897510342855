import React, { useEffect, useState } from 'react';
import NavigationArrows from 'common/components/general/NavigationArrows';
import { useSelector } from 'react-redux';
import { selectTableListDataIds } from 'common/components/table/store/selectors';
import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';
import { selectJobField } from 'common/components/jobs/_base/store/selectors';
import {
  selectVesselSystemAssignmentJobs,
  selectMaintenanceDetaisVesselSystemAssignmentId
} from 'common/components/pms/jobs/store/selectors';

const NavigationDrawerTabs = () => {
  const [previousJob, setPreviousJob] = useState(null);
  const [nextJob, setNextJob] = useState(null);

  const jobID = useSelector(state => selectJobField(state, 'id'));
  const systemAssignmentID = useSelector(selectMaintenanceDetaisVesselSystemAssignmentId);

  const { history, match } = useRouter();

  const tableData = useSelector(state =>
    history.location?.pathname?.includes(`${paths?.pms_jobs_per_system}`)
      ? selectVesselSystemAssignmentJobs(state, systemAssignmentID)
      : selectTableListDataIds(state, 'pms_jobs_list')
  );

  const onArrowClick = id => {
    history.push({
      pathname: history.location.pathname.replace(`${match?.params?.jobID}`, `${id}`),
      search: history.location.search
    });
  };

  useEffect(() => {
    // We are using  '==' because id is a different type on core/on board.
    const jobIndex = tableData?.findIndex(field => field === jobID);

    if (jobIndex !== -1) {
      setNextJob(tableData?.[jobIndex + 1]);
      setPreviousJob(tableData?.[jobIndex - 1]);
    } else {
      setNextJob(null);
      setPreviousJob(null);
    }
  }, [jobID, tableData?.length, tableData, setNextJob, setPreviousJob]);

  return (
    <NavigationArrows
      disabledNext={!nextJob}
      disabledPrevious={!previousJob}
      onNextClick={() => onArrowClick(nextJob)}
      onPreviousClick={() => onArrowClick(previousJob)}
      className={'m-0 ps-2 border-start'}
    />
  );
};

export default NavigationDrawerTabs;
