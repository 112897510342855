import { useState } from 'react';
import { Row, Col } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';

import edit from 'common/assets/svg/actions/edit-empty.svg';
import remove from 'common/assets/svg/actions/remove.svg';
import reset from 'common/assets/svg/actions/reset.svg';
import deactivate from 'common/assets/svg/actions/deactivate.svg';

import ColoredTable from 'common/components/colored-table';
import IssueDate from './IssueDate';
import CreatedAtDate from './CreatedAtDate';
import DocumentHistory from './DocumentHistory';

import { selectDocumentTypeCategories } from 'store/vessels/selectors';
import { isAuthorized } from 'utils/permissions/authorize';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import ExpirationDate from '../components/ExpirationDate';
import Departments from 'common/components/general/Departments';
import permissions from 'common/utils/permissions/constants';
import DocumentRemindersTooltip from '../components/DocumentRemindersTooltip';
import { selectAccount } from 'store/account/selectors';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  archiveDocumentDocumentAction,
  changeVesselDocumentDrawerStatus,
  downloadVesselDocumentAttachments,
  getVesselDocumentAttachmentsAction
} from '@/common/components/vessels/profile/tabs/documents/store/actions';
import { DOCUMENT_TEMPLATES } from 'common/components/vessels/profile/tabs/documents/helpers';

const CategoriesTable = ({ vesselId, type }) => {
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const account = useAppSelector(selectAccount);
  const dispatch = useAppDispatch();

  const state = useAppSelector(state => selectDocumentTypeCategories(state, type.id));

  const [isDownloading, setIsDownloading] = useState(false);

  const downloadAttachments = async data => {
    setIsDownloading(true);
    const res = await downloadVesselDocumentAttachments({ id: data.document_id });
    setIsDownloading(false);

    return res;
  };

  const requestAttachments = async data => {
    try {
      return await dispatch(getVesselDocumentAttachmentsAction({ id: data.id })).unwrap();
    } catch (e) {
      console.error(e);
    }
  };

  const isSurveyTemplate = type.template === DOCUMENT_TEMPLATES.surveys;
  const isItemCertificateTemplate = type.template === DOCUMENT_TEMPLATES.item_certificate;

  const canEdit = isOnBoard
    ? isAuthorized(account, [permissions.onboard.vessel.documents.edit])
    : isAuthorized(account, [permissions.office.vessels.documents.edit]);

  const getDocumentActions = data => {
    const options = [];

    if (canEdit) {
      options.push({
        label: 'Delete',
        icon: remove,
        preventionModal: true,
        modalProps: {
          onAccept: () =>
            dispatch(
              archiveDocumentDocumentAction({ vessel_id: vesselId, document_id: data.document_id })
            ),
          header: 'Delete',
          body: (
            <div className="text-center">
              You are about to delete this document. <br />
              Are you sure you want to continue?
            </div>
          )
        }
      });

      if (data.active) {
        options.push(
          {
            label: 'Renew',
            icon: reset,
            onClick: _ =>
              dispatch(
                changeVesselDocumentDrawerStatus({
                  drawerOpen: true,
                  drawerType: 'renew',
                  drawerActiveItem: { ...data, type }
                })
              )
          },
          {
            label: 'Edit',
            icon: edit,
            onClick: _ =>
              dispatch(
                changeVesselDocumentDrawerStatus({
                  drawerOpen: true,
                  drawerType: 'edit',
                  drawerActiveItem: { ...data, type }
                })
              )
          }
        );
      }
    }

    return options;
  };

  return (
    <Row>
      <Col xs={12}>
        <ColoredTable
          columns={[
            {
              className: 'hidden-text',
              header: 'Name',
              key: 'category_name',
              type: 'string',
              width: 3
            },
            {
              header: 'issue',
              key: 'issue_date',
              type: 'date',
              minWidth: 165,
              maxWidth: 165
            },
            {
              header: 'inserted in',
              key: 'created_at',
              type: 'date'
            },
            {
              key: 'reminders',
              sort: false,
              filter: false,
              className: 'p-0',
              minWidth: 24,
              maxWidth: 24
            },
            {
              header: 'expiration',
              key: 'expiration_date',
              type: 'date',
              width: isSurveyTemplate ? 1 : 2
            },
            {
              header: 'Range From',
              key: 'range_from',
              type: 'date',
              hidden: !isSurveyTemplate,
              width: 1
            },
            {
              header: 'Range To',
              key: 'range_to',
              type: 'date',
              hidden: !isSurveyTemplate,
              width: 1
            },
            {
              header: 'Postponed',
              key: 'postponed',
              type: 'date',
              hidden: !isSurveyTemplate,
              width: 1
            },
            {
              header: 'Serial Number',
              key: 'serial_number',
              type: 'string',
              hidden: !isItemCertificateTemplate,
              width: 1
            },
            {
              key: 'attachments_count',
              field: 'attachments'
            },
            {
              header: 'Actions',
              key: 'actions',
              field: 'actions',
              className: 'text-center'
            }
          ]}
          hideTableSearch
          collapsableRows={(data, isOpen) =>
            data.has_history ? (
              <DocumentHistory
                data={data}
                isOpen={isOpen}
                template={type?.template}
                typeId={type.id}
                isDownloading={isDownloading}
                downloadAttachments={downloadAttachments}
                requestAttachments={requestAttachments}
              />
            ) : null
          }
          color="lighter-gray"
          rows={{
            category_name: data => (
              <div className="d-flex align-items-center flex-nowrap w-100p overflow-hidden">
                <div className="flex-1 overflow-hidden pe-1">
                  <TextWithTooltip className="me-2">{data.category_name}</TextWithTooltip>
                </div>
                <div>
                  <Departments values={[data?.responsible_department]} />
                </div>
              </div>
            ),
            issue_date: data =>
              data.active ? (
                <IssueDate
                  date={data.issue_date}
                  comments={data.comments}
                  typeId={type.id}
                  certificateId={data.id}
                />
              ) : null,
            expiration_date: data => <ExpirationDate date={data.expiration_date} />,
            created_at: data =>
              data.active ? (
                <CreatedAtDate createdAt={data.created_at} issuedBy={data.issued_by} />
              ) : null,
            attachments_count: data =>
              data.active && (canEdit || (!canEdit && data.attachments_count > 0)) ? (
                {
                  isDisabled: isDownloading,
                  hasAttachments: data.attachments_count > 0,
                  downloadFileName: data.category_name,
                  data,
                  downloadFiles: () => downloadAttachments(data),
                  attachFiles: () =>
                    dispatch(
                      changeVesselDocumentDrawerStatus({
                        drawerOpen: true,
                        drawerType: 'edit',
                        drawerActiveItem: { ...data, type }
                      })
                    )
                }
              ) : !data.active ? (
                <SvgRender
                  className="text-violet"
                  src={deactivate}
                  style={{ width: 16, height: 16, marginLeft: 6 }}
                />
              ) : (
                <div></div>
              ),
            actions: data => ({ options: getDocumentActions(data) }),
            reminders: data => <DocumentRemindersTooltip reminders={data?.reminders} />
          }}
          state={state}
        />
      </Col>
    </Row>
  );
};

export default CategoriesTable;
