import React from 'react';

import Header from './Header';
import Body from './Body';
import JobDrawer from 'common/components/pms/jobs/drawer';

const PmsJobsPerSystem = () => {
  return (
    <div className="pms-jobs-view system">
      <Header />
      <Body />
      <JobDrawer />
    </div>
  );
};

export default PmsJobsPerSystem;
