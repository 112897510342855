import SaveTableLayout from 'components/layouts/page/table/save-table';
import { Row, Col } from 'reactstrap';
import NavTabs from 'common/components/pms/jobs/components/NavTabs';
import TableHeaderName from 'common/components/pms/jobs/components/TableHeaderName';
import { useSelector } from 'react-redux';
import * as pmsJobsActions from 'common/components/pms/jobs/store/actions';
import { useActions } from 'utils/hooks';
import permissions from 'common/utils/permissions/constants';
import AnnualReportAction from '../../components/AnnualReportAction';

const Header = () => {
  const [toggleJobDrawer] = useActions([pmsJobsActions.toggleJobDrawer]);
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <Row className="position-relative d-flex ">
      <Col xs="auto">
        <NavTabs listView />
      </Col>

      <div className="small-separator mx-3 cpy-10 cmt-6 bg-platinum" />

      <Col>
        <SaveTableLayout
          label={'pms_jobs_list'}
          pageTitle={
            <div className="d-flex align-items-center">
              <div>
                <TableHeaderName /> <strong>/ List</strong>
              </div>
            </div>
          }
          pageActions={
            !isOnBoard
              ? [
                  { type: 'favorite' },
                  { customElement: () => <AnnualReportAction isList /> },
                  {
                    type: 'create',
                    color: 'primary',
                    text: 'Add Job',
                    onClick: () => toggleJobDrawer(true, null),
                    permissions: [permissions.office.jobs.create]
                  }
                ]
              : null
          }
        />
      </Col>
    </Row>
  );
};

export default Header;
