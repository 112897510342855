import { useCallback, useState } from 'react';
import { Row, Col } from 'reactstrap';
import EmptyFilterPage from 'common/components/mga/components/EmptyFilterPage';
import ActionTableButtons from 'common/components/mga/components/actions/ActionTableButtons';
import MgaAccountsSelector from 'common/components/selectors/MgaAccountsSelector';
import ModularTable, { useModularTable } from 'common/components/ModularTable';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import CrewClosingBalanceWarnings from 'common/components/mga/components/CrewClosingBalanceWarnings';

import { constructColumns } from 'common/components/table/utils/modular-table/helpers';
import {
  selectRefetchTableTrigger,
  selectLatestPeriod
} from 'common/components/mga/store/selectors';

import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import BaseToCurrentCurrency from '@/common/components/mga/components/actions/setup-forex-rate-form/components/BaseToCurrentCurrency.tsx';
import { get } from '@/api';
import {
  getMgaAccountLedger,
  getMgaAccountBalances,
  getMgaAccountsSummary,
  createMgaAccountDigitalSignature,
  deleteMgaAccountDigitalSignature,
  getMgaAccountDigitalSignatures,
  setWarningCrewClosingBalance,
  getMgaAttachments,
  downloadMgaActionAttachments
} from 'common/components/mga/store/actions';
import { getTableList, setTableTotals } from 'store/tables/lists/actions';
import { selectCurrenciesWithoutBase } from '@/common/components/mga/store/selectors-ts';

import _get from 'lodash/get';
import accounting from 'common/assets/svg/accounting/calculate.svg';
import { getInitialAsyncValues } from 'utils/helpers';
import { numberToStr } from 'common/utils/numbers';
import ExportIcon from 'common/components/general/ExportIcon';
import { download } from 'utils/api';
import { handleFileDownload } from 'common/utils/downloads';
import DigitalSignature from 'common/components/digital-signature';
import { useDrawer } from 'common/components/drawer';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import moment from 'moment';
import { saveDownloadedFile } from 'common/utils/downloads';
import { useDispatch } from 'react-redux';
import CellWarningTooltip from 'common/components/table/main/CellWarningTooltip';
import { RESUBMITTED_MESSAGE } from '../overview/helpers';
import { useAppSelector } from '@/store/hooks';

const CellValue = ({ children, hasResubmittedActions }) => {
  return hasResubmittedActions ? (
    <CellWarningTooltip warning={RESUBMITTED_MESSAGE} color={'yellow'} textColor={'table-color'}>
      {children}
    </CellWarningTooltip>
  ) : (
    children
  );
};

const Accounts = () => {
  const [account, setAccount] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [forexRates, setForexRates] = useState([]);

  const signatureDrawer = useDrawer(false);
  const dispatch = useDispatch();

  const currencies = useAppSelector(selectCurrenciesWithoutBase);
  const shouldRefetchTable = useAppSelector(selectRefetchTableTrigger);
  const latestPeriod = useAppSelector(selectLatestPeriod);
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(
    async params => {
      const filters = (_get(params, 'filters', []) || []).filter(f => f.name !== 'period_id');
      const accountFilter = filters.find(f => f.name === 'account_id' && f.value);

      if (!accountFilter) {
        return;
      }
      const parsedParams = {
        ids: [accountFilter?.value],
        includes: ['leaf_tag', 'forex_rates'],
        period_id: latestPeriod?.id
      };

      const resMgaAccount = await get('/mga/accounts', parsedParams);
      const selectedMgaAccount = _get(resMgaAccount, 'data');
      const selectedMgaAccountData = _get(selectedMgaAccount, 'data');
      const selectedMgaAccountForexRates = _get(selectedMgaAccount, 'forex_rates');

      setForexRates(selectedMgaAccountForexRates || []);

      const isLeaf = _get(selectedMgaAccountData, '0.is_leaf', true);
      setAccount(_get(selectedMgaAccountData, '0', null));

      filters.push({ name: 'period_id', operation: '=', value: latestPeriod.id });

      let response = null;

      if (isLeaf) {
        response = await dispatch(
          getMgaAccountLedger({
            ...params,
            filters,
            includes: ['totals']
          })
        );
      } else {
        response = await dispatch(
          getMgaAccountsSummary({
            ...params,
            id: accountFilter?.value,
            filters,
            includes: ['totals']
          })
        );
      }

      const accountBalances = await dispatch(
        getMgaAccountBalances({
          id: accountFilter?.value,
          period_id: latestPeriod.id
        })
      );

      dispatch(
        setTableTotals({
          table: 'mga_accounts',
          totals: {
            opening_debit: accountBalances?.opening_debit,
            opening_credit: accountBalances?.opening_credit,
            closing_credit: accountBalances?.closing_credit,
            closing_debit: accountBalances?.closing_debit
          }
        })
      );

      const tableData = await dispatch(
        getTableList({
          table: 'mga_overview',
          includes: ['forex_rates'],
          period_id: latestPeriod.id
        })
      ); // Fetch mga_overview to generate the Crew closing balance warnings
      dispatch(
        setWarningCrewClosingBalance({ data: tableData?.data, period_id: latestPeriod.period_id })
      );

      setForexRates(tableData?.forex_rates || []);

      return response;
    },
    [dispatch, latestPeriod.id, latestPeriod.period_id]
  );

  const onDownload = async () => {
    if (!account?.crew_member_id) return;

    setIsDownloading(true);

    await handleFileDownload(
      { url: `/mga/accounts/${selectedAccount?.value}/pdf` },
      download,
      false,
      {
        requestParams: { filters: [{ name: 'period_id', operation: '=', value: latestPeriod.id }] },
        parsed: true
      }
    );

    setIsDownloading(false);
  };

  const table = useModularTable({
    defaultRequestParams: { visible: false, paging: false, sorting: false, filters: true },
    requestTableListFrom: fetchData,
    label: 'mga_accounts',
    config: columns => ({
      columns: constructColumns(columns, [], 'mga_accounts')
    }),
    top: {
      filters: [
        {
          name: 'account_id',
          operation: '=',
          value: null
        }
      ]
    }
  });

  useUpdateEffect(() => {
    if (shouldRefetchTable) {
      table.refetchData();
    }
  }, [shouldRefetchTable]);

  useUpdateEffect(() => {
    table.refetchData();
  }, [latestPeriod?.id]);

  const selectedAccount = _get(table, 'topFilters', []).find(f => f.name === 'account_id');

  const selectOtherAccount = (action, account_id) => {
    const creditAccountId = _get(action, 'credit_account.id');

    if (creditAccountId == account_id) {
      return `${_get(action, 'debit_account.code')}. ${_get(action, 'debit_account.name')}`;
    } else {
      return `${_get(action, 'credit_account.code')}. ${_get(action, 'credit_account.name')}`;
    }
  };

  const downloadHandler = async (id, downloadFileName) => {
    try {
      setIsLoading(true);
      const response = await dispatch(downloadMgaActionAttachments({ id, type: 'mga_action' }));
      saveDownloadedFile(
        response,
        `${downloadFileName ? `${downloadFileName} - ${moment().format('DD-MM-YYYY')}` : ''}`
      );
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const requestAttachments = async data => {
    try {
      setIsLoading(true);
      const res = await dispatch(getMgaAttachments({ action_id: data.action?.id }));
      setIsLoading(false);
      return res;
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Row className="align-items-center">
        <Col xs={5} className={`mb-1 ${account?.crew_member_id ? 'border-end pe-2 me-2' : ''}`}>
          <MgaAccountsSelector
            key={selectedAccount?.value}
            filter={{ value: selectedAccount?.value ? [selectedAccount?.value] : [] }}
            onChange={({ value }) => {
              table.setTopFilters(
                table.topFilters.map(f =>
                  f.name === 'account_id' ? { ...f, value: value?.id || null } : f
                )
              );
              if (!value) setAccount(null);
            }}
            autoFocus={false}
            isMulti={false}
            isClearable={true}
            placeholder="Select account"
            listParams={{
              period_id: latestPeriod?.id,
              ...(isOnBoard ? { show_account_page_onboard: true } : {})
            }}
          />
        </Col>
        {account?.crew_member_id ? (
          <Col xs="auto" className="d-flex aling-items-center ps-0 mb-1">
            <DigitalSignature
              className="height-24"
              drawer={signatureDrawer}
              count={account.signatures_count}
              onCreate={async (party_id, signature) =>
                await dispatch(
                  createMgaAccountDigitalSignature({
                    account_id: account.id,
                    period_id: latestPeriod.id,
                    party_id,
                    signature
                  })
                )
              }
              onFetch={async () =>
                await dispatch(
                  getMgaAccountDigitalSignatures({
                    account_id: account.id,
                    period_id: latestPeriod.id
                  })
                )
              }
              onDelete={async signature_id =>
                await dispatch(
                  deleteMgaAccountDigitalSignature({
                    signature_id,
                    account_id: account.id,
                    period_id: latestPeriod.id
                  })
                )
              }
              onClose={async () => {
                if (!account?.id) return;

                const res = await getInitialAsyncValues('mga-accounts', null, null, {
                  ids: [account.id],
                  includes: ['leaf_tag'],
                  period_id: latestPeriod?.id
                });
                setAccount(res[0]);
              }}
            />
            <div className="small-separator mx-2 pt-1 pb-2" />
            <ExportIcon extension="pdf" disabled={isDownloading} onClick={onDownload} />
          </Col>
        ) : null}
      </Row>

      {selectedAccount && selectedAccount?.value ? (
        <Row>
          {account?.crew_member_id ? (
            <Col xs={12}>
              <CrewClosingBalanceWarnings crewMemberId={account?.crew_member_id} />
            </Col>
          ) : null}

          <Col xs={12}>
            <ModularTable
              loader
              hideTableSearch
              hideTopPagination
              hideTableFilters
              solidTotalBarKeys={['footer_total_key']}
              rows={
                account?.is_leaf
                  ? {
                      other_account: data => {
                        const action = _get(data, 'action');
                        const accound_id = _get(data, 'account.id');

                        return (
                          <TextWithTooltip>
                            {selectOtherAccount(action, accound_id)}
                          </TextWithTooltip>
                        );
                      },
                      'action.description': data => (
                        <div className="d-flex align-items-center w-100p pe-1">
                          <div className="flex-1 overflow-hidden">
                            <TextWithTooltip>{data?.action?.description || '-'}</TextWithTooltip>
                          </div>

                          <DownloadAttachment
                            isDisabled={isLoading}
                            data={{ attachments_count: data?.action?.attachments_count }}
                            downloadFiles={() =>
                              downloadHandler(data?.action?.id, data?.action?.action_type?.name)
                            }
                            requestAttachments={() => requestAttachments(data)}
                            hasAttachments={true}
                            canAddAttachments={false}
                          />
                        </div>
                      ),
                      ...currencies.reduce(
                        (acc, curr) => ({
                          ...acc,
                          [`debit_${curr?.id}`]: data => {
                            const shouldShowValue = data?.currency_id === curr?.id;
                            if (!shouldShowValue) return null;

                            return (
                              <CellValue
                                hasResubmittedActions={data?.action?.is_resubmitted && data?.debit}
                              >
                                {numberToStr(data?.local_debit, 2, 2)}
                              </CellValue>
                            );
                          },
                          [`credit_${curr?.id}`]: data => {
                            const shouldShowValue = data?.currency_id === curr?.id;
                            if (!shouldShowValue) return null;

                            return (
                              <CellValue
                                hasResubmittedActions={data?.action?.is_resubmitted && data?.debit}
                              >
                                {numberToStr(data?.local_credit, 2, 2)}
                              </CellValue>
                            );
                          }
                        }),
                        {}
                      ),
                      debit: data => (
                        <CellValue
                          hasResubmittedActions={data?.action?.is_resubmitted && data?.debit}
                        >
                          {numberToStr(data?.debit, 2, 2)}
                        </CellValue>
                      ),
                      credit: data => (
                        <CellValue
                          hasResubmittedActions={data?.action?.is_resubmitted && data?.credit}
                        >
                          {numberToStr(data?.credit, 2, 2)}
                        </CellValue>
                      ),
                      actions: data => <ActionTableButtons action={data.action} />
                    }
                  : {
                      name: data => `${_get(data, 'code', '')} ${_get(data, 'name', '-')}`,
                      debit: data => (
                        <CellValue
                          hasResubmittedActions={
                            data.has_resubmitted_debit_actions && _get(data, 'ledger.total_debit')
                          }
                        >
                          {numberToStr(_get(data, 'ledger.total_debit', '-'), 2, 2)}
                        </CellValue>
                      ),
                      ...currencies.reduce(
                        (acc, curr) => ({
                          ...acc,
                          [`debit_${curr?.id}`]: data => {
                            const shouldShowValue = data?.currency_id === curr?.id;
                            if (!shouldShowValue) return null;

                            return (
                              <CellValue
                                hasResubmittedActions={
                                  data.has_resubmitted_debit_actions &&
                                  _get(data, `ledger.total_debit_${curr?.id}`)
                                }
                              >
                                {numberToStr(
                                  _get(data, `ledger.total_debit_${curr?.id}`, '-'),
                                  2,
                                  2
                                )}
                              </CellValue>
                            );
                          },
                          [`credit_${curr?.id}`]: data => {
                            const shouldShowValue = data?.currency_id === curr?.id;
                            if (!shouldShowValue) return null;

                            return (
                              <CellValue
                                hasResubmittedActions={
                                  data.has_resubmitted_credit_actions &&
                                  _get(data, `ledger.total_credit_${curr?.id}`)
                                }
                              >
                                {numberToStr(
                                  _get(data, `ledger.total_credit_${curr?.id}`, '-'),
                                  2,
                                  2
                                )}
                              </CellValue>
                            );
                          }
                        }),
                        {}
                      ),
                      credit: data => (
                        <CellValue
                          hasResubmittedActions={
                            data.has_resubmitted_credit_actions && _get(data, 'ledger.total_credit')
                          }
                        >
                          {numberToStr(_get(data, 'ledger.total_credit', '-'), 2, 2)}
                        </CellValue>
                      )
                    }
              }
              {...table}
            />
          </Col>
        </Row>
      ) : (
        <EmptyFilterPage text={'You have not selected any Account yet.'} icon={accounting} />
      )}

      <div className="d-flex flex-column flex-1 align-items-end cmt-12">
        <div>
          {forexRates.map(forex => (
            <BaseToCurrentCurrency
              key={forex?.id}
              baseCurrencyLabel={forex?.base_currency?.label}
              currentCurrencyLabel={forex?.currency?.label}
              rate={forex?.rate}
              currenciesClassName="fw-medium"
              period={{ from: forex?.from_date, to: forex?.to_date }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Accounts;
