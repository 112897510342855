import React, { useMemo } from 'react';

import menuIcon from 'common/assets/svg/common/burger.svg';
import add from 'common/assets/svg/actions/add-action.svg';
import accounting from 'common/assets/svg/accounting/calculate.svg';
import inventory from 'common/assets/svg/accounting/inventory.svg';
import reports from 'common/assets/svg/common/reports.svg';

import NavigationGroupTabs from 'common/components/buttons/NavigationGroupTabs';

import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import {
  selectVesselFilter,
  selectPeriodFilter,
  selectIsPastMga
} from 'common/components/mga/store/selectors';

const NavTabs = () => {
  const vesselFilter = useSelector(selectVesselFilter);
  const periodFilter = useSelector(selectPeriodFilter);
  const isPastMga = useSelector(selectIsPastMga);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const tabs = useMemo(() => {
    const pathname =
      (isOnBoard && isPastMga && vesselFilter) || (!isOnBoard && vesselFilter)
        ? `/${vesselFilter}${periodFilter ? `/${periodFilter}` : ''}`
        : '';

    return [
      {
        to: `${paths.mga}/${isPastMga ? 'past/' : ''}overview${pathname}`,
        icon: menuIcon,
        tooltip: 'Overview',
        label: 'mga-overview',
        isActive: (_, location) =>
          location.pathname.startsWith(`${paths.mga}${isPastMga ? '/past' : ''}/overview`)
      },
      {
        to: `${paths.mga}/${isPastMga ? 'past/' : ''}accounts${pathname}`,
        icon: accounting,
        tooltip: 'Accounts',
        label: 'mga-accounts',
        isActive: (_, location) =>
          location.pathname.startsWith(`${paths.mga}${isPastMga ? '/past' : ''}/accounts`)
      },
      {
        to: `${paths.mga}/${isPastMga ? 'past/' : ''}actions${pathname}`,
        icon: add,
        tooltip: 'Actions',
        label: 'mga-actions',
        isActive: (_, location) =>
          location.pathname.startsWith(`${paths.mga}${isPastMga ? '/past' : ''}/actions`)
      },
      {
        to: `${paths.mga}/${isPastMga ? 'past/' : ''}inventory${pathname}`,
        icon: inventory,
        tooltip: 'Inventory',
        label: 'mga-inventory',
        isActive: (_, location) =>
          location.pathname.startsWith(`${paths.mga}${isPastMga ? '/past' : ''}/inventory`)
      },
      {
        to: `${paths.mga}/${isPastMga ? 'past/' : ''}reports${pathname}`,
        icon: reports,
        tooltip: 'Reports',
        label: 'mga-reports',
        isActive: (_, location) =>
          location.pathname.startsWith(`${paths.mga}${isPastMga ? '/past' : ''}/reports`)
      }
    ];
  }, [vesselFilter, periodFilter]);

  return <NavigationGroupTabs tabs={tabs} />;
};

export default NavTabs;
