import { useEffect } from 'react';
import { useActions } from 'utils/hooks';
import { selectIsTreeInitialized } from 'common/components/filesystem/store/selectors';
import Sidebar from 'common/components/filesystem/components/Sidebar';
import Content from 'common/components/filesystem/components/content';

import * as filesystemActions from 'common/components/filesystem/store/actions';
import { useSelector } from 'react-redux';

const FileSystemLayout = () => {
  const [getFolders] = useActions([filesystemActions.getFolders]);
  const initialized = useSelector(selectIsTreeInitialized);

  useEffect(() => {
    if (!initialized) getFolders();
  }, [initialized]);

  return (
    <div className="filesystem-layout">
      <div className="filesystem-layout__inner">
        <Sidebar />
        <div className="filesystem-layout__main">{initialized && <Content />}</div>
      </div>
    </div>
  );
};

export default FileSystemLayout;
