import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getPartyDisplayName } from 'common/utils/labels';
import { getAuthorizedPermissionSettings } from 'utils/permissions/authorize';
import permissions from '@/common/utils/permissions/constants';

const getMainInfo = account => {
  const permissionManningAgentIds = getAuthorizedPermissionSettings(
    account,
    permissions.office.crew.seamen_list.view
  )?.manning_agents;

  return [
    {
      fields: [
        {
          label: 'FIRST NAME',
          key: 'first_name',
          type: 'string',
          inputProps: {
            placeholder: 'Add first name',
            required: true
          }
        },
        {
          label: 'MIDDLE NAME',
          key: 'middle_name',
          type: 'string',
          inputProps: {
            placeholder: 'Add middle name'
          }
        },
        {
          label: 'LAST NAME',
          key: 'last_name',
          type: 'string',
          inputProps: {
            placeholder: 'Add last name'
          }
        },
        {
          label: 'FATHER NAME',
          key: 'father_name',
          type: 'string',
          inputProps: {
            placeholder: 'Add father name',
            required: true
          }
        },
        {
          label: 'MOTHER NAME',
          key: 'mother_name',
          type: 'string',
          inputProps: {
            placeholder: 'Add mother name'
          }
        },
        {
          label: 'RANK',
          key: 'rank.name',
          select_key: 'rank',
          type: 'select',
          inputProps: {
            placeholder: 'Add rank',
            isAsync: true,
            getOptionValue: option => option.id,
            getOptionLabel: option => option.name,
            defaultOptions: () => getInitialAsyncValues('crew-ranks'),
            loadOptions: search => getAsyncOptions(search, 'crew-ranks'),
            isClearable: true
          }
        },
        {
          label: 'NATIONALITY',
          key: 'nationality.name',
          select_key: 'nationality',
          type: 'select',
          inputProps: {
            placeholder: 'Add nationality',
            isAsync: true,
            getOptionValue: option => option.id,
            getOptionLabel: option => option.name,
            defaultOptions: () => getInitialAsyncValues('nationalities'),
            loadOptions: search => getAsyncOptions(search, 'nationalities'),
            isClearable: true
          }
        },
        {
          label: 'BIRTH DATE',
          key: 'birthday',
          type: 'date',
          inputProps: {
            placeholder: 'Set date'
          }
        },
        {
          label: 'GENDER',
          key: 'gender.name',
          type: 'listSelect',
          select_key: 'gender_id',
          inputProps: {
            placeholder: 'Add gender',
            list: 'genders',
            isAsync: false
          }
        },
        {
          label: 'PLACE OF BIRTH',
          key: 'place',
          type: 'string',
          inputProps: {
            placeholder: 'Add place of birth'
          }
        },

        {
          label: 'MANNING AGENT',
          labelExtraInfo: `This dropdown displays all companies associated with contacts labeled as 'Manning Agent'.`,
          key: 'agent.full_name',
          select_key: 'agent',
          type: 'select',
          inputProps: {
            placeholder: 'Select agent',
            isAsync: true,
            getOptionValue: option => option.id,
            getOptionLabel: option => getPartyDisplayName(option),
            defaultOptions: () =>
              getInitialAsyncValues('parties', null, null, {
                type: 'company',
                role: 'manning_agent',
                id: permissionManningAgentIds
              }),
            loadOptions: search =>
              getAsyncOptions(search, 'parties', {
                type: 'company',
                role: 'manning_agent',
                id: permissionManningAgentIds
              }),
            isClearable: true
          }
        },
        {
          className: 'mt-3',
          label: 'HEIGHT (cm)',
          key: 'height',
          type: 'number',
          inputProps: {
            placeholder: 'Value'
          }
        },
        {
          label: 'WEIGHT (kg)',
          key: 'weight',
          type: 'number',
          inputProps: {
            placeholder: 'Value'
          }
        },
        {
          label: 'SHOE SIZE',
          key: 'shoes_size',
          type: 'number',
          inputProps: {
            placeholder: 'Value'
          }
        },
        {
          label: 'UNIFORM SIZE',
          key: 'uniform_size',
          type: 'string',
          inputProps: {
            placeholder: 'Value'
          }
        },
        {
          label: 'BLOOD TYPE',
          key: 'blood_type.name',
          type: 'listSelect',
          select_key: 'blood_type_id',
          inputProps: {
            placeholder: 'Add blood type',
            list: 'blood-types',
            isAsync: false
          }
        },
        {
          label: 'ALLERGIES',
          key: 'allergies',
          type: 'string',
          inputProps: {
            placeholder: 'Add allergies'
          }
        },
        {
          label: 'TATTOOS & MARKS',
          key: 'tattoos_and_marks',
          type: 'string',
          inputProps: {
            placeholder: 'Add tattoos & marks'
          }
        },
        {
          label: '',
          hideLabel: true,
          key: 'tattoos_and_marks_attachments',
          type: 'file',
          inputProps: {
            className: 'square-upload ',
            group: 'crew.files',
            showFileName: false
          }
        },
        {
          label: 'TAX AUTHORITY',
          key: 'tax_authority',
          type: 'string',
          inputProps: {
            placeholder: 'Add tax authority'
          }
        },
        {
          label: 'TAX ID #',
          key: 'tax_id',
          type: 'string',
          inputProps: {
            placeholder: 'Add tax ID'
          }
        },
        {
          label: 'TAXATION COUNTRY',
          key: 'taxation_country.name',
          type: 'select',
          select_key: 'taxation_country',
          inputProps: {
            placeholder: 'Select country',
            isAsync: true,
            getOptionValue: option => option.id,
            getOptionLabel: option => option.name,
            defaultOptions: () => getInitialAsyncValues('countries'),
            loadOptions: search => getAsyncOptions(search, 'countries'),
            isClearable: true,
            menuPlacement: 'top'
          }
        }
      ]
    }
  ];
};

export default getMainInfo;
