import React, { useState } from 'react';

import Select from 'common/components/form/inputs/Select';

import useFilterSelector from 'common/components/filters/useFilterSelector';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getFilterSelectorValues } from 'common/components/filters/helpers';
import PropTypes from 'prop-types';

const SparePartsSelector = ({ filter, onChange, isMulti, label, placeholder, ...rest }) => {
  const [selectedParts, setSelectedParts] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'spare-parts' },
    { selected: selectedParts, setSelected: setSelectedParts },
    'label'
  );

  return (
    <Select
      label={label}
      placeholder={placeholder ? placeholder : 'Select value'}
      getOptionValue={option => option.id}
      getOptionLabel={option => `${option.part_no}. ${option.description}`}
      autoFocus
      value={filter?.value ? getFilterSelectorValues(selectedParts, filter?.value) : []}
      isMulti={isMulti}
      defaultOptions={() => getInitialAsyncValues('spare-parts')}
      isAsync
      loadOptions={search => getAsyncOptions(search, 'spare-parts')}
      onChange={selected => {
        setSelectedParts(selected);
        onChange({
          value: selected ? (isMulti ? [...selected.map(s => s.id)] : selected) : ''
        });
      }}
      isClearable
      {...rest}
    />
  );
};

SparePartsSelector.propTypes = {
  filter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string
};

export default SparePartsSelector;
