import { useEffect } from 'react';
import useRouter from 'use-react-router';
import PageLoader from 'common/components/general/PageLoader';
import PropTypes from 'prop-types';

import Table, { useTable } from 'common/components/table';
import { parseQueryParams } from 'utils/urls';
import { useSelector } from 'react-redux';
import TopFilters from 'common/components/pms/jobs/components/TableTopFilters';
import TableFilters from 'common/components/pms/jobs/components/TableFilters';
import { getTableList } from 'store/tables/lists/actions';
import {
  updateJob,
  setTableShouldRefetchData,
  deleteJobAction
} from 'common/components/jobs/_base/store/actions';

import { periodicityEnums } from 'common/utils/fixed';

import { toggleJobDrawer } from 'common/components/pms/jobs/store/actions';

import _isArray from 'lodash/isArray';

import useTableTopFilter from 'common/components/filters/useTableTopFilter';
import { selectTableListPaging } from 'common/components/table/store/selectors';
import { selectJobDrawerIsOpen } from 'common/components/pms/jobs/store/selectors';

import { selectTableShouldRefetchData } from 'common/components/jobs/_base/store/selectors';

import TextWithTooltip from 'common/components/general/TextWithTooltip';

import JobLink from 'common/components/jobs/maintenance/components/JobLink';
import JobStatus from 'common/components/pms/jobs/components/JobStatus';
import DueDate from 'common/components/jobs/_base/components/DueDate';
import DueOnRh from 'common/components/pms/jobs/components/DueOnRh';
import Priority from 'common/components/general/Priority';
import VesselSystemInfo from 'common/components/pms/jobs/components/VesselSystemInfo';
import moment from 'moment';
import paths from 'routing/routes/_paths';
import { selectJobStatuses } from 'store/jobs-statuses/selectors';
import listColumns from 'common/components/pms/jobs/views/list/_tableColumns';
import Resubmitted from 'common/components/forms/_components/Resubmitted';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import { downloadAllFilesOfEntity } from 'common/utils/downloads';
import binIcon from 'common/assets/svg/actions/delete.svg';
import { useAppDispatch } from '@/store/hooks';
import { jobEnums } from '@/common/utils/fixed';

const Body = ({ components = {} }) => {
  const { history, match } = useRouter();
  const urlJobId = parseQueryParams(history.location.search)?.jobID || match.params.jobID;
  const createMode = history.location.pathname.includes('/create');
  const dispatch = useAppDispatch();

  const tableShouldRefetchData = useSelector(selectTableShouldRefetchData);
  const jobDrawerIsOpen = useSelector(selectJobDrawerIsOpen);

  const doneJobsFilterEnabled = useTableTopFilter({ name: 'is_completed' });

  const tablePaging = useSelector(state => selectTableListPaging(state, 'pms_jobs_list'));
  const jobStatuses = useSelector(selectJobStatuses);

  const fetchData = async (params, extraRequestParams = {}) => {
    const { sorting, filters } = params;
    const { assignment_vessel_system_id, status_id } = sorting || {};
    const formattedParams = {
      ...params,
      ...(sorting || {}),
      ...extraRequestParams
    };

    if (assignment_vessel_system_id) {
      formattedParams.sorting.assignment_description = assignment_vessel_system_id;
      formattedParams.sorting.assignment_vessel_id = assignment_vessel_system_id;
    }

    if (status_id) {
      formattedParams.sorting.status_id = status_id;
      formattedParams.sorting.status_completion = status_id;
    }

    formattedParams.filters = filters?.filter(f => {
      return (
        f.name !== 'due_date' ||
        (f.name === 'due_date' &&
          (f.operation !== 'between' ||
            (f.operation === 'between' && _isArray(f.value) && f.value.length === 2)))
      );
    });

    try {
      await dispatch(getTableList(formattedParams));
    } catch (error) {
      console.error(error);
    }

    return;
  };

  const table = useTable({
    label: 'pms_jobs_list',
    defaultRequestParams: { visible: false, paging: true, sorting: true, filters: true },
    requestTableListFrom: fetchData,
    columns: listColumns.map(c =>
      c.key === 'last_done_at'
        ? {
            ...c,
            hidden: doneJobsFilterEnabled ? true : false
          }
        : c.key === 'carried_out_at'
        ? {
            ...c,
            hidden: doneJobsFilterEnabled ? false : true,
            canFilter: doneJobsFilterEnabled ? true : false
          }
        : c.key === 'last_done_rh'
        ? {
            ...c,
            header: doneJobsFilterEnabled ? 'R/H When Done' : 'Last Done R/H',
            sort: doneJobsFilterEnabled ? true : false
          }
        : c
    ),
    top: {
      filters: [
        {
          name: 'vessel_id',
          operation: 'oneOf',
          value: null
        },
        {
          name: 'is_completed',
          operation: '=',
          value: null
        },
        {
          name: 'due_date',
          operation: '=',
          value: null
        },
        {
          name: 'is_overdue',
          operation: '=',
          value: null
        },
        {
          name: 'is_postponed',
          operation: '=',
          value: null
        },
        {
          name: 'remaining_rh_until_due',
          operation: '=',
          value: null
        },
        {
          name: 'becoming_due',
          operation: '=',
          value: null
        }
      ]
    }
  });

  const updateStatus = async (id, params, previousStatus) => {
    // Do not refetch table data when updating a job's status
    await dispatch(
      updateJob({ id: id, ...params }, false, false, {
        field: 'status_id',
        value: previousStatus.id
      })
    );
  };

  const onDelete = async id => {
    if (!id) return null;
    try {
      await dispatch(deleteJobAction({ id, table: 'pms_jobs_list' }));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (urlJobId) {
      dispatch(toggleJobDrawer(true, urlJobId));
    } else if (createMode) {
      dispatch(toggleJobDrawer(true, null));
    }
  }, [urlJobId, createMode, dispatch]);

  useEffect(() => {
    if (!jobDrawerIsOpen && tableShouldRefetchData) {
      table.refetchData();
      dispatch(setTableShouldRefetchData(false));
    }
  }, [tableShouldRefetchData, jobDrawerIsOpen, table, dispatch]);

  return (
    <>
      <TopFilters listView={true} doneJobsFilterEnabled={doneJobsFilterEnabled} table={table} />

      <TableFilters
        setFilters={table.setFilters}
        tableLabel={table.label}
        topFilters={table.topFilters}
        columns={table.columns}
        listView
      />

      {components?.BulkActionsButton ? (
        <components.BulkActionsButton totals={tablePaging?.total} refetchData={table.refetchData} />
      ) : null}

      <Table
        loader
        hideTableFilters
        hideTopPagination
        hideTableSearch
        rows={{
          assignment_vessel_system_id: data => (
            <VesselSystemInfo
              key={`vessel-system-${data.id}`}
              vesselSystem={{
                description: data.assignment_description,
                extra_description: data.assignment_extra_description,
                parent_id: data.assignment_parent_id
              }}
              attributes={{
                is_class: data?.assignment_is_class,
                is_critical: data?.is_critical,
                is_environmental_critical: data?.is_environmental_critical,
                is_navigational: data?.is_navigational
              }}
              cmsReferenceCode={data?.assignment_cms_reference_code}
              showGroup={false}
            />
          ),
          importance: data => <Priority value={data.importance_id} />,
          title: data => (
            <JobLink
              isPms={true}
              job={data}
              vesselName={data?.vessel_name}
              onClick={e => {
                e.preventDefault();

                history.replace({
                  pathname: `${paths.pms_jobs}/${data?.id}`,
                  search: history.location.search
                });
              }}
            />
          ),
          vessel_name: data => data?.vessel_name || '-',
          status_id: data => {
            const status = jobStatuses.find(st => st.id === data.status_id);
            return (
              <div className="d-flex align-items-center">
                <JobStatus
                  className="w-auto"
                  status={status}
                  onJobUpdate={params => updateStatus(data.id, params, status)}
                />

                <Resubmitted className={`cms-4`} isResubmitted={data?.resubmitted} />
              </div>
            );
          },
          job_type: data => (
            <div className="text-truncate w-100p">
              <TextWithTooltip>{data.maintenance_job_type_name}</TextWithTooltip>
            </div>
          ),
          periodicity: data => data?.periodicity_description,
          last_done_at: data => moment(data.last_done_at).format('DD/MM/YYYY') || '-',
          carried_out_at: data => moment(data?.carried_out_at).format('DD/MM/YYYY') || '-',
          due_date: data => {
            return data?.periodicity?.type === periodicityEnums.as_needed ? null : (
              <DueDate
                className="fs-12"
                dueDate={data.due_date}
                dueDateChanged={data.due_date_changed_at || data.due_on_rh_changed_at}
                showTime={data.due_date_show_time}
                createdAt={data.assignment_created_at}
                status={data.status_id}
                condition={{
                  is_becoming_due_date: data.is_becoming_due_date,
                  is_overdue_date: data.is_overdue_date
                }}
              />
            );
          },
          last_done_rh: data => {
            if (doneJobsFilterEnabled) {
              return data?.total_system_running_hours || '-';
            } else {
              return data?.last_done_rh || '-';
            }
          },
          due_on_rh: data => (
            <DueOnRh
              className="fs-12"
              dueOnRh={data?.due_on_rh}
              condition={{
                is_becoming_due_rh: data.is_becoming_due_rh,
                is_overdue_rh: data.is_overdue_rh
              }}
            />
          ),
          last_rh_report: data =>
            data?.last_date_of_running_hours_reading ? (
              <TextWithTooltip className="h-auto">
                {moment(data?.last_date_of_running_hours_reading).format('DD/MM/YYYY')}
                <span className="cms-6 cme-6">|</span>
                {data?.running_hours} rh
              </TextWithTooltip>
            ) : (
              <div>-</div>
            ),
          forms_completed: data => <NamesInTooltip names={data?.forms?.map(f => f?.form?.name)} />,
          attachments: data => (
            <div className="d-flex justify-content-start">
              <DownloadAttachment
                downloadFiles={() => downloadAllFilesOfEntity({ id: data.id, type: 'job' })}
                popupPlacement="left"
                data={{
                  attachments: data.attachments,
                  attachments_count: data.attachments?.length,
                  id: data?.id
                }}
                hasAttachments={data.attachments?.length > 0}
              />
            </div>
          ),
          maintenance_attachments: data => (
            <div className="d-flex justify-content-start overflow-y-hidden">
              <DownloadAttachment
                downloadFiles={() =>
                  downloadAllFilesOfEntity({ id: data.id, type: 'job_maintenance_details' })
                }
                popupPlacement="left"
                data={{
                  attachments: data.maintenance_attachments,
                  attachments_count: data.maintenance_attachments?.length,
                  id: data?.id
                }}
                hasAttachments={data.maintenance_attachments?.length > 0}
              />
            </div>
          ),
          actions: data => ({
            options:
              data.job_type === jobEnums.unscheduled
                ? [
                    {
                      label: 'Delete',
                      icon: binIcon,
                      preventionModal: true,
                      modalProps: {
                        onAccept: () => onDelete(data.id),
                        actionText: 'Delete',
                        action: 'Delete',
                        actionHoverColor: 'delete',
                        header: 'Delete Unplanned Maintenance Job',
                        body: (
                          <div className="text-center">
                            Are you sure you want to delete <strong>{`${data.title}`}</strong>? This
                            cannot be undone.
                          </div>
                        )
                      }
                    }
                  ]
                : []
          })
        }}
        {...table}
      />

      <PageLoader isLoading={table.fetching} />
    </>
  );
};

Body.propTypes = {
  components: PropTypes.shape({ BulkActionsButton: PropTypes.func })
};

export default Body;
