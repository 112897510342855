import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Row, Col, Label } from 'reactstrap';

import { useFormState, useUpdateEffect } from 'utils/hooks';
import _debounce from 'lodash/debounce';

import FormList from 'common/components/form/FormList';
import NumberInput from 'common/components/form/inputs/NumberInput';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { Option } from 'common/components/purchasing/requisition/categories/items/components/ItemSelector';
import { getMgaDepletionMethodInfo } from 'common/utils/depletion-method';
import { getLocalAmountConversion } from '@/common/components/mga/store/actions-ts';
import { selectBaseCurrencyLabel } from '@/common/components/mga/store/selectors-ts';
import {
  selectInventoryDepletionMethod,
  selectVesselFilter,
  selectLatestPeriod
} from 'common/components/mga/store/selectors';
import { numberToStr, strToNumber } from 'common/utils/numbers';
import Information from 'common/components/general/Information';

import infoIcon from 'common/assets/svg/common/info-line.svg';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import usePrevious from '@/ts-common/utils/hooks/usePrevious';

const Item = React.memo(
  ({
    formState,
    addedToInventory,
    subStates,
    isBaseCurrency,
    hasId,
    hasExtraCurrencies,
    isInEditMode,
    accountID,
    dateAction,
    actionTypeId,
    currencyId
  }) => {
    const { fields, changeField, selectField, setFieldError } = useFormState(formState);
    const inventoryDepletionMethodLabel = useAppSelector(selectInventoryDepletionMethod);
    const [remainingQuantityValidation, setRemainingQuantityValidation] = useState();
    const vessel = useAppSelector(selectVesselFilter);
    const baseCurrencyLabel = useAppSelector(selectBaseCurrencyLabel);
    const allSelectedStoreIds = subStates?.map(e => e.state?.store?.value?.id)?.filter(e => e);
    const latestPeriod = useAppSelector(selectLatestPeriod);
    const pricePerUnitKey = useMemo(
      () => (hasExtraCurrencies ? 'local_price_per_unit' : 'price_per_unit'),
      [hasExtraCurrencies]
    );
    const dispatch = useAppDispatch();

    const getAsyncDetails = () => {
      if (inventoryDepletionMethodLabel !== 'none' && !addedToInventory) {
        return {
          list: 'mga-items-available',
          params: {
            vessel_id: vessel || undefined,
            mga_account_id: accountID,
            currency_id: !hasExtraCurrencies ? undefined : currencyId,
            period_id: latestPeriod?.id
          },
          getOptionValue: option => option.id,
          getOptionLabel: option => {
            const code = option?.store ? option?.store?.code : option?.code;
            const description = option?.store ? option?.store?.description : option?.description;
            const quantity = option?.store ? option?.remaining_quantity : option?.quantity;
            const pricePerUnit = option?.[pricePerUnitKey];

            return `${code}. ${description} ${quantity ? `| ${quantity}` : ''} ${
              pricePerUnit !== null && pricePerUnit !== undefined
                ? `@ $${numberToStr(pricePerUnit, 2, 2)}`
                : ''
            }`;
          }
        };
      } else {
        return {
          list: 'stores',
          params: {
            mga_account_id: accountID,
            active: true,
            currency_id: !hasExtraCurrencies ? undefined : currencyId
          },
          getOptionValue: option => option.id,
          getOptionLabel: option => `${option.code}. ${option.description}`
        };
      }
    };

    const { list, params, getOptionLabel, getOptionValue } = getAsyncDetails();

    const getConversion = useCallback(
      async (local_amount, timestamp, currency_id) => {
        if (isBaseCurrency || !local_amount || !timestamp || !currency_id) return null;

        try {
          const value = await dispatch(
            getLocalAmountConversion({
              local_amount: strToNumber(local_amount),
              timestamp: timestamp.format('YYYY-MM-DD'),
              currency_id
            })
          ).unwrap();
          selectField('price_per_unit_converted')(strToNumber(value?.amount || value));
        } catch (err) {
          console.error(err);
        }
      },
      [dispatch, isBaseCurrency]
    );

    const debouncedGetConversion = useMemo(() => _debounce(getConversion, 400), [getConversion]);
    useEffect(() => {
      if (hasExtraCurrencies && addedToInventory) {
        debouncedGetConversion(fields?.[pricePerUnitKey]?.value, dateAction, currencyId);
      }
    }, [
      pricePerUnitKey,
      fields?.[pricePerUnitKey]?.value,
      inventoryDepletionMethodLabel,
      addedToInventory,
      debouncedGetConversion,
      dateAction,
      currencyId,
      hasExtraCurrencies
    ]);

    useUpdateEffect(() => {
      if (inventoryDepletionMethodLabel !== 'none' && !addedToInventory) {
        const autoFillPricePerUnitKey =
          hasExtraCurrencies && isBaseCurrency ? 'price_per_unit' : pricePerUnitKey;

        selectField(pricePerUnitKey)(fields.store.value?.[autoFillPricePerUnitKey] || '');
        if (hasExtraCurrencies && !isBaseCurrency) {
          selectField('price_per_unit_converted')(fields.store.value?.price_per_unit || '');
        }
        setRemainingQuantityValidation(fields.store.value?.remaining_quantity);
      }
    }, [
      inventoryDepletionMethodLabel,
      addedToInventory,
      hasExtraCurrencies,
      pricePerUnitKey,
      isBaseCurrency,
      fields.store.value
    ]);

    const onChangeItemQuantity = value => {
      changeField('quantity')(value);

      setTimeout(
        () =>
          setFieldError(
            'quantity',
            remainingQuantityValidation &&
              strToNumber(value?.target?.value) > remainingQuantityValidation
              ? `Available items ${remainingQuantityValidation}`
              : ''
          ),
        400
      );
    };

    const previousCurrencyId = usePrevious(currencyId);

    useUpdateEffect(() => {
      if (fields.store.value && previousCurrencyId !== currencyId) {
        selectField('store')(null);
      }
    }, [currencyId, fields.store.value, selectField]);

    return (
      <Row>
        <Col xs={12}>
          <AsyncSelector
            onChange={store => {
              selectField('store')(store);
              fields?.quantity?.value && selectField('quantity')('');
            }}
            type={list}
            label={
              addedToInventory
                ? 'ITEMS ADDED TO INVENTORY'
                : `ITEMS REMOVED FROM INVENTORY - PRICE PER UNIT (${baseCurrencyLabel})`
            }
            placeholder="Select item"
            getOptionValue={getOptionValue}
            isDisabled={hasId && isInEditMode}
            getOptionLabel={getOptionLabel}
            listParams={params}
            defaultOptionsTriggerChange={`${addedToInventory} ${actionTypeId} ${currencyId}`}
            components={{ Option }}
            filterOption={opt => !allSelectedStoreIds?.find(id => id === opt.value)}
            noOptionsMessage={({ inputValue }) =>
              !inputValue.length ? `Search for items` : `No items found`
            }
            {...fields.store}
          />
        </Col>
        <Col xs={hasExtraCurrencies && !isBaseCurrency ? 3 : 4}>
          {inventoryDepletionMethodLabel !== 'none' && !addedToInventory ? (
            <div className="d-flex flex-column">
              <Label className="form-label">
                PRICE PER UNIT {hasExtraCurrencies ? '' : baseCurrencyLabel}
              </Label>
              <div className="fs-12 fw-medium text-primary cmt-2">
                {numberToStr(fields?.[pricePerUnitKey].value, 2, 2)}
              </div>
            </div>
          ) : (
            <NumberInput
              className="mb-2"
              label={
                <div className="d-flex align-items-center">
                  <div className="form-label text-primary mb-0">Price Per Unit</div>
                  {hasExtraCurrencies ? null : (
                    <div className="texxt-violet cms-2 text-violet">{baseCurrencyLabel}</div>
                  )}
                </div>
              }
              name={pricePerUnitKey}
              onChange={changeField(pricePerUnitKey)}
              allowNegative={false}
              placeholder="Add value"
              fixedDecimalScale={2}
              {...fields?.[pricePerUnitKey]}
            />
          )}
        </Col>
        {hasExtraCurrencies && !isBaseCurrency ? (
          <Col xs={3}>
            <div className="d-flex align-items-center">
              <div className="form-label text-nowrap">PRICE PER UNIT</div>
              <div className="form-label text-violet cms-4">{baseCurrencyLabel}</div>
            </div>
            <div className="fs-12 cmt-2">
              {numberToStr(fields.price_per_unit_converted?.value, 2, 2)}
            </div>
          </Col>
        ) : null}
        <Col xs={hasExtraCurrencies && !isBaseCurrency ? 3 : 4}>
          <NumberInput
            className="mb-2"
            label="QUANTITY"
            name="quantity"
            onChange={onChangeItemQuantity}
            placeholder="Add value"
            disabled={hasId && isInEditMode}
            isAllowed={({ value, floatValue }) => value === '' || floatValue > 0}
            allowNegative={false}
            allowLeadingZeros={false}
            decimalScale={0}
            {...fields.quantity}
          />
        </Col>
        <Col xs={hasExtraCurrencies && !isBaseCurrency ? 3 : 4} className="mb-2">
          <div className="form-label">TOTAL ({baseCurrencyLabel})</div>
          <div className="fs-12 lh-1 mt-1 fw-medium text-primary">
            {((hasExtraCurrencies && !isBaseCurrency && fields.price_per_unit_converted?.value) ||
              (isBaseCurrency && fields.local_price_per_unit?.value) ||
              (!hasExtraCurrencies && fields?.price_per_unit.value)) &&
            fields.quantity.value
              ? numberToStr(
                  strToNumber(
                    hasExtraCurrencies
                      ? isBaseCurrency
                        ? fields.local_price_per_unit?.value
                        : fields.price_per_unit_converted?.value
                      : fields.price_per_unit?.value
                  ) * strToNumber(fields.quantity.value),
                  2,
                  2
                )
              : '-'}
          </div>
        </Col>
      </Row>
    );
  }
);

const InventoryItems = ({
  hasInventoryItems,
  isCredit,
  isDebit,
  isBaseCurrency,
  actionType,
  subStates,
  addSubform,
  removeSubform,
  isInEditMode,
  hasExtraCurrencies,
  dateAction,
  currencyId
}) => {
  const inventoryDepletionMethodLabel = useAppSelector(selectInventoryDepletionMethod);
  const depletionMethodInfo = getMgaDepletionMethodInfo(inventoryDepletionMethodLabel);
  const addedToInventory = isDebit;
  const shouldHideItems = !currencyId && hasExtraCurrencies;

  if (!hasInventoryItems || shouldHideItems) return null;

  return (
    <div className="d-flex flex-column">
      {addedToInventory ? null : (
        <div className="d-flex align-items-center text-violet fs-12 cmb-2 cmt-6">
          <Information
            svgStyle={{ width: 14, height: 14 }}
            tooltipClassname="min-width-fit max-width-412"
            target={'depletion-method'}
            svgIcon={infoIcon}
            message={depletionMethodInfo.subTitle}
          />
          <div className="cms-12">Inventory Depletion Method:</div>
          <div className="fw-bold cms-4">{depletionMethodInfo?.name || '-'}</div>
        </div>
      )}

      <FormList
        forms={subStates(`items`)}
        formListclassName="rounded-sm cpt-12 inventory-items-form-list"
        onAdd={() => addSubform('items')}
        onRemove={index => removeSubform('items', index)}
        addText={'Add item'}
        canRemove={() => true}
      >
        {formState => (
          <Item
            hasExtraCurrencies={hasExtraCurrencies}
            key={actionType?.id}
            hasId={!!formState?.state?.id?.value}
            formState={formState}
            subStates={subStates(`items`)}
            isInEditMode={isInEditMode}
            isBaseCurrency={isBaseCurrency}
            actionTypeId={actionType?.id}
            addedToInventory={addedToInventory}
            accountID={isCredit ? actionType?.credit_account_id : actionType?.debit_account_id}
            dateAction={dateAction}
            currencyId={currencyId}
          />
        )}
      </FormList>
    </div>
  );
};

export default InventoryItems;
