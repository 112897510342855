import { RootState } from '@/store';
import { strToNumber } from '@/ts-common/utils/numbers';
import { createSelector } from 'reselect';

export const selectOrganizationSettings = (state: RootState) => state.settings;

export const selectSystemCurrency = () => null;

export const selectExternalEmailStatus = () => null;

export const selectPurchasingRequisitionsHighlightCheapest = () => null;

export const selectPurchasingRequisitionsHighlightMostExpensive = () => null;

export const selectEvaluationsRangeFrom = createSelector(selectOrganizationSettings, state => {
  const from = strToNumber(state.evaluations_range_from);

  if (from !== null) {
    return from;
  }

  return 1;
});

export const selectEvaluationsRangeTo = (state: RootState) =>
  +selectOrganizationSettings(state).evaluations_range_to || 5;

export const selectEvaluationsStep = (state: RootState) =>
  +selectOrganizationSettings(state).evaluations_step || 0.5;

export const selectPmsJobsRequireTotalSystemRunningHoursWhenReportedInTheSystem = (
  state: RootState
) =>
  selectOrganizationSettings(state)
    .pms_jobs_require_total_system_running_hours_when_reported_in_the_system;

export const selectItineraryEditScheduledPortsOnboard = (state: RootState) =>
  selectOrganizationSettings(state).itinerary_edit_scheduled_ports_onboard;
