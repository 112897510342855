import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TableTop from 'common/components/table/TableTop';
import TableMain from './main';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import TableHeader from 'common/components/table/header';
import PageLoader from 'common/components/general/PageLoader';
import { getVisibleColumns } from 'common/components/table/utils/helpers';
import { useSelector } from 'react-redux';
import { TopType, ColumnsType, DefaultRequestParamsType } from 'common/entities/tables/TableTypes';

const Table = ({
  label,
  columns,
  rows,
  drawer,
  loader = true,
  defaultRequestParams,
  refetchData,
  requestParams,
  state,
  fetching,
  handleTableSearch,
  handleTableSort,
  handleTableApplyFilters,
  collapsableRows,
  triggerOpen = false,
  filters,
  excludedFilters,
  setFilters,
  topFilters,
  setTopFilters,
  top,
  hideTopPagination = false,
  hideTableFilters = true,
  hideTableSearch = false,
  hideTableReorder = false,
  hideTableHead = false,
  topFiltersComponent,
  archivedLabel,
  hiddenLabel,
  showArchived,
  showHidden,
  onArchive,
  onUnarchive,
  downLoadExtension,
  downloadText,
  onDownload,
  grayFiltersBg,
  emptyStateComponent,
  getRowClassName,
  onRowClick = () => null
}) => {
  const [dangerousModal, setDangerousModal] = useState({ isOpen: null });
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <div className={`app-table app-table__${label}${fetching && loader ? ' is-fetching' : ''}`}>
      <TableTop
        archivedLabel={archivedLabel}
        hiddenLabel={hiddenLabel}
        top={top}
        topFiltersComponent={topFiltersComponent}
        defaultRequestParams={defaultRequestParams}
        refetchData={refetchData}
        showArchived={showArchived}
        showHidden={showHidden}
        state={state}
        fetching={fetching}
        filters={filters}
        setFilters={setFilters}
        topFilters={topFilters}
        setTopFilters={setTopFilters}
        downLoadExtension={downLoadExtension}
        downloadText={downloadText}
        onDownload={onDownload}
        hideTopPagination={hideTopPagination}
      />

      {!hideTableFilters || (hideTableFilters && !hideTableSearch) ? (
        <TableHeader
          state={state}
          columns={hideTableFilters ? [] : columns}
          filters={hideTableFilters ? [] : filters}
          setFilters={setFilters}
          topFilters={topFilters}
          fetchData={refetchData}
          label={label}
          excludedFilters={excludedFilters}
          hideTableSearch={hideTableSearch}
          hideTableReorder={hideTableReorder || hideTableFilters}
          handleTableSearch={handleTableSearch}
          handleTableApplyFilters={handleTableApplyFilters}
          grayFiltersBg={grayFiltersBg}
        />
      ) : null}

      <TableMain
        state={state}
        setDangerousModal={setDangerousModal}
        columns={getVisibleColumns({ requestParams, columns, isOnBoard })}
        rows={rows}
        fetchData={refetchData}
        handleTableSort={handleTableSort}
        collapsableRows={collapsableRows}
        triggerOpen={triggerOpen}
        hideTableHead={hideTableHead}
        emptyStateComponent={emptyStateComponent}
        getRowClassName={getRowClassName}
        onRowClick={onRowClick}
      />

      {drawer ? React.cloneElement(drawer, { refetchData }) : null}

      {onArchive || onUnarchive ? (
        <DangerousActionModal
          transparent
          action={dangerousModal.isArchived ? 'unarchive' : 'archive'}
          actionHoverColor={'primary'}
          onAccept={async () => {
            dangerousModal.isArchived
              ? await onUnarchive(dangerousModal.params)
              : await onArchive(dangerousModal.params);
            refetchData();
          }}
          closeModal={() => setDangerousModal({ isOpen: false })}
          isOpen={dangerousModal.isOpen}
          actionText={dangerousModal.actionText ? dangerousModal.actionText.toUpperCase() : ''}
          header={dangerousModal.header}
          body={dangerousModal.body}
        />
      ) : null}

      {columns.some(c => c.field === 'actions') ? (
        <DangerousActionModal
          transparent
          closeModal={() => setDangerousModal({ isOpen: false })}
          onAccept={async () => {
            await dangerousModal.onAccept();
            setDangerousModal({ isOpen: false });
          }}
          isOpen={dangerousModal.isOpen}
          {...dangerousModal}
        />
      ) : null}

      <PageLoader isLoading={fetching && loader} />
    </div>
  );
};

export default Table;

Table.propTypes = {
  label: PropTypes.string.isRequired,
  columns: ColumnsType,
  rows: PropTypes.array.isRequired,
  drawer: PropTypes.element,
  loader: PropTypes.bool,
  defaultRequestParams: DefaultRequestParamsType,
  refetchData: PropTypes.func,
  requestParams: PropTypes.object,
  fetching: PropTypes.bool,
  handleTableSearch: PropTypes.func,
  handleTableSort: PropTypes.func,
  handleTableApplyFilters: PropTypes.func,
  collapsableRows: PropTypes.func,
  triggerOpen: PropTypes.bool,
  filters: PropTypes.array,
  excludedFilters: PropTypes.array,
  setFilters: PropTypes.func,
  topFilters: PropTypes.array,
  setTopFilters: PropTypes.func,
  top: TopType,
  hideTopPagination: PropTypes.bool,
  hideTableFilters: PropTypes.bool,
  hideTableSearch: PropTypes.bool,
  hideTableReorder: PropTypes.bool,
  hideTableHead: PropTypes.bool,
  topFiltersComponent: PropTypes.element,
  archivedLabel: PropTypes.string,
  showArchived: PropTypes.bool,
  hiddenLabel: PropTypes.string,
  showHidden: PropTypes.bool,
  onArchive: PropTypes.func,
  onUnarchive: PropTypes.func,
  downLoadExtension: PropTypes.string,
  downloadText: PropTypes.string,
  onDownload: PropTypes.func,
  isRowSelectable: PropTypes.func,
  grayFiltersBg: PropTypes.bool,
  emptyStateComponent: PropTypes.element,
  getRowClassName: PropTypes.func,
  onRowClick: PropTypes.func
};
