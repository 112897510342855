import React from 'react';

import { selectCrewBirthday } from 'crew/store/profile/selectors';
import { useSelector } from 'react-redux';
import { strToNumber, numberToStr } from 'common/utils/numbers';
import { getProfileAge } from 'common/utils/profiles';

const Totals = ({ components }) => {
  const birthday = useSelector(selectCrewBirthday);

  return (
    <div className="crew-sidebar__totals d-flex flex-column h-100p">
      {components.RatingGraph ? <components.RatingGraph /> : null}

      <div
        className={`crew-sidebar__totals-age d-flex align-items-center border rounded text-primary`}
      >
        <span className="text-violet fw-medium fs-12">Age</span>
        <strong className="fs-16">
          {getProfileAge(birthday) ? numberToStr(strToNumber(getProfileAge(birthday))) : '-'}
        </strong>
        <span className="fw-medium fs-12">years old</span>
      </div>
    </div>
  );
};

export default Totals;
