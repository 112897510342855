import { useEffect, useState } from 'react';
import Loader from '../components/Loader';

import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { usePrevious, useUpdateEffect } from 'utils/hooks';
import {
  selectDocumentDrawerType,
  selectDocumentDrawerIsOpen,
  selectDocumentDrawerActiveItem
} from 'store/vessels/selectors';
import useRouter from 'use-react-router';

import DateWithIcon from 'common/components/dates/DateWithIcon';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import BodyCell from 'common/components/sortable-table/BodyCell';
import IssueDate from './IssueDate';
import CreatedAtDate from './CreatedAtDate';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import ExpirationDate from '../components/ExpirationDate';
import { useAppDispatch } from '@/store/hooks';
import { getVesselDocumentHistoryAction } from '../store/actions';
import { DOCUMENT_TEMPLATES } from 'common/components/vessels/profile/tabs/documents/helpers';

const OldDocument = ({
  data,
  typeId,
  categoryName,
  downloadAttachments,
  isDownloading,
  template
}) => {
  const isFlagDispensationTemplate = template === DOCUMENT_TEMPLATES.flag_dispensations;
  const isSurveyTemplate = template === DOCUMENT_TEMPLATES.surveys;
  const isItemCertificateTemplate = template === DOCUMENT_TEMPLATES.item_certificate;

  return (
    <Row className="vessel-document-history__row app-table--body--row">
      <Col xs="auto" className="cell p-0">
        <div className="width-32"></div>
      </Col>
      <Col xs={3} className="cell"></Col>
      <Col className="cell min-width-165 max-width-16">
        <IssueDate
          date={data.issue_date}
          comments={data.comments}
          typeId={typeId}
          certificateId={data.id}
        />
      </Col>
      <Col className="cell">
        <CreatedAtDate createdAt={data.created_at} issuedBy={data.issued_by} />
      </Col>
      <Col xs={isFlagDispensationTemplate || isSurveyTemplate ? 1 : 2} className="cell me-0">
        <ExpirationDate date={data.expiration_date} />
      </Col>

      {isFlagDispensationTemplate ? (
        <>
          <Col xs={2} className="cell me-0">
            <TextWithTooltip>{data?.extension_dispensation_requested_for}</TextWithTooltip>
          </Col>
          <Col xs={1} className="cell me-0">
            <DateWithIcon hideIcon={true} date={data.completed_on} />
          </Col>
          <Col xs={1} className="cell me-0">
            <DateWithIcon hideIcon={true} date={data.flag_informed_on} />
          </Col>
        </>
      ) : null}

      {isSurveyTemplate ? (
        <>
          <Col xs={1} className="cell me-0">
            <DateWithIcon hideIcon={true} date={data.range_from} />
          </Col>
          <Col xs={1} className="cell me-0">
            <DateWithIcon hideIcon={true} date={data.range_to} />
          </Col>
          <Col xs={1} className="cell me-0">
            <DateWithIcon hideIcon={true} date={data.postponed} />
          </Col>
        </>
      ) : null}

      {isItemCertificateTemplate ? (
        <Col xs={1} className="cell">
          <div>{data.serial_number}</div>
        </Col>
      ) : null}

      <Col xs="auto" className="cell col app-table--body--row__attachments col-auto">
        {data.attachments_count ? (
          <DownloadAttachment
            isDisabled={isDownloading}
            downloadFileName={categoryName}
            data={data}
            downloadFiles={() => downloadAttachments(data)}
            hasAttachments={true}
          />
        ) : null}
      </Col>
      <Col xs="auto" className="p-0">
        <BodyCell
          col={{ key: 'actions', field: 'actions' }}
          row={() => ({ options: [] })}
          data={{}}
        />
      </Col>
    </Row>
  );
};

const DocumentHistory = ({
  data,
  isOpen,
  typeId,
  downloadAttachments,
  isDownloading,
  requestAttachments,
  template
}) => {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { match } = useRouter();

  const drawerIsOpen = useSelector(selectDocumentDrawerIsOpen);
  const drawerType = useSelector(selectDocumentDrawerType);
  const active = useSelector(selectDocumentDrawerActiveItem);
  const dispatch = useAppDispatch();

  const previousDrawerType = usePrevious(drawerType);
  const previousActiveId = usePrevious(active?.id);

  const fetchHistory = async () => {
    setIsLoading(true);

    try {
      const historyData = await dispatch(
        getVesselDocumentHistoryAction({
          category_id: data.id,
          vessel_id: match.params.id
        })
      ).unwrap();
      setHistory(historyData);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchHistory();
    }
  }, [isOpen]);

  useUpdateEffect(() => {
    if (!drawerIsOpen && previousDrawerType === 'renew' && previousActiveId === data.id) {
      fetchHistory();
    }
  }, [drawerIsOpen, previousDrawerType, previousActiveId]);

  return (
    <div className="vessel-document-history">
      {isLoading ? (
        <Loader />
      ) : (
        history.map(d => (
          <OldDocument
            key={d.id}
            data={d}
            typeId={typeId}
            template={template}
            downloadAttachments={downloadAttachments}
            requestAttachments={requestAttachments}
            isDownloading={isDownloading}
            categoryName={data.category_name}
          />
        ))
      )}
    </div>
  );
};

export default DocumentHistory;
