import React from 'react';
import PropTypes from 'prop-types';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';
const PALETTE_SIZE = 9; // $label-palette size constant

const ProgressLine = ({ steps = [], maxLimit = steps.length - 1, onClick }) => {
  const stepsLength = steps?.length;
  const { tooltipID, avoidRender } = useTooltipID('review-process-tooltip');
  const hasUncompletedSteps = steps.every(step => step?.uncompleted_substeps);

  if (avoidRender) return null;

  return (
    <div className="progress-line d-flex flex-row align-items-center">
      {steps
        .filter((_, i) => i < maxLimit)
        .map((step, index) => (
          <>
            <div
              id={step.is_required ? tooltipID : ''}
              key={index}
              className={`progress-item d-flex flex-row align-items-center ${
                onClick ? 'cursor-pointer' : ''
              }`}
              onClick={onClick ? onClick : null}
            >
              <div
                className={`${
                  step.is_required
                    ? `progress-item__required  ${
                        step?.uncompleted_substeps?.length
                          ? 'progress-item__required__uncompleted'
                          : `progress-item__required__color_${index % PALETTE_SIZE}`
                      }`
                    : ''
                }`}
              >
                <div
                  className={`${
                    step.is_required
                      ? ` progress-item__circle ${
                          step?.uncompleted_substeps?.length
                            ? 'progress-item__uncompleted'
                            : ` progress-item__color_${index % PALETTE_SIZE}`
                        }`
                      : `${
                          step?.uncompleted_substeps?.length
                            ? 'progress-item__empty'
                            : `progress-item__circle progress-item__color_${index % PALETTE_SIZE}`
                        }`
                  }
                  d-inline-flex align-items-center justify-content-center fs-12 fw-bold`}
                >
                  {index + 1}
                </div>
              </div>

              <div
                className={`progress-item__line ${
                  hasUncompletedSteps
                    ? 'progress-item__uncompleted-steps-line'
                    : `progress-item__color_${index % PALETTE_SIZE} `
                } ${index === steps.length - 1 ? 'd-none' : ''}`}
              />
            </div>
            {step.is_required ? <Tooltip target={tooltipID}>Mandatory Step</Tooltip> : null}
          </>
        ))}
      {stepsLength > maxLimit ? (
        <div className="progress-item__extra-steps-circle d-flex align-items-center justify-content-center fs-12">
          <strong>+{stepsLength - maxLimit}</strong>
        </div>
      ) : null}
    </div>
  );
};

export default ProgressLine;

ProgressLine.propTypes = {
  steps: PropTypes.array,
  onClick: PropTypes.func
};
