import { FC, HTMLProps } from 'react';
import { useAppSelector } from '@/store/hooks';
import {
  selectEvaluationsRangeFrom,
  selectEvaluationsRangeTo,
  selectEvaluationsStep
} from '@/store/settings/selectors';
import ScaleNumberInput from 'common/components/form/inputs/ScaleNumberInput';

const renderThumb = (
  props: HTMLProps<HTMLDivElement>,
  state: { index: number; value: number; valueNow: number }
) => {
  return <div {...props}>{state.valueNow > 1 ? state.valueNow : '-'}</div>;
};

type ScaleNumberInputWrapperProps = {
  onChange: (event: string | number | null) => void;
  disabled?: boolean;
  value?: number;
};

const ScaleNumberInputWrapper: FC<ScaleNumberInputWrapperProps> = ({
  disabled,
  onChange,
  value
}) => {
  const min = useAppSelector(selectEvaluationsRangeFrom);
  const max = useAppSelector(selectEvaluationsRangeTo);
  const step = useAppSelector(selectEvaluationsStep);

  return (
    <ScaleNumberInput
      min={min}
      max={max}
      step={step}
      value={value}
      disabled={disabled}
      onChange={onChange}
      sliderProps={{ renderThumb: !value ? renderThumb : undefined }}
    />
  );
};

export default ScaleNumberInputWrapper;
