import Table, { useTable } from 'common/components/table';
import Departments from 'common/components/general/Departments';
import SvgRender from 'common/components/general/SvgRender';
import NamesInTooltip from 'common/components/general/NamesInTooltip';

import paths from 'routing/routes/_paths';
import vesselIcon from 'assets/svg/sidebar/vessels.svg';
import Status from 'common/components/general/Status';
import Priority from 'common/components/general/Priority';
import { UncontrolledTooltip } from 'reactstrap';
import VesselSelector from 'common/components/selectors/VesselSelector';
import PortSelector from 'common/components/selectors/PortSelector';
import ImportanceSelector from 'events/components/selectors/ImportanceSelector';
import TypeSelector from 'events/components/selectors/TypeSelector';
import * as eventActions from 'events/store/events/actions';
import { useActions } from 'utils/hooks';
import binIcon from 'common/assets/svg/actions/delete.svg';
import clock from 'common/assets/svg/jobs/clock.svg';
import Subtypes from './components/Subtypes';

import PersonSelector from 'common/components/selectors/PersonSelector';
import EventModulesSelector from 'common/components/selectors/EventModulesSelector';
import DepartmentsFilterSelector from 'common/components/selectors/departments/FilterSelector';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import OcimfCategorySelector from 'common/components/selectors/OcimfCategorySelector';

import moment from 'moment';

import EventRelatedAuditSelector from 'common/components/selectors/EventAuditsSelector';
import EventStatusSelector from 'common/components/selectors/EventStatusSelector';
import PageLink from 'common/components/general/page-link';
import MessagesCountBubble from '@/common/components/general/MessagesCountBubble';
import FindingTagsSelector from '@/common/components/selectors/FindingTagsSelector';

const EventList = ({ isOnBoard }) => {
  const columns = [
    {
      header: 'I',
      filterLabel: 'Importance',
      key: 'importance',
      type: 'collection',
      sort: true,
      sticky: true,
      canFilter: false,
      left: 0,
      minWidth: 49,
      maxWidth: 49,
      className: 'priority-cell'
    },
    {
      header: 'ID',
      key: 'id',
      type: 'string',
      sort: true,
      canFilter: false,
      minWidth: 65,
      maxWidth: 65
    },
    {
      header: 'I',
      filterLabel: 'Importance',
      key: 'importance_id',
      type: 'collection',
      sort: false,
      hidden: true,
      component: ImportanceSelector,
      componentRest: { isMulti: true },
      canFilter: true
    },
    {
      header: 'Name',
      key: 'name',
      type: 'string',
      sort: true,
      sticky: true,
      left: 49,
      minWidth: 384,
      maxWidth: 384,
      className: 'title-cell'
    },
    {
      header: 'Type',
      key: 'type',
      type: 'string',
      canFilter: false,
      sort: false,
      sticky: true,
      left: 433,
      minWidth: 257,
      maxWidth: 257,
      className: 'title-cell'
    },
    {
      header: 'type',
      filterLabel: 'Type',
      key: 'type_id',
      type: 'collection',
      hidden: true,
      sort: false,
      component: TypeSelector,
      componentRest: { isMulti: true },
      canFilter: true
    },
    {
      header: 'Date',
      key: 'started_at',
      type: 'date',
      sort: true,
      showUtc: true,
      canFilter: true,
      className: 'ps-3',
      minWidth: 229,
      maxWidth: 229
    },
    {
      header: 'Responsible',
      key: 'responsible',
      type: 'string',
      sort: true,
      canFilter: false,
      minWidth: 217,
      maxWidth: 217
    },
    {
      header: 'Responsible',
      key: 'responsible_id',
      type: 'collection',
      sort: false,
      hidden: true,
      canFilter: true,
      component: PersonSelector,
      componentRest: {
        isMulti: true,
        listParams: { can_login: true, ...(isOnBoard ? { onboard: true } : {}) }
      }
    },
    {
      header: 'Department',
      key: 'department_ids',
      type: 'collection',
      sort: false,
      component: DepartmentsFilterSelector,
      minWidth: 217,
      maxWidth: 217
    },
    {
      header: 'Approvers',
      key: 'approvers',
      type: 'string',
      sort: true,
      canFilter: false,
      hidden: isOnBoard,
      minWidth: 217,
      maxWidth: 217
    },
    {
      header: 'Approvers',
      key: 'approver_id',
      type: 'collection',
      sort: false,
      hidden: true,
      canFilter: true,
      component: PersonSelector,
      componentRest: {
        isMulti: true,
        listParams: { can_login: true, ...(isOnBoard ? { onboard: true } : {}) }
      }
    },
    {
      header: 'Status',
      key: 'status',
      type: 'collection',
      sort: true,
      canFilter: false,
      minWidth: 217,
      maxWidth: 217,
      className: 'ps-4'
    },
    {
      header: 'Status',
      key: 'status_id',
      type: 'collection',
      sort: false,
      canFilter: true,
      component: EventStatusSelector,
      componentRest: { isMulti: true },
      hidden: true
    },
    {
      header: 'Vessel',
      key: 'vessel',
      type: 'collection',
      sort: true,
      canFilter: false,
      hidden: isOnBoard,
      className: `ps-4 ${isOnBoard ? 'd-none' : ''}`,
      minWidth: 145,
      maxWidth: 145
    },
    {
      header: 'Vessel',
      key: 'vessel_id',
      type: 'collection',
      sort: false,
      canFilter: !isOnBoard,
      component: VesselSelector,
      componentRest: { isMulti: true },
      hidden: true
    },
    {
      header: 'Port',
      key: 'port',
      type: 'collection',
      sort: true,
      minWidth: 217,
      maxWidth: 217,
      canFilter: false,
      className: `${isOnBoard ? 'ps-4' : 'pe-5'}`
    },
    {
      header: 'Port',
      key: 'port_id',
      type: 'collection',
      sort: false,
      canFilter: true,
      component: PortSelector,
      componentRest: { isMulti: true },
      hidden: true
    },
    {
      header: 'Included Modules',
      key: 'module_type_ids',
      type: 'collection',
      sort: false,
      canFilter: true,
      component: EventModulesSelector,
      componentRest: { isMulti: true },
      hidden: true
    },
    {
      header: 'Finding Tags',
      key: 'finding_tag_ids',
      type: 'collection',
      sort: false,
      canFilter: true,
      component: FindingTagsSelector,
      componentRest: { isMulti: true },
      hidden: true
    },
    {
      header: 'Audit/Inspection',
      key: 'event_audit_id',
      type: 'collection',
      component: EventRelatedAuditSelector,
      componentRest: { isMulti: true },
      sort: false,
      canFilter: !isOnBoard,
      hidden: true
    },
    {
      header: 'Ocimf System Category',
      key: 'ocimf_system_categorization',
      component: OcimfCategorySelector,
      type: 'ocimf_system_category',
      hidden: true,
      canFilter: true
    },
    !isOnBoard && {
      header: 'Watching',
      key: 'is_watching',
      type: 'boolean',
      hidden: true,
      canFilter: true,
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' }
      ]
    },
    {
      header: 'Actions',
      key: 'actions',
      hidden: isOnBoard,
      field: 'actions'
    }
  ];

  let table = useTable({
    label: 'events',
    columns,
    excludedFilters: ['is_watcher'],
    top: {}
  });

  const [deleteEvent] = useActions([eventActions.deleteEvent]);

  return (
    <Table
      // onArchive={isProject ? null : archiveJob}
      // onUnarchive={isProject ? null : unarchiveJob}
      loader
      archivedLabel="Events"
      // showArchived={!isProject}
      onDelete={async ({ id }) => {
        await deleteEvent({ id });
        table.refetchData();
      }}
      rows={{
        importance: data => <Priority value={data.importance.label} />,
        type: data => (
          <div className="d-flex align-items-center justify-content-between">
            <PageLink
              className="d-flex align-items-center text-nowrap overflow-hidden"
              to={`${paths.events}/${data.id}`}
            >
              {data?.type?.name}
              <Subtypes data={data} />
            </PageLink>
            {data.is_scheduled && (
              <>
                <SvgRender
                  id={`event-table-scheduled-tooltip-${data.id}`}
                  className="text-orange"
                  src={clock}
                  style={{ width: 13, height: 13 }}
                />

                <UncontrolledTooltip
                  innerClassName={`${
                    !isOnBoard && data.is_scheduled === false ? '' : 'event-scheduled-tooltip'
                  }`}
                  target={`event-table-scheduled-tooltip-${data.id}`}
                  trigger="hover"
                >
                  {'Scheduled'}
                </UncontrolledTooltip>
              </>
            )}
          </div>
        ),
        name: data => (
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center flex-1 max-width-calc-32">
              <PageLink to={`${paths.events}/${data.id}`}>{data.name}</PageLink>
              {!!data.chat_messages_count && (
                <MessagesCountBubble
                  className="width-16 height-16 cms-12"
                  count={data.chat_messages_count}
                />
              )}
            </div>
            {!isOnBoard && data.is_for_vessel ? (
              <>
                <SvgRender
                  id={`event-table-vessel-tooltip-${data.id}`}
                  className={`${
                    data?.status?.edit_side !== 'vessel' ? 'text-turquoise' : 'text-violet'
                  } cursor-pointer`}
                  src={vesselIcon}
                  style={{ width: 16, height: 16 }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`event-table-vessel-tooltip-${data.id}`}
                  trigger="hover"
                >
                  {data?.vessel?.name}
                </UncontrolledTooltip>
              </>
            ) : null}
          </div>
        ),
        started_at: data => (
          <div>
            {data.date_type === 'single' && data.started_at ? (
              <span>{moment(data.started_at).format('DD/MM/YYYY')}</span>
            ) : data.date_type === 'range' ? (
              <TextWithTooltip>
                {moment(data.started_at).format('DD/MM/YYYY')} -{' '}
                {moment(data.ended_at).format('DD/MM/YYYY')}
              </TextWithTooltip>
            ) : (
              '-'
            )}
          </div>
        ),

        responsible: data =>
          data.responsible && data.responsible.full_name ? (
            <PageLink to={`${paths.persons}/${data.responsible.id}`}>
              {data.responsible.full_name}
            </PageLink>
          ) : (
            <div className="text-secondary">Unassigned</div>
          ),
        department_ids: data => <Departments values={data.departments} />,
        approvers: data =>
          data?.approvers?.length ? (
            <NamesInTooltip
              maxHeight={20}
              names={(data?.approvers || []).map(approver => approver?.full_name)}
            />
          ) : (
            '-'
          ),
        status: data => <Status type="events" value={data.status} />,
        vessel: data =>
          isOnBoard ? null : data.vessel && data.vessel.name ? (
            <PageLink to={`${paths.vessels}/${data.vessel.id}`}>{data.vessel.name}</PageLink>
          ) : (
            <div className="text-secondary">No Vessel</div>
          ),
        port: data =>
          data.port && data.port.name ? (
            !isOnBoard ? (
              <PageLink to={`${paths.ports}/${data.port.id}`}>{data.port.name}</PageLink>
            ) : (
              <div className="d-flex align-items-center">
                <TextWithTooltip>{data.port.name}</TextWithTooltip>
              </div>
            )
          ) : (
            <div className="text-secondary">No Port</div>
          ),
        actions: data => ({
          options: [
            {
              label: 'Delete Event',
              icon: binIcon,
              preventionModal: true,
              modalProps: {
                params: { id: data.id },
                header: 'Delete Event',
                body: (
                  <div className="text-center">
                    Are you sure you want to delete <strong>{`${data?.name}`}</strong>? This cannot
                    be undone.
                  </div>
                )
              }
            }
          ]
        })
      }}
      {...table}
    />
  );
};

export default EventList;
