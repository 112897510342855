import React from 'react';
import PropTypes from 'prop-types';
import PageActions from 'components/layouts/page/table/PageActions';
import TitleLayout from './TitleLayout';

import { Row, Col } from 'reactstrap';

const SaveTableLayout = React.memo(
  ({ pageTitle, pageActions, label, className = '', headerClassName = '' }) => {
    return (
      <div className={`page-layout page-layout--table ${className}`}>
        <Row className={`align-items-center page-table-layout--header ${headerClassName}`}>
          <Col xs="auto">
            <TitleLayout title={pageTitle} label={label} />
          </Col>

          <PageActions actions={pageActions} label={label} />
        </Row>
      </div>
    );
  }
);

SaveTableLayout.propTypes = {
  label: PropTypes.string.isRequired,
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  pageActions: PropTypes.array,
  className: PropTypes.string,
  headerClassName: PropTypes.string
};

export default SaveTableLayout;
