import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import useRouter from 'use-react-router';
import _get from 'lodash/get';
import paths from 'routing/routes/_paths';
import { Link } from 'react-router-dom';

import { parseQueryParams, stringifyObj } from 'utils/urls';
import { download } from 'utils/api';
import ContractsTable from './contracts';
import Form from 'views/crew/form';
import PlaceAndDateText from './_components/PlaceAndDateText';
import PromotionLabel from 'views/crew/_components/PromotionLabel';

import useDrawer from 'common/components/drawer/useDrawer';
import Table, { useTable } from 'common/components/table';
import SaveTableLayout from 'components/layouts/page/table/save-table';
import TableTopFilter from 'common/components/table/TableTopFilter';
import ExportSelector from '@/common/components/selectors/ExportSelector.tsx';
import { handleFileDownload } from 'common/utils/downloads';
import CrewRankSelector from 'common/components/selectors/CrewRankSelector';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import NationalitySelector from 'common/components/selectors/NationalitySelector';
import AsOfDateFilter from 'common/components/dates/AsOfDateFilter';
import ExportIcon from 'common/components/general/ExportIcon';
import edit from 'common/assets/svg/actions/edit.svg';
import { getTableVisibilityParams } from 'common/components/table/utils/helpers';
import useTableTopFilterValue from 'common/components/filters/useTableTopFilterValue';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from 'common/utils/permissions/constants';
import { getCrewContract } from 'crew/store/contracts/actions';
import { useDispatch } from 'react-redux';
import { selectContractDate, selectContractPort } from '@/views/crew/profile/helpers.ts';
import { displayDate } from '@/ts-common/utils/dates';
import { borderedFilesTypes } from '@/ts-common/utils/files.ts';

import checkIcon from 'common/assets/svg/common/check-circle.svg';
import list from 'common/assets/svg/common/list.svg';
import camera from 'common/assets/svg/common/camera-line.svg';
import vessel from 'common/assets/svg/common/vessels.svg';

const options = [
  {
    label: 'Default',
    value: 'default',
    types: [{ type: 'excel', extension: borderedFilesTypes.excel, text: 'Export excel' }],
    icon: checkIcon
  },
  {
    label: 'Immigration Crew List',
    value: 'immigration',
    types: [{ type: 'excel', extension: borderedFilesTypes.excel, text: 'Export excel' }],
    icon: list
  },
  {
    label: 'Immigration Photo Crew List',
    value: 'photos',
    types: [{ type: 'pdf', extension: borderedFilesTypes.pdf, text: 'Export pdf' }],
    icon: camera
  },
  {
    label: 'IMO Crew List',
    value: 'imo',
    types: [
      { type: 'excel', extension: borderedFilesTypes.excel, text: 'Export excel' },
      { type: 'pdf', extension: borderedFilesTypes.pdf, text: 'Export pdf' }
    ],
    icon: vessel
  }
];

const CrewTable = () => {
  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { history, location } = useRouter();
  const [active, setActive] = useState(null);

  const dispatch = useDispatch();
  const drawer = useDrawer();

  const initDateFilter = useCallback(async () => {
    const { filters } = parseQueryParams(history.location.search);

    if (!filters || (filters && !filters.some(f => f.name === 'onboard_vessel_id'))) {
      history.replace({
        pathname: location.pathname,
        search: `${stringifyObj({
          filters: [
            {
              name: 'onboard_as_of_date',
              operation: '=',
              value: moment().format('YYYY-MM-DD')
            }
          ]
        })}`
      });
    }
  }, [history, location.pathname]);

  useEffect(() => {
    initDateFilter();
  }, [initDateFilter]);

  const table = useTable({
    defaultRequestParams: { visible: false, paging: false, sorting: true, filters: true },
    label: 'crew',
    urlRefetchLevel: 3,
    columns: [
      {
        header: 'Name',
        key: 'full_name',
        type: 'string',
        minWidth: 300,
        maxWidth: 300,
        sort: true
      },
      {
        header: '',
        key: 'promotion_label',
        minWidth: 14,
        maxWidth: 14
      },
      {
        header: 'Rank',
        key: 'rank_id',
        type: 'collection',
        className: 'text-truncate',
        component: CrewRankSelector,
        componentRest: { isMulti: true },
        minWidth: 144.6,
        maxWidth: 144.6,
        sort: true
      },
      {
        header: 'Nationality',
        key: 'nationality_id',
        type: 'collection',
        sort: true,
        component: NationalitySelector,
        componentRest: { isMulti: true, showDefaultOptions: true },
        minWidth: 150,
        maxWidth: 150
      },
      {
        header: 'Place & Date of Birth',
        key: 'birthday',
        type: 'string',
        minWidth: 300,
        maxWidth: 300,
        sort: true,
        canFilter: false
      },
      {
        header: 'SIGN ON DATE & PORT',
        key: 'initial_contract_sign_on',
        type: 'date',
        canFilter: false,
        sort: true,
        minWidth: 300,
        maxWidth: 300
      },
      {
        header: 'Req Sign Off Date',
        key: 'requested_sign_off_date',
        type: 'date',
        canFilter: false,
        sort: true,
        width: 1
      },
      {
        header: 'SIGN OFF DATE & PORT',
        key: 'active_contract_sign_off',
        type: 'date',
        canFilter: false,
        sort: true,
        minWidth: 250,
        maxWidth: 250
      },
      {
        header: 'PASSPORT NO.',
        key: 'passport_no',
        type: 'string',
        canFilter: false,
        sort: false,
        minWidth: 180,
        maxWidth: 180
      },
      {
        header: 'PASSPORT EXP.DATE',
        key: 'passport_exp_date',
        type: 'date',
        canFilter: false,
        sort: false,
        minWidth: 180,
        maxWidth: 180
      },
      {
        header: 'USA VISA NO.',
        key: 'usa_visa_no',
        type: 'string',
        canFilter: false,
        sort: false,
        minWidth: 180,
        maxWidth: 180
      },
      {
        header: 'USA VISA EXP.DATE',
        key: 'usa_visa_exp_date',
        type: 'date',
        canFilter: false,
        sort: false,
        minWidth: 180,
        maxWidth: 180
      },
      {
        header: 'Actions',
        key: 'actions',
        field: 'actions'
      }
    ],
    top: {
      filters: [
        {
          name: 'onboard_as_of_date',
          operation: '=',
          value: moment().format('YYYY-MM-DD')
        }
      ]
    }
  });

  const [dateFilter, setDateFilter] = useTableTopFilterValue(
    { topFilters: table.topFilters, setTopFilters: table.setTopFilters },
    'onboard_as_of_date'
  );

  const onDownload = async extraParams => {
    const {
      requestParams: { sorting, filters }
    } = table;
    const rParams = {
      sorting,
      filters,
      visible: getTableVisibilityParams(table),
      ...extraParams
    };

    setIsLoading(true);

    try {
      await handleFileDownload(
        {
          url: '/crew/export'
        },
        download,
        false,
        {
          requestParams: rParams,
          parsed: true
        }
      );

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const isEditable = data => {
    if (!data?.sign_off_date) {
      return true;
    } else {
      return moment(data?.sign_off_date)?.isAfter(moment().subtract(1, 'months'));
    }
  };

  return (
    <Row className="crew-table">
      <Col>
        <SaveTableLayout pageTitle={'Crew'} />
      </Col>
      <Col xs={12} className="mb-1">
        <Row noGutters className="crew-table--top-filters">
          <Col className="d-flex align-items-center">
            <AsOfDateFilter onChange={value => setDateFilter(value || null)} value={dateFilter} />

            <div className="small-seperator cpt-12 bg-platinum cpb-12 mx-2"></div>

            <TableTopFilter>
              <ExportSelector
                className="me-1"
                selected={selected}
                setSelected={setSelected}
                options={options}
              />
            </TableTopFilter>

            {selected
              ? selected?.types?.map((s, i) => (
                  <ExportIcon
                    key={i}
                    extension={s?.extension}
                    text={s?.text}
                    onClick={() => onDownload({ export: selected.value, type: s?.type })}
                    className={`${i >= 1 ? 'ms-1' : ''}`}
                    disabled={isLoading}
                  />
                ))
              : null}
          </Col>
        </Row>
      </Col>
      <Col xs={12} className="mb-4">
        <Table
          loader
          hideTopPagination
          mainHeaderComponent={<ContractsTable />}
          drawer={<Form drawer={drawer} active={active} />}
          rows={{
            full_name: data => (
              <AuthCheck
                permissions={[permissions.onboard.crew.seaman_profile.personal.all_users.view]}
                unAuthorizedRender={data.full_name}
              >
                <div className="d-flex align-items-center justify-content-between flex-nowrap w-100p">
                  <div className="flex-1 max-width-calc-16">
                    {data.type === 'crew' ? (
                      <Link to={`${paths.crew}/${data.id}`}>
                        <TextWithTooltip>{data.full_name || '-'}</TextWithTooltip>
                      </Link>
                    ) : (
                      <Link to={`${paths.persons}/${data.person_id}`}>
                        <TextWithTooltip>{data.full_name || '-'}</TextWithTooltip>
                      </Link>
                    )}
                  </div>
                </div>
              </AuthCheck>
            ),
            promotion_label: data => {
              const initialRank = _get(data, 'contract_rank.name');
              const promotionRank = _get(data, 'active_promotion_plan.promotion_rank.name');

              return initialRank && promotionRank ? (
                <PromotionLabel initialRank={initialRank} promotionRank={promotionRank} />
              ) : null;
            },
            rank_id: data => (
              <TextWithTooltip className="d-flex align-items-center">
                {data.contract_rank ? data.contract_rank.name : '-'}
              </TextWithTooltip>
            ),
            birthday: data => (
              <PlaceAndDateText
                place={data?.place}
                date={data?.birthday ? moment(data.birthday).format('DD/MM/YYYY') : null}
              />
            ),
            nationality_id: data => _get(data, 'nationality.name', '-'),
            initial_contract_sign_on: data => {
              const signOnDate = selectContractDate(data?.initial_contract);
              const signOnPort = selectContractPort(data?.initial_contract);

              return (
                <PlaceAndDateText
                  place={_get(signOnPort, 'name')}
                  date={displayDate(signOnDate, 'DD/MM/YYYY HH:mm')}
                />
              );
            },
            active_contract_sign_off: data => {
              const signOffPort = selectContractPort(data?.active_contract, false);
              const signOffDate = selectContractDate(data?.active_contract, false);

              return (
                <PlaceAndDateText
                  place={_get(signOffPort, 'name')}
                  date={displayDate(signOffDate, 'DD/MM/YYYY HH:mm')}
                />
              );
            },
            requested_sign_off_date: data => {
              const requestedSignOffDate = _get(data, 'active_contract.requested_sign_off_date');
              return displayDate(requestedSignOffDate, 'DD/MM/YYYY');
            },
            passport_no: data =>
              data?.passport?.code ? (
                <TextWithTooltip className="h-auto">{data.passport.code}</TextWithTooltip>
              ) : (
                '-'
              ),
            passport_exp_date: data => {
              const date = data?.passport?.expires_at;
              return date ? moment(date).format('DD/MM/YYYY') : '-';
            },
            usa_visa_no: data =>
              data?.visa?.code ? (
                <TextWithTooltip className="h-auto">{data.visa.code}</TextWithTooltip>
              ) : (
                '-'
              ),
            usa_visa_exp_date: data => {
              return data?.visa?.expires_at
                ? moment(data.visa.expires_at).format('DD/MM/YYYY')
                : '-';
            },
            actions: data => ({
              options:
                data?.type === 'crew' && isEditable(data)
                  ? [
                      {
                        label: 'Edit',
                        icon: edit,
                        permissions: [
                          permissions.onboard.crew.seaman_profile.contracts.sign_on_date.edit,
                          permissions.onboard.crew.seaman_profile.contracts.sign_off_date.edit
                        ],
                        onClick: async () => {
                          await dispatch(getCrewContract({ id: data.contract_id }));
                          setActive(data);
                          drawer.open();
                        }
                      }
                    ]
                  : []
            })
          }}
          {...table}
        />
      </Col>
    </Row>
  );
};

export default CrewTable;
