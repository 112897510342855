import React from 'react';

import useRouter from 'use-react-router';
import SvgRender from 'common/components/general/SvgRender';

import paths from 'routing/routes/_paths';

import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { selectCrewProfileActiveView } from 'crew/store/profile/selectors';
import AuthCheck from 'components/permissions/AuthCheck';

import skills from 'common/assets/svg/common/skills.svg';
import policies from 'common/assets/svg/common/policies.svg';
import permissions from 'common/utils/permissions/constants';

const Views = ({ views, isOnboard }) => {
  const { match } = useRouter();
  const active = useSelector(selectCrewProfileActiveView);

  return (
    <div className="crew-sidebar__views">
      <Row className="g-0">
        {views.map(view =>
          view.hiddenTab ? null : (
            <AuthCheck mode={view.permissionMode} permissions={view.permissions} key={view.label}>
              <Col xs={6}>
                <NavLink
                  data-cy={view.label}
                  className={`crew-sidebar__views-link text-uppercase ${
                    view.notify ? 'crew-sidebar__views-link--notify' : ''
                  }`}
                  to={`${paths.crew}/${match.params.id}/${view.label}`}
                  isActive={() => active === view.label}
                >
                  <SvgRender src={view.icon} style={{ height: 20, width: 20 }} />
                  <span>{view.name}</span>
                </NavLink>
              </Col>
            </AuthCheck>
          )
        )}
      </Row>

      {!isOnboard ? (
        <div className="cmt-12">
          <NavLink
            className="crew-sidebar__views-link w-100p height-28 flex-row align-items-center rounded rounded-lg"
            to={`${paths.crew}/${match.params.id}/skills`}
            isActive={() => active === 'skills'}
          >
            <SvgRender className="me-1 mb-0 mt-0" src={skills} style={{ height: 16, width: 16 }} />
            <strong>SKILLS & EXPERIENCE</strong>
          </NavLink>

          <AuthCheck permissions={[permissions.office.crew.seaman_profile.policies.view]}>
            <NavLink
              className="crew-sidebar__views-link w-100p height-28 flex-row align-items-center rounded rounded-lg cmt-12"
              to={`${paths.crew}/${match.params.id}/policies`}
              isActive={() => active === 'policies'}
            >
              <SvgRender
                className="me-1 mb-0 mt-0"
                src={policies}
                style={{ height: 16, width: 16 }}
              />
              <strong className="lh-1">PERMISSION POLICIES</strong>
            </NavLink>
          </AuthCheck>
        </div>
      ) : null}
    </div>
  );
};

export default Views;
