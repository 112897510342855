import React from 'react';
import { Row, Col } from 'reactstrap';
import SaveTableLayout from 'components/layouts/page/table/save-table';
import TableHeaderName from 'common/components/pms/jobs/components/TableHeaderName';
import NavTabs from 'common/components/pms/jobs/components/NavTabs';
import * as pmsJobsActions from 'common/components/pms/jobs/store/actions';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import permissions from 'common/utils/permissions/constants';
import AnnualReportAction from '../../components/AnnualReportAction';

const Header = React.memo(() => {
  const [toggleJobDrawer] = useActions([pmsJobsActions.toggleJobDrawer]);
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <Row>
      <Col xs="auto">
        <NavTabs />
      </Col>

      <div className="small-separator mx-3 cpy-10 cmt-6 bg-platinum" />

      <Col>
        <SaveTableLayout
          label={'pms_jobs_per_system'}
          pageTitle={
            <div className="d-flex align-items-center">
              <div>
                <TableHeaderName /> <strong>/ Per System</strong>
              </div>
            </div>
          }
          pageActions={
            isOnBoard
              ? []
              : [
                  { type: 'favorite' },
                  { customElement: () => <AnnualReportAction isList={false} /> },
                  {
                    type: 'create',
                    color: 'primary',
                    text: 'Add Job',
                    onClick: () => {
                      toggleJobDrawer(true, null);
                    },
                    permissions: [permissions.office.jobs.create]
                  }
                ]
          }
        />
      </Col>
    </Row>
  );
});

export default Header;
