import { useFormState } from 'utils/hooks';

import CriteriaCategory from './CriteriaCategory';
import ScaleRatings from 'common/components/general/ScaleRatings';
import ReportType from './ReportType';
import RecommendButtons from './RecommendButtons';
import { useAppSelector } from '@/store/hooks';
import { selectShouldShowDefaultScaleLabel } from '@/store/lists/selectors-ts';

const ComponentAnalysisView = ({ criteria, categories, active }) => {
  return (
    <>
      <div className="mt-2">
        {categories.map((category, index) => (
          <CriteriaCategory key={index} category={category} criteria={criteria} view />
        ))}
      </div>
      <ReportType template={active && active.template ? active.template : null} />
    </>
  );
};

const ComponentAnalysisCreateOrDraft = ({ formState, categories, onRecalculateAverageScore }) => {
  const { subStates } = useFormState(formState);
  const criteriaStates = subStates('criteria');

  const shouldShowDefaultScaleLabel = useAppSelector(selectShouldShowDefaultScaleLabel);

  return (
    <>
      <div className="ms-1 my-2 d-flex">
        {shouldShowDefaultScaleLabel ? <ScaleRatings /> : null}
      </div>
      <div>
        {categories.map((category, index) => (
          <CriteriaCategory
            key={index}
            category={category}
            criteria={criteriaStates}
            onRecalculateAverageScore={onRecalculateAverageScore}
          />
        ))}
      </div>
    </>
  );
};

const ComponentAnalysis = ({
  criteria,
  categories,
  formState,
  view,
  active,
  onRecalculateAverageScore
}) => {
  return (
    <>
      {!view ? <div className="border-bottom-gray-200 mt-4" /> : null}
      {!view ? <RecommendButtons formState={formState} /> : null}
      {!view ? (
        <div className={`mt-4 ms-1`}>
          <span className="text-primary fs-20 fw-medium lh-1">Components Analysis</span>
        </div>
      ) : null}
      {view ? (
        <ComponentAnalysisView categories={categories} criteria={criteria} active={active} />
      ) : (
        <ComponentAnalysisCreateOrDraft
          categories={categories}
          formState={formState}
          onRecalculateAverageScore={onRecalculateAverageScore}
        />
      )}
    </>
  );
};

export default ComponentAnalysis;
