import JobTriggerPrototypeSelector from 'common/components/selectors/jobs/JobTriggerPrototypeSelector';
import MaintenanceJobTypeSelector from 'common/components/selectors/jobs/MaintenanceJobTypeSelector';
import JobStatusSelector from 'common/components/selectors/JobStatusSelector';
import JobPrioritySelector from 'common/components/selectors/jobs/JobPrioritySelector';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';
import VesselSystemGroupSelector from 'common/components/selectors/VesselSystemGroupSelector';
import SparePartsSelector from 'common/components/selectors/SparePartsSelector';

const columns = [
  {
    header: 'System',
    key: 'assignment_vessel_system_id',
    type: 'string',
    sort: true,
    canFilter: false,
    sticky: true,
    minWidth: 368,
    maxWidth: 368,
    left: 0
  },
  {
    header: 'I',
    filterLabel: 'Importance',
    key: 'importance',
    sort: true,
    canFilter: false,
    sticky: true,
    minWidth: 72,
    maxWidth: 72,
    left: 368
  },
  {
    header: 'Title',
    key: 'title',
    canFilter: false,
    sort: true,
    headerClassName: 'title-column-header',
    sticky: true,
    minWidth: 368,
    maxWidth: 368,
    left: 440
  },
  {
    header: 'Vessel',
    key: 'vessel_name',
    canFilter: false,
    className: 'ps-2',
    sort: true,
    minWidth: 172,
    maxWidth: 172,
    hiddenOnBoard: true
  },
  {
    header: 'Status',
    key: 'status_id',
    sort: true,
    width: 1,
    type: 'collection',
    component: JobStatusSelector,
    componentRest: {
      isMulti: true,
      showDefaultOptions: true
    },
    minWidth: 192,
    maxWidth: 192,
    className: 'overflow-hidden'
  },
  {
    header: 'Type',
    key: 'job_type',
    sort: true,
    canFilter: false,
    minWidth: 143,
    maxWidth: 143
  },
  {
    header: 'Periodicity',
    key: 'periodicity',
    sort: true,
    canFilter: false,
    minWidth: 144,
    maxWidth: 144
  },

  {
    header: 'Periodicity Interval',
    key: 'periodicity_interval',
    type: 'periodicity_interval',
    canFilter: true,
    hidden: true
  },

  {
    header: 'Periodicity Running Hours',
    key: 'periodicity_running_hours',
    type: 'number',
    canFilter: true,
    hidden: true
  },
  {
    header: 'Last Done At',
    key: 'last_done_at',
    type: 'date',
    canFilter: false,
    minWidth: 143,
    maxWidth: 143
  },
  {
    header: 'Done At',
    key: 'carried_out_at',
    type: 'date',
    sort: true,
    canFilter: true,
    minWidth: 143,
    maxWidth: 143
  },
  {
    header: 'Due Date',
    key: 'due_date',
    type: 'date',
    sort: true,
    canFilter: false,
    minWidth: 115,
    maxWidth: 115
  },
  {
    header: 'Last Done R/H',
    key: 'last_done_rh',
    type: 'number',
    sort: false,
    canFilter: false,
    className: 'text-end justify-content-end',
    minWidth: 143,
    maxWidth: 143
  },
  {
    header: 'R/H Due on',
    key: 'due_on_rh',
    type: 'number',
    sort: true,
    canFilter: false,
    className: 'text-end justify-content-end rounded-end rounded-lg',
    minWidth: 130,
    maxWidth: 130
  },
  {
    header: 'LAST R/H REPORT',
    key: 'last_rh_report',
    type: 'string',
    sort: false,
    canFilter: false,
    className: 'text-start justify-content-end rounded-start rounded-lg',
    minWidth: 180,
    maxWidth: 180
  },
  {
    header: 'R/H Left',
    key: 'rh_left',
    type: 'number',
    sort: false,
    canFilter: false,
    className: 'text-end justify-content-end',
    minWidth: 80,
    maxWidth: 80
  },
  {
    header: '',
    reorderColumnHeader: 'Library Attachments',
    key: 'maintenance_attachments',
    sort: false,
    canFilter: false,
    minWidth: 60,
    maxWidth: 60,
    canReorder: true
  },
  {
    header: 'Forms to be completed',
    key: 'forms_completed',
    type: 'string',
    sort: false,
    canFilter: false,
    minWidth: 200,
    maxWidth: 200
  },
  {
    header: '',
    reorderColumnHeader: 'Job Attachments',
    key: 'attachments',
    sort: false,
    canFilter: false,
    minWidth: 60,
    maxWidth: 60,
    canReorder: true
  },
  {
    header: 'Job Prototype',
    key: 'prototype_id',
    type: 'collection',
    sort: true,
    hidden: true,
    hiddenOnBoard: true,
    component: JobTriggerPrototypeSelector,
    componentRest: { isMulti: true, showDefaultOptions: true, listParams: { is_maintenance: true } }
  },
  {
    header: 'System',
    key: 'system_title',
    type: 'string',
    sort: false,
    canFilter: true,
    hidden: true
  },
  {
    header: 'Done before due',
    key: 'done_before_due',
    type: 'percentage',
    hidden: true
  },
  {
    header: 'Job Title',
    key: 'job_title',
    type: 'string',
    sort: false,
    canFilter: true,
    hidden: true
  },
  {
    header: 'Job Type',
    key: 'maintenance_job_type_id',
    type: 'collection',
    sort: false,
    canFilter: true,
    component: MaintenanceJobTypeSelector,
    componentRest: {
      isMulti: true,
      showDefaultOptions: true
    },
    hidden: true
  },
  {
    header: 'Job Importance',
    key: 'importance_id',
    type: 'collection',
    sort: false,
    canFilter: true,
    component: JobPrioritySelector,
    componentRest: {
      isMulti: true,
      showDefaultOptions: true
    },
    hidden: true
  },
  {
    header: 'Critical',
    key: 'is_critical',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Periodicity As Needed',
    key: 'periodicity_as_needed',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Has Comments/Attachments',
    key: 'has_comments_or_attachments',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Class',
    key: 'is_class',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Environmental',
    key: 'is_environmental_critical',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Navigational',
    key: 'is_navigational',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Has linked Forms',
    key: 'has_forms',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Due changed',
    key: 'due_date_changed',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Due Date/RH Changed',
    key: 'due_date_due_on_rh_changed',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Safety',
    key: 'is_safety',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Drydock',
    key: 'is_drydock',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Job Office Department',
    key: 'department_id',
    hidden: true,
    canFilter: true,
    type: 'collection',
    component: DepartmentSelector
  },
  {
    header: 'Job Vessel Department',
    key: 'main_department_id',
    hidden: true,
    canFilter: true,
    type: 'collection',
    component: DepartmentSelector,
    componentRest: {
      onlyCrewDepartments: true
    }
  },
  {
    header: 'System group',
    key: 'vessel_system_group_id',
    hidden: true,
    canFilter: true,
    type: 'collection',
    component: VesselSystemGroupSelector,
    componentRest: {
      isMulti: true
    }
  },
  {
    header: 'Consumed Spare Parts',
    key: 'consumed_spare_parts',
    type: 'collection',
    sort: true,
    hidden: true,
    hiddenOnBoard: true,
    component: SparePartsSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Class Number',
    key: 'class_no',
    type: 'string',
    canFilter: true,
    hidden: true
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions',
    hiddenOnBoard: true
  }
];

export default columns;
