import { useMemo } from 'react';
import _get from 'lodash/get';

import ButtonGroupTabs from 'common/components/buttons/ButtonGroupTabs';

const NavTabs = ({ topFilters = [], setTopFilters }) => {
  const selectedTab = _get(topFilters[0], 'value', 'pending');

  const tabs = useMemo(
    () => [
      {
        label: 'Pending',
        value: 'pending'
      },
      {
        label: 'Completed',
        value: 'completed'
      }
    ],
    []
  );

  const onTabClick = value => {
    setTopFilters(topFilters.map(f => (f.name === 'status' ? { ...f, value } : f)));
  };

  return (
    <div className="d-flex align-items-start me-3 pe-3 border-end">
      <ButtonGroupTabs active={selectedTab} options={tabs} onClick={onTabClick} />
    </div>
  );
};

export default NavTabs;
