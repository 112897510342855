import { Button } from 'reactstrap';

const ButtonFilter = ({
  onClick,
  className = '',
  isActive,
  label,
  children,
  innerClassName = '',
  disabled,
  size = 'sm'
}) => {
  return (
    <Button
      type="button"
      color="link"
      className={`d-inline-flex align-items-center btn-status-filter ${
        isActive ? 'active' : ''
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
      size={size}
    >
      <span className="fs-12 lh-1 fw-medium btn-status-filter__text">{label}</span>
      <span className={`btn-status-filter__num ${innerClassName}`}>{children}</span>
    </Button>
  );
};

export default ButtonFilter;
