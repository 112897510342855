import React, { useState, useEffect } from 'react';
import useRouter from 'use-react-router';
import { useActions, useUpdateEffect } from 'utils/hooks';
import * as crewDocumentsActions from 'store/crew/documents/actions';

import { Row, Col } from 'reactstrap';
import { useTable } from 'common/components/table';
import SvgRender from 'common/components/general/SvgRender';
import Filters from './Filters';
import PageLoader from 'common/components/general/PageLoader';
import Information from 'common/components/general/Information';

import _get from 'lodash/get';
import moment from 'moment';
import { dateToLocal } from 'common/utils/dates';
import { useSelector } from 'react-redux';

import useDrawer from 'common/components/drawer/useDrawer';

import calendar from 'common/assets/svg/common/calendar.svg';
import comments from 'common/assets/svg/common/comments.svg';
import deactivate from 'common/assets/svg/actions/deactivate.svg';

import ColoredTable from 'common/components/colored-table';
import HistoryRowCollapsable from './HistoryRowCollapsable';

import { getClassNameForExpirationColumn } from './helpers';

import CircledButton from 'common/components/buttons/CircledButton';

import DocumentTypeIndicator from 'crew/components/DocumentTypeIndicator';

const DocumentsTable = ({
  category,
  actions,
  form,
  onCreateClick,
  onAttachFiles,
  onArchive,
  warnings = {},
  isOnboard
}) => {
  const downloadingDocs = useSelector(state => state.crew.documents.downloading);

  const warningDocsExist = !!Object.values(warnings)
    ?.flat()
    ?.filter(e => e.category === category)?.length;

  const [isLoading, setIsLoading] = useState(false);
  const [crewDocumentsData, setCrewDocumentsData] = useState([]);

  const [expirationFilter, setExpirationFilter] = useState({
    column: {
      key: 'expires_at',
      label: 'Expiration Date',
      type: 'date'
    },
    operation: {
      label: '=',
      value: '='
    },
    value: ''
  });
  const [getCrewDocuments, downloadCrewDocumentAttachments] = useActions([
    crewDocumentsActions.getCrewDocuments,
    crewDocumentsActions.downloadCrewDocumentAttachments
  ]);

  const drawer = useDrawer(false);
  const { match } = useRouter();

  const fetchData = async params => {
    setIsLoading(true);

    const { filters, ...rest } = params;
    const categoryFilter = { name: 'category', operation: 'oneOf', value: [category] };
    const filtersParams = [...filters, categoryFilter];

    if (expirationFilter.value) {
      const { operation, value } = expirationFilter;
      filtersParams.push({
        name: 'expires_at',
        operation: operation.value,
        value: value.from
          ? [
              dateToLocal(value.from).format('YYYY-MM-DD'),
              dateToLocal(value.to).format('YYYY-MM-DD')
            ]
          : dateToLocal(value).format('YYYY-MM-DD')
      });
    }

    const res = await getCrewDocuments({ id: match.params.id, ...rest, filters: filtersParams });

    setCrewDocumentsData(res?.data?.length ? res.data : []);
    setIsLoading(false);
  };

  const table = useTable({
    label: 'crew_profile_documents',
    defaultRequestParams: { visible: false, paging: true, sorting: true, filters: true },
    urlRefetchLevel: 4,
    requestTableListFrom: fetchData,
    top: { filters: [{ name: 'archived', operation: '=', value: null }] },
    columns: [
      {
        header: 'Document',
        key: 'type',
        type: 'string'
      },
      {
        header: 'CODE / NUMBER',
        key: 'code',
        type: 'string'
      },
      {
        header: 'issue',
        key: 'issued_at',
        type: 'date',
        width: 2
      },
      {
        header: 'Expiration',
        key: 'expires_at',
        type: 'date',
        width: 1
      },
      {
        header: 'entered in',
        key: 'created_at',
        type: 'date',
        width: 1
      },
      {
        key: 'attachments_count',
        field: 'attachments'
      },
      actions
        ? {
            header: 'Actions',
            key: 'actions',
            field: 'actions',
            className: 'text-center'
          }
        : { header: 'Actions', key: 'actions', hidden: true, className: 'd-none' }
    ]
  });

  const archivedTable = !!table.filters.find(f => f?.column?.key === 'archived');

  const resetTablePagination = () => {
    fetchData({
      ...table.requestParams,
      paging: { current_page: 1, per_page: table.requestParams.paging.per_page }
    });
  };

  useUpdateEffect(() => {
    resetTablePagination();
  }, [expirationFilter.value, expirationFilter.operation.value]);

  const isArchivedFilterActive =
    table.filters.find(filter => filter.column?.value === 'archived')?.value === 'true';

  return (
    <Row>
      <Col xs={12}>
        <Filters
          table={table}
          onCreateClick={onCreateClick}
          drawer={drawer}
          expirationFilter={expirationFilter}
          setExpirationFilter={setExpirationFilter}
        />

        {crewDocumentsData?.length ||
        warningDocsExist ||
        expirationFilter?.value ? null : !isLoading ? (
          <Col className="d-flex fs-20 justify-content-center h-100p align-items-end mt-10" xs={12}>
            No Documents
          </Col>
        ) : null}
      </Col>

      {!isOnboard && warnings.missing.length && !isArchivedFilterActive ? (
        <Col
          xs={12}
          className={`mt-2 mb-4 ${crewDocumentsData?.length || warningDocsExist ? '' : 'd-none'}`}
        >
          <ColoredTable
            className="missing-documents"
            hideTableReorder
            hideTableSearch
            hideTableFilters
            hideTopPagination
            columns={[
              {
                header: 'Missing Documents',
                key: 'name',
                type: 'string',
                className: 'ps-5'
              },
              {
                key: 'missing_documents_action',
                type: 'string',
                className: 'text-end'
              }
            ]}
            rows={{
              name: data => {
                const requiredType = _get(data, 'rank_warnings.required_type');

                return (
                  <div className={`d-flex align-items-center flex-nowrap g-0`}>
                    <DocumentTypeIndicator
                      type={requiredType}
                      className="position-absolute start-0 cms-4"
                    />

                    {_get(data, 'name') || '-'}
                  </div>
                );
              },
              missing_documents_action: data => {
                return (
                  <CircledButton
                    label="Add Document"
                    type={'add'}
                    onClick={() => onCreateClick(data)}
                    style={{ width: 18, height: 18 }}
                    svgStyle={{ width: 9, height: 9 }}
                    reversed
                  />
                );
              }
            }}
            state={{ data: warnings.missing }}
          />
        </Col>
      ) : null}

      <Col
        xs={12}
        className={`${isArchivedFilterActive ? 'mt-2' : ''} ${
          crewDocumentsData?.length || warningDocsExist ? '' : 'd-none'
        }`}
      >
        <ColoredTable
          hideTableReorder
          hideTableSearch
          hideTableFilters
          hideTopPagination
          className={`${archivedTable ? 'archived-documents' : ''}`}
          key={category}
          onArchive={onArchive}
          drawer={form ? React.cloneElement(form, { category }) : null}
          collapsableRows={data =>
            data.has_history ? (
              <HistoryRowCollapsable
                downloadAttachments={downloadCrewDocumentAttachments}
                key={data.id}
                crewId={match.params.id}
                {...data}
              />
            ) : null
          }
          rows={{
            type: data => (
              <Row className="align-items-center flex-nowrap g-0 ">
                <DocumentTypeIndicator
                  type={data?.warnings?.required_type}
                  className="position-absolute start-0 cms-4"
                />
                <Col>{_get(data, 'type.name') || '-'}</Col>
                {data.active ? (
                  <>
                    {data.comments ? (
                      <Col xs="auto">
                        <Information
                          target={`crew-doc-comments-${data.id}`}
                          svgIcon={comments}
                          svgStyle={{ width: 16, height: 14 }}
                          className="text-purple"
                          message={data.comments}
                        />
                      </Col>
                    ) : null}
                  </>
                ) : null}
              </Row>
            ),
            issued_at: data => (
              <div className="d-flex align-items-center lh-1">
                {data.active ? (
                  data.issued_at ? (
                    <>
                      <span className="text-nowrap">
                        {moment(data.issued_at).format('DD/MM/YYYY')}
                      </span>
                      <div className="small-seperator text-violet"></div>
                      <span className="text-violet">{data.country ? data.country.name : '-'}</span>
                    </>
                  ) : (
                    '-'
                  )
                ) : null}
              </div>
            ),
            created_at: data =>
              data.active && data.created_at ? (
                <div className="d-flex align-items-center lh-1">
                  <span>{dateToLocal(data.created_at).format('DD/MM/YYYY')}</span>
                </div>
              ) : null,
            expires_at: data =>
              data.active && data.expires_at ? (
                <div
                  className={`d-flex align-items-center lh-1${getClassNameForExpirationColumn(
                    data,
                    warnings
                  )}`}
                >
                  <span>{moment(data.expires_at).format('DD/MM/YYYY')}</span>
                </div>
              ) : null,

            attachments_count: data =>
              data.active ? (
                {
                  isDisabled: downloadingDocs.indexOf(data.id) !== -1,
                  hasAttachments: (_get(data, 'attachments_count') || 0) > 0,
                  downloadFileName: (_get(data, 'name.name') || '').split(' ').join('_'),
                  data,
                  downloadFiles: downloadCrewDocumentAttachments,
                  attachFiles: () => onAttachFiles(data.id),
                  isHidden: !onAttachFiles && !data.attachments_count ? true : false,
                  canAddAttachments: false
                }
              ) : (
                <SvgRender
                  className="text-violet"
                  src={deactivate}
                  style={{ width: 16, height: 16, marginLeft: 6 }}
                />
              ),
            actions: data => (actions ? actions(data, table.refetchData) : null)
          }}
          {...table}
        />
      </Col>

      <PageLoader isLoading={table.fetching || isLoading} />
    </Row>
  );
};

export default DocumentsTable;
