import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';

import { Col, Row } from 'reactstrap';

import { numberToStr } from 'common/utils/numbers';
import useD3Events from './base/hooks/useD3Events';
import TextWithTooltip from '../general/TextWithTooltip';

const D3Doughnut = ({
  data,
  showPlaceholder = false,
  needLegends = false,
  legendsTitle,
  hideInnerValue = false,
  legendsWrapperClassName,
  customClass,
  GraphComponent,
  titleClassName,
  style,
  invert,
  titleInfo = null,
  customSchema = {},
  legendsWidth = 6
}) => {
  const innerValue = hideInnerValue ? null : numberToStr(_get(data, 'graphInner.value'));
  const renderValue = _isObject(innerValue) ? innerValue : numberToStr(innerValue);
  const isPercentage = _get(data, 'graphInner.isPercentage');
  const innerTitle = _get(data, 'graphInner.title');
  const paletteColors = _get(data, 'paletteColors') || [];

  const graphEvents = useD3Events();

  const nameMapping = useMemo(() => {
    return Object.assign(
      {},
      ...data?.legends?.map(l => ({
        [l.label]: l.name
      }))
    );
  }, [data?.legends]);

  return (
    <div className={`doughnut-graph`}>
      <Row className={`${customClass || ''}`}>
        <Col xs={needLegends ? 12 - legendsWidth : 12} className={`${invert ? 'order-last' : ''}`}>
          <div className="doughnut-graph__container">
            {showPlaceholder ? (
              <div className="doughnut-graph__placeholder-pie">
                <div className="doughnut-graph__placeholder-pie--wrapper">
                  <div className="doughnut-graph__placeholder-pie--wrapper-inner">
                    <span className="doughnut-graph__placeholder-pie__value fw-medium fs-28 text-primary">
                      {renderValue || '-'}
                      {isPercentage && innerValue !== '-' ? '%' : ''}
                    </span>
                    <span className="doughnut-graph__placeholder-pie__subtitle fw-black fs-12 text-primary text-nowrap">
                      {innerTitle}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <GraphComponent
                plots={[
                  {
                    type: 'doughnut',
                    innerValue: innerValue || null,
                    graphInnerTitle: innerTitle,
                    isPercentage: isPercentage || false,
                    style: { ...style }
                  }
                ]}
                data={data?.percentages.map(item => ({
                  label: item?.label,
                  value: item?.percentage,
                  color: paletteColors[item?.label]?.color
                }))}
                style={{ marginLeft: 0, marginTop: 0, marginRight: 0, marginBottom: 0 }}
                schema={{
                  valueKey: 'value',
                  colorKey: 'color',
                  isZoomDisabled: true,
                  hideGridLines: true,
                  ...customSchema
                }}
                tooltipOptions={{
                  forcePopperTooltip: true,
                  showX: false,
                  showCustomMessage: ({ dataPoint }) => (
                    <span>
                      <b className="text-capitalize">
                        {nameMapping[dataPoint?.data?.label] || dataPoint?.data?.label}:
                      </b>
                      &nbsp;{dataPoint.percentageValue}%
                    </span>
                  )
                }}
                events={graphEvents}
                axesConfig={{ y: { ticks: 0 }, x: { ticks: 0 } }}
              />
            )}
          </div>
        </Col>

        {needLegends ? (
          <Col xs={legendsWidth}>
            <div>
              <div
                className={`text-primary doughnut-graph-title d-flex justify-content-between align-items-center ${
                  titleClassName ? titleClassName : 'fw-medium fs-20'
                }`}
              >
                {legendsTitle}
                {titleInfo || null}
              </div>
              <div
                className={`doughnut-graph-legends-wrapper pe-1 gray-scrollbar ${legendsWrapperClassName}`}
              >
                {data?.legends
                  .filter(l => !l.hidden)
                  .map((scale, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center justify-content-between cmb-10"
                    >
                      <div className="d-flex align-items-center doughnut-graph-legend">
                        <div
                          className={`me-2 doughnut-graph-circle doughnut-graph-circle--${scale.color}`}
                          style={{
                            backgroundColor:
                              scale.color || paletteColors ? paletteColors[scale?.label]?.color : ''
                          }}
                        />
                        <span className="text-primary fw-normal">
                          <TextWithTooltip>{scale.name}</TextWithTooltip>
                        </span>
                      </div>
                      {(scale?.value || scale?.value === 0) && (
                        <div className="doughnut-graph-legend-value">{scale.value}</div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default D3Doughnut;

D3Doughnut.propTypes = {
  data: PropTypes.object.isRequired,
  showPlaceholder: PropTypes.bool,
  needLegends: PropTypes.bool,
  legendsTitle: PropTypes.string,
  sliceSpace: PropTypes.number,
  invert: PropTypes.bool, //to swap legends with graph
  titleClassName: PropTypes.string,
  legendsWidth: PropTypes.number,
  legendsWrapperClassName: PropTypes.string
};
