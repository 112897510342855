import React from 'react';

import Header from './Header';
import Body from './Body';
import JobDrawer from 'common/components/pms/jobs/drawer';

const PmsJobsList = ({ components }) => {
  return (
    <div className="pms-jobs-view list">
      <Header />
      <Body components={components} />
      <JobDrawer />
    </div>
  );
};

export default PmsJobsList;
