import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { toggleJobDrawer } from 'common/components/pms/jobs/store/actions';
import {
  createJob,
  hasInvalidRequiredFields,
  setTableShouldRefetchData,
  resetJobProfile,
  deleteJobAction
} from 'common/components/jobs/_base/store/actions';
import PreventActionButton from 'common/components/buttons/PreventActionButton';

import useRouter from 'use-react-router';

import { parseQueryParams } from 'utils/urls';

import ProfileBody from './ProfileBody';

import { useSelector } from 'react-redux';
import { selectJobDrawerIsOpen } from 'common/components/pms/jobs/store/selectors';
import {
  selectMaintenanceDetaisVesselSystemAssignment,
  selectIsUnscheduledJob
} from 'common/components/pms/jobs/store/selectors';
import { removeJobIdFromURL } from 'common/components/pms/jobs/helpers';
import bin from 'common/assets/svg/common/bin-large.svg';

import { Button } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import paths from '@/routing/routes/_paths';
import { strToNumber } from '@/ts-common/utils/numbers';

const Drawer = () => {
  const { history, match } = useRouter();
  const createMode = history.location.pathname.includes('/create');
  const jobDrawerIsOpen = useSelector(selectJobDrawerIsOpen);
  const isUnscheduled = useAppSelector(selectIsUnscheduledJob);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const vesselSystemAssignment = useSelector(selectMaintenanceDetaisVesselSystemAssignment);

  const handleJobCreate = async () => {
    try {
      if (dispatch(hasInvalidRequiredFields()) || !vesselSystemAssignment) {
        return;
      }

      setIsSubmitting(true);

      await dispatch(createJob());
      dispatch(setTableShouldRefetchData(true));

      dispatch(toggleJobDrawer(false, null));
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      console.error(err);
    }
  };

  const jobId = useMemo(() => {
    if (match.params.jobID) return match.params.jobID;
    else if (history.location.search) return parseQueryParams(history.location.search)?.jobID;
    else return null;
  }, [history.location.search, match.params.jobID]);

  useEffect(() => {
    if (jobId) dispatch(toggleJobDrawer(true, jobId));
  }, [jobId]);

  useEffect(() => {
    if (createMode) {
      dispatch(toggleJobDrawer(true, null));
    }
  }, [history.location.pathname]);

  const onDelete = async () => {
    if (!jobId) return null;
    try {
      setIsDeleting(true);
      await dispatch(deleteJobAction({ id: strToNumber(jobId), table: 'pms_jobs_list' }));

      dispatch(toggleJobDrawer(false, null));
      dispatch(resetJobProfile());
      history.push({
        pathname: paths.pms_jobs,
        search: history.location.search
      });
      setIsDeleting(false);
    } catch (err) {
      setIsDeleting(false);

      console.error(err);
    }
  };

  return (
    <ProfileBody
      drawer={{
        isOpen: jobDrawerIsOpen,
        close: () => {
          removeJobIdFromURL(history, match);
          dispatch(toggleJobDrawer(false, null));
        }
      }}
      jobID={jobId}
      footer={
        !jobId || createMode ? (
          <div>
            <Button
              color="cancel"
              className="px-0 py-1 me-2 fs-10"
              onClick={() => dispatch(toggleJobDrawer(false, null))}
              disabled={isSubmitting}
            >
              CANCEL
            </Button>
            <Button
              onClick={() => handleJobCreate(true, true)}
              disabled={isSubmitting}
              color="primary"
              className="cpe-12 cps-12 cpt-2 cpb-2 fs-10"
            >
              CREATE
            </Button>
          </div>
        ) : isUnscheduled && jobId ? (
          <div
            className="d-flex align-items-center flex-1 text-red cursor-pointer"
            onClick={() => setShowDeleteModal(true)}
          >
            <PreventActionButton
              buttonLabel="Delete"
              buttonColor="link"
              buttonClassName="text-red fw-bold ms-1"
              onModalAccept={onDelete}
              onButtonClick={() => setShowDeleteModal(true)}
              closeModal={() => setShowDeleteModal(false)}
              isModalOpen={showDeleteModal}
              disabled={isDeleting}
              buttonIcon={bin}
              modalProps={{
                header: 'Delete',
                body: `Are you sure you want to delete this unplanned maintenance job?`,
                actionText: 'DELETE'
              }}
            />
          </div>
        ) : null
      }
    />
  );
};

Drawer.propTypes = {
  refetchData: PropTypes.func.isRequired
};

export default Drawer;
