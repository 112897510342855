import React from 'react';

import { selectVesselSystemAssignments } from 'common/components/pms/jobs/store/selectors';
import { useSelector } from 'react-redux';

import CollapseBox from './CollapseBox';

const Table = () => {
  const vesselSystemAssignments = useSelector(selectVesselSystemAssignments);

  if (!vesselSystemAssignments?.length) return null;

  return (
    <div className="pms-jobs-view__table mt-2">
      {vesselSystemAssignments.map(vesselSystemAssignmentID => (
        <CollapseBox
          key={vesselSystemAssignmentID}
          vesselSystemAssignmentID={vesselSystemAssignmentID}
        />
      ))}
    </div>
  );
};

export default Table;
