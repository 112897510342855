import { useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import VesselSelector from 'common/components/selectors/VesselSelector';
import TableTopFilter from 'common/components/table/TableTopFilter';
import TopPagination from 'common/components/table/main/TopPagination';
import SvgRender from 'common/components/general/SvgRender';
import ButtonFilter from 'common/components/buttons/ButtonFilter';
import DueDateTopFilter from 'common/components/jobs/_base/components/DueDateTopFilter';
import TableExcelExport from 'common/components/table/TableExcelExport';
import TableTopFilterNumberField from 'common/components/table/TableTopFilterNumberField';

import { setAllExpanded } from 'common/components/pms/jobs/store/actions';
import {
  selectAllExpanded,
  selectTotalVesselSystemAssignments
} from 'common/components/pms/jobs/store/selectors';

import classIcon from 'common/assets/svg/common/class-color.svg';
import critical from 'common/assets/svg/common/critical-color.svg';
import environmental from 'common/assets/svg/common/environmental-color.svg';
import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';
import navigational from 'common/assets/svg/common/navigational-color.svg';
import useRouter from 'use-react-router';
import { parseQueryParams, stringifyObj } from 'utils/urls';
import FilterButton from 'common/components/buttons/FilterButton';

const BecomingDueFilter = ({ isHidden, disabled, selectedBecomingDue, updateTopFilters }) =>
  isHidden ? null : (
    <FilterButton
      isActive={selectedBecomingDue}
      onClick={() => updateTopFilters('becoming_due', selectedBecomingDue ? null : 'true')}
      status="proceeding"
      disabled={disabled}
      size="sm"
    >
      Becoming Due
    </FilterButton>
  );

const OverdueFilter = ({ isHidden, disabled, selectedOverdue, updateTopFilters }) =>
  isHidden ? null : (
    <FilterButton
      isActive={selectedOverdue}
      onClick={() => updateTopFilters('is_overdue', selectedOverdue ? null : 'true')}
      status="error"
      disabled={disabled}
      size="sm"
    >
      Overdue
    </FilterButton>
  );

const PostponedFilter = ({ isHidden, disabled, selectedPostponed, updateTopFilters }) =>
  isHidden ? null : (
    <FilterButton
      isActive={selectedPostponed}
      onClick={() => updateTopFilters('is_postponed', selectedPostponed ? null : 'true')}
      status="proceeding"
      disabled={disabled}
      size="sm"
    >
      Postponed
    </FilterButton>
  );

const TopFilters = ({ listView = false, hasSelectedVessel, doneJobsFilterEnabled, table }) => {
  const { topFilters, setTopFilters, label, paging, refetchData, setFilters } = table;
  const dispatch = useDispatch();
  const selectedVessel = topFilters.find(f => f?.name === 'vessel_id');
  const selectedProgress = doneJobsFilterEnabled;
  const selectedOverdue = topFilters.find(f => f?.name === 'is_overdue')?.value;
  const selectedBecomingDue = topFilters.find(f => f?.name === 'becoming_due')?.value;
  const selectedPostponed = topFilters.find(f => f?.name === 'is_postponed')?.value;
  const selectedRunningHours = topFilters.find(f => f?.name === 'remaining_rh_until_due');
  const totalVesselAssignments = useSelector(selectTotalVesselSystemAssignments);

  const allExpanded = useSelector(selectAllExpanded);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const tableFilters = table.filters;

  const disabledFilter = isOnBoard ? false : !selectedVessel?.value?.length && !listView;

  const { history, location } = useRouter();

  const attibutesFilters = [
    { label: 'Class', value: 'is_class', icon: classIcon },
    { label: 'Critical', value: 'is_critical', icon: critical },
    { label: 'Navigational', value: 'is_navigational', icon: navigational },
    { label: 'Environmental', value: 'is_environmental_critical', icon: environmental }
  ];

  const updateTopFilters = useCallback(
    (key, value, operation) => {
      setTopFilters(
        topFilters.map(f =>
          f?.name === key ? { ...f, value, operation: operation || f.operation } : f
        )
      );
    },
    [setTopFilters, topFilters]
  );

  const onClickDoneJobs = () => {
    const { filters, sorting, ...rest } = parseQueryParams(history.location.search);
    const updated = { ...rest, filters: filters || [], sorting: sorting || {} };

    if (!doneJobsFilterEnabled) {
      updated.filters.push({ name: 'is_completed', operation: '=', value: 'true' });
    } else {
      setFilters(tableFilters?.filter(f => f?.column?.key !== 'carried_out_at'));
      updated.filters = updated.filters.filter(
        f => !['is_completed', 'carried_out_at'].includes(f.name)
      );
      updated.sorting = updated.sorting.carried_out_at ? {} : updated.sorting;
    }

    history.push({ pathname: location.pathname, search: stringifyObj(updated) });
  };

  return (
    <Row className="d-flex align-items-center cmb-4" noGutters>
      {!isOnBoard ? (
        <Col xs="auto" className="me-1">
          <TableTopFilter>
            <VesselSelector
              filter={{
                value: listView
                  ? selectedVessel?.value?.length
                    ? selectedVessel.value
                    : null
                  : selectedVessel.value?.[0] ?? null
              }}
              isMulti={listView}
              isClearable
              autoFocus={false}
              onChange={({ value }) =>
                updateTopFilters(
                  'vessel_id',
                  listView ? (value?.length ? value : null) : value?.id ? [value.id] : null
                )
              }
              placeholder="Select vessel"
            />
          </TableTopFilter>
        </Col>
      ) : null}

      {listView && (
        <>
          <Col xs="auto">
            <TableTopFilterNumberField
              selectedFilter={selectedRunningHours}
              onUpdate={({ value, operation }) => {
                if (isNaN(value)) return null;
                updateTopFilters('remaining_rh_until_due', value, operation);
              }}
              label="Running Hours"
              info={
                <div>
                  <div className="mb-3">
                    Here we filter{' '}
                    <strong>
                      running hours until job due, based on the last running hours report
                    </strong>{' '}
                    for each system. We do NOT filter running hours due based on total system RH.
                  </div>
                  <strong className="fs-10">Example:</strong>
                  <div>Last Running Hours report has Total System RH 7,800.</div>
                  <div>Job for the above system, has RH Due 8,000.</div>
                  <div>Job will be Due in 200 Running hours and this is what we filter.</div>
                </div>
              }
            />
          </Col>
          <Col xs="auto">
            <DueDateTopFilter
              topFilters={topFilters}
              disabled={disabledFilter}
              updateTopFilters={({ value, operation }) =>
                updateTopFilters('due_date', value, operation)
              }
            />
          </Col>
        </>
      )}

      <Col xs="auto" className="me-1">
        <BecomingDueFilter
          disabledFilter={disabledFilter}
          selectedBecomingDue={selectedBecomingDue}
          updateTopFilters={updateTopFilters}
        />
      </Col>
      <Col xs="auto" className="me-1">
        <OverdueFilter
          disabledFilter={disabledFilter}
          selectedOverdue={selectedOverdue}
          updateTopFilters={updateTopFilters}
        />
      </Col>
      <Col xs="auto" className="me-1">
        <PostponedFilter
          disabledFilter={disabledFilter}
          selectedPostponed={selectedPostponed}
          updateTopFilters={updateTopFilters}
        />
      </Col>

      {listView ? (
        <>
          <Col xs="auto">
            <FilterButton
              isActive={selectedProgress}
              onClick={
                !isOnBoard
                  ? onClickDoneJobs
                  : () => updateTopFilters('is_completed', selectedProgress ? null : 'true')
              }
              className="cme-2"
              disabled={disabledFilter}
              size="sm"
            >
              History
            </FilterButton>
          </Col>
          {!isOnBoard && listView && (
            <Col xs="auto" className="ps-2 ms-2 border-start d-flex align-items-center">
              <TableExcelExport
                className="ms-auto me-1"
                label={label}
                exportUrl="/vessel-systems/maintenance-jobs/export"
              />
            </Col>
          )}
        </>
      ) : (
        <Col className="d-flex align-items-center">
          <div className="border-start ps-1 ms-2 height-24"></div>

          {attibutesFilters.map(atr => {
            const selectedFilter = topFilters.find(f => f?.name === atr.value)?.value;

            return (
              <ButtonFilter
                key={atr.value}
                onClick={() => updateTopFilters(atr.value, selectedFilter ? null : 'true')}
                className="me-2"
                isActive={selectedFilter}
                innerClassName="bg-white text-purple d-inline-flex align-items-center px-1"
                label={atr.label}
                disabled={disabledFilter}
              >
                <SvgRender src={atr.icon} style={{ width: 14, height: 14 }} />
              </ButtonFilter>
            );
          })}
        </Col>
      )}
      {label && (
        <Col className="ms-auto app-table">
          <div className="app-table--toptotal d-flex align-items-center justify-content-end">
            {listView ? (
              <TopPagination
                standAlone
                state={{
                  paging: paging || table.state.paging
                }}
                fetchData={params => refetchData(params)}
              />
            ) : (
              <div className="app-table d-flex align-items-center">
                <div className="app-table--toptotal ">
                  Total Systems & Subsystems: {totalVesselAssignments || 0}
                </div>
                <div className="small-separator bg-violet mx-1"></div>
                <div
                  className={`text-primary cursor-pointer ${hasSelectedVessel ? '' : 'disabled'}`}
                  onClick={hasSelectedVessel ? () => dispatch(setAllExpanded(!allExpanded)) : null}
                >
                  {allExpanded ? 'Shrink All' : 'Expand All'}
                  <SvgRender
                    className="text-violet ms-1"
                    src={arrow}
                    style={{
                      width: 11,
                      height: 11,
                      transform: allExpanded ? `` : 'rotate(180deg)'
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Col>
      )}
    </Row>
  );
};

export default TopFilters;
