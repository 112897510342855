import ExportIcon from 'common/components/general/ExportIcon';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { selectTableListRequestParams } from 'common/components/table/store/selectors';
import { useState } from 'react';
import { download } from 'utils/api';
import { handleFileDownload } from 'common/utils/downloads';
import { getExportDefaultRequestParamsExcluded } from 'store/tables/lists/defaults';
import _reduce from 'lodash/reduce';

const TableExcelExport = ({ label, exportUrl, className }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const tableRequestParams = useSelector(state => selectTableListRequestParams(state, label));
  const excludedRequestParams = getExportDefaultRequestParamsExcluded(label);

  const onDownload = async () => {
    setIsDownloading(true);

    const rParams = {
      sorting: tableRequestParams?.sorting,
      filters: tableRequestParams?.filters,
      visible: tableRequestParams?.visible,
      paging: tableRequestParams?.paging,
      search: tableRequestParams?.search
    };

    const filteredParams = _reduce(
      rParams,
      (prev, value, key) => {
        if (!excludedRequestParams.includes(key)) {
          prev[key] = tableRequestParams?.[key];
        }

        return prev;
      },
      {}
    );

    try {
      await handleFileDownload({ url: exportUrl }, download, false, {
        requestParams: filteredParams,
        parsed: true
      });

      setIsDownloading(false);
    } catch (e) {
      setIsDownloading(false);
      console.error(e);
    }
  };

  return (
    <ExportIcon
      className={className}
      extension="excel"
      svgStyle={{ width: 14, height: 18 }}
      text="Export Excel"
      disabled={isDownloading}
      onClick={onDownload}
    />
  );
};

TableExcelExport.propTypes = {
  label: PropTypes.string.isRequired,
  exportUrl: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default TableExcelExport;
